/* eslint-disable */
import Parser from "html-react-parser";
export const lang = function() {
  const lang = {
    common: {
      title: "More than Friends",
      field_required: "This field is required",
      passwordmin: "Minimum password length is 6",
      invalid_email: "Invalid email address",
      prev: "Previous",
      next: "Next",
      submit: "Submit",
      close: "Close",
      select: "Select",
      log_out: "Log Out",
      powered_by: "Powered By",
      or: "or"
    },
    login: {
      title: "USER LOGIN",
      login: "Login",
      user_name: "Username",
      password: "Password",
      cpassword: "Confirm Password",
      sign_in: "Sign In",
      remember_me: "Remember Me",
      fb_login: "Login with Facebook",
      fb_error: "Somthing is wrong",
      by_proceeding: "* By proceeding, you agree to our",
      terms: "Terms&nbsp;of&nbsp;Use",
      privacy: "Privacy&nbsp;Policy",
      login_faild: "Login Failed"
    },
    forgot: {
      title: "Forgot Password",
      forgotbtn: "Get Reset Link",
      faild: "Forgot Password Failed",
      success: "Forgot Password Success"
    },
    resetpwd: {
      title: "Reset Password",
      no_match_pwd: "your password and confirm password does not match.",
      reset: "Rest",
      faild: "Reset Password Failed",
      success: "Reset Password Success"
    },
    sign_up: {
      title: "Sign Up",
      upload_image: "Upload Profile Picture",
      profile: "Profile",
      name: "Name",
      email: "Email",
      emailid:'Email ID',
      password: "Password",
      contact_number: "Contact Number",
      referralId: "Reference ID ",
      success: "Sign Up Success",
      faild: "Sign Up Failed"
    },
    home: {
      title: "Home",
      current_coin: "Current i-Coins",
      redeem_today: "Redeem Today!",
      hot: "Hot",
      deals: "Deals",
      participating: "Participating",
      merchants: "Merchants",
      projects: "Projects",
      gallery: "Gallery",
      my: "My",
      account: "Account",
      hot_deals: "Hot Deals",
      p_merchants: "Participating Merchants",
      p_gallery: "Projects Gallery",
      my_accout: "My Account",

      refer: Parser("refer a friend<span>and get<br/>rewarded!!</span>"),
      best_showroom: Parser(
        "Best Showroom<span>of the Year?<br/><i>vote us now!!</i></span>"
      )
    },
    register: {
      title: "Sign Up"
    },
    deals: {
      title: "Deals",
      all_deals: "All Deals",
      downloaded: "Downloaded",
      categories: "Categories",
      all_categories: "All Categories",
      filter: "Filter",
      locations: "locations",
      location: "location",
      coin: "i",
      icoin: "Coins",
      no_deals: "No deals found",
      where: "Where",
      terms: "Terms & conditions",
      redeem_with: "Redeem with",
      redeemed: "Redeemed",
      expired: "Expired",
      active: "Active",
      redemption_failed: "Redemption Failed",
      check_out: "Check out more deals",
      check_download: "Check out download deal",
      deal_downloaded: "Deal Downloaded"
    },
    download: {
      title: "Download Deals"
    },
    merchant: {
      title: "Merchants",
      no_merchant: "No merchant found"
    },
    project: {
      title: "Projects",
      no_project: "No merchant found",
      pinch_zoom: "Pinch to zoom"
    },
    refer: {
      title: "Refer A Friend",
      fill: "* Please fill up all the information of your",
      friend: "Friend",
      faild: "Referral Failed",
      success: "Referral Success",
      submit: "Submit Referral"
    },
    announcement: {
      title: "Announcement",
      no_announcement: "No announcement found"
    },
    myaccount: {
      title: "My Account",
      update: "Update",
      faild: "Profile Failed",
      success: "Profile Success"
    },
    best_showroom: {
      title: "Best Showroom",
      chapter: "How many IDs have you visited<br />before i-Chapter?",
      project:
        "Which of the project designs in the showroom do you prefer the most?",
      rank:
        "In regards to Q1, how would you rank i-Chapter amongst the IDs that you have visited?",
      voting_failed: "Voting Failed",
      thank_voting: "Thank You For Voting <br />For Our Showroom!",
      select_project: "Select Showroom",
      select_rank: "Select Rank"
    },
    activate: {
      title: "Generate Password",
      generate: "Generate"
    }
  };
  return lang;
};
