const reducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_LOGIN":
      return { ...state, logindata: action.json };
    case "SET_PROFILE":
      return { ...state, profiledata: action.json };
    case "SET_MYACCOUNT_PROFILE":
      return { ...state, myaccountprofile: action.json };
    case "SET_FBLOGIN":
      return { ...state, fblogindata: action.json };
    case "SET_ACTIVATE_ACCOUNT":
      return { ...state, activatedata: action.json };
    case "SET_GENERATE_PASSWORD":
      return { ...state, generatepwddata: action.json };
    case "SET_REGISTER":
      return { ...state, registerdata: action.json };
    case "SET_CATEGORIES":
      return { ...state, categorydata: action.json };
    case "SET_DEALS":
      return { ...state, dealdata: action.json };
    case "SET_DOWNLOAD_DEALS":
      return { ...state, downloaddealdata: action.json };
    case "SET_DEALDETAIL":
      return { ...state, dealdetaildata: action.json };
    case "SET_DEAL_REDEEM":
      return { ...state, dealredeemdata: action.json };
    case "SET_FORGOT":
      return { ...state, forgotdata: action.json };
    case "SET_MERCHANTS":
      return { ...state, merchantdata: action.json };
    case "SET_PROJECTS":
      return { ...state, projectdata: action.json };
    case "SET_STATIC_BLOCK":
      return { ...state, staticblockdata: action.json };
    case "SET_RESET_PASSWORD":
      return { ...state, resetpwddata: action.json };
    case "SET_PAGE":
      return { ...state, pagedata: action.json };
    case "SET_REFERRAL":
      return { ...state, referraldata: action.json };
    case "SET_ANNOUNCEMENT":
      return { ...state, announcementdata: action.json };
    case "SET_UNREADANNOUNCEMENT":
      return { ...state, unreadannouncementdata: action.json };
    case "SET_READANNOUNCEMENT":
      return { ...state, readannouncementdata: action.json };
    case "SET_UPDATE_PROFILE":
      return { ...state, profiledata: action.json };
    case "SET_BESTSHOWROOM":
      return { ...state, bestshowroomdata: action.json };
    case "SET_EMPLOYEE_LIST":
      return { ...state, employeeList: action.json }
    case "SET_SETTING":
      return { ...state, settingdata: action.json }
    case "SET_SHOWROOM":
      return { ...state, showroom: action.json }
    default:
      return state;
  }
};

export default reducer;
