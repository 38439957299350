import { all } from "redux-saga/effects";
import { watchGetLogin } from "./login";
import { watchGetProfile, watchGetMyAccountProfile } from "./profile";
import { watchGetFBLogin } from "./fblogin";
import { watchGetActivateAccount } from "./activateaccount";
import { watchGetGeneratePassword } from "./generatepwd";
import { watchGetRegister } from "./register";
import { watchGetCategories } from "./categories";
import { watchGetDeals } from "./deals";
import { watchGetDealDetail } from "./dealdetail";
import { watchGetDealRedeem } from "./dealredeem";
import { watchGetForgot } from "./forgot";
import { watchGetResetPassword } from "./resetpassword";
import { watchGetMerchants } from "./merchants";
import { watchGetProjects } from "./projects";
import { watchGetStaticBlock } from "./staticblocks";
import { watchGetPage } from "./page";
import { watchGetReferral } from "./referral";
import { watchGetAnnouncement } from "./dealsannouncements";
import { watchGetUnreadAnnouncement } from "./unreadannouncements";
import { watchGetReadAnnouncement } from "./readannouncements";
import { watchGetUpdateProfile } from "./updateprofile";
import { watchGetDownloadDeal } from "./downloaddeal";
import { watchGetBestShowroom, watchGetListShowroom } from "./bestshowroom";
import { watchGetEmployeelist } from "./employeelist";
import { watchGetSetting } from "./setting";
export default function*() {
  yield all([
    watchGetLogin(),
    watchGetProfile(),
    watchGetMyAccountProfile(),
    watchGetFBLogin(),
    watchGetActivateAccount(),
    watchGetGeneratePassword(),
    watchGetRegister(),
    watchGetCategories(),
    watchGetDeals(),
    watchGetDealDetail(),
    watchGetDealRedeem(),
    watchGetForgot(),
    watchGetResetPassword(),
    watchGetMerchants(),
    watchGetProjects(),
    watchGetStaticBlock(),
    watchGetPage(),
    watchGetReferral(),
    watchGetAnnouncement(),
    watchGetUnreadAnnouncement(),
    watchGetReadAnnouncement(),
    watchGetUpdateProfile(),
    watchGetDownloadDeal(),
    watchGetBestShowroom(),
    watchGetEmployeelist(),
    watchGetSetting(), 
    watchGetListShowroom(), 
  ]);
}
