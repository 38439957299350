import { takeLatest, call, put } from "redux-saga/effects";
import { apiUrl } from "../components/Config/config";
import { GET_MERCHANTS } from "../actions";
import cookie from "react-cookies";
import Axios from "axios";

export const watchGetMerchants = function*(Param) {
  yield takeLatest(GET_MERCHANTS, workerGetMerchants);
};

function* workerGetMerchants(Param) {
  const result = yield call(getMerchants, Param);
  yield put({ type: "SET_MERCHANTS", json: result.data });
}

function getMerchants(Param) {
  const options = {
    headers: { Auth: cookie.load("user_details").token }
  };
  let params = "";
  if (Param.param !== "" && typeof Param.param !== "undefined") {
    params = "?" + Param.param;
  }

  return Axios.get(apiUrl + "merchants/listMerchants" + params, options).then(
    response => {
      return response;
    },
    error => {
      return { data: { status: error.response.data.status } };
    }
  );
}
