/* eslint-disable */
import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import cookie from "react-cookies";
import { Pagination, PaginationItem } from "reactstrap";
import { page_limit } from "../Config/config";
import { GET_PAGE, GET_MERCHANTS } from "../../actions";
import {
  language,
  checkauthentication,
  getImage,
  LoadingSec,
  ScrollDiv,
  IntialLoading,
  Encrypt,
  CreateMeta,
  headerDetails,
  DisplayContent
} from "../Config/Helpers";
import ScrollAnimation from "react-animate-on-scroll";

class Index extends React.Component {
  constructor(props) {
    super(props);
    if (checkauthentication() === false) {
      const { history } = this.props;
      history.push("/login");
    }
    this.state = {
      userdetails: cookie.load("user_details"),
      lang: language(),
      page: 1,
      merchantlist: "",
      pagenation: "",
      merchant_details: "",
      pagedetails: "",
      frmloading: true,
      pagecontentLoading: true,
      noRecords:'',
    };
  }

  componentDidMount() {
    let pageparams = "slug=participating-merchants";
    this.props.getPage(pageparams);

    let params = "page=" + this.state.page;
    this.props.getMerchants(params);
  }
  componentDidUpdate(prevProps) {
    if (this.props.merchantdata !== prevProps.merchantdata) {
      /* Merchants Response */
      this.setState({ frmloading: false });
      if (
        this.props.merchantdata.status !== "" &&
        typeof this.props.merchantdata.status !== "undefined" &&
        typeof this.props.merchantdata.status !== undefined
      ) {
        if (this.props.merchantdata.status === false) {
          const { history } = this.props;
          history.push("/logout");
        } else if (this.props.merchantdata.status === "ok") {
          if (
            this.props.merchantdata.total_records !== "" &&
            typeof this.props.merchantdata.total_records !== "undefined" &&
            typeof this.props.merchantdata.total_records !== undefined
          ) {
            this.setState(
              { total_records: this.props.merchantdata.total_records },
              function() {
                this.loadPagenation();
              }
            );
          }
          this.setState(
            { merchant_details: this.props.merchantdata.result_set },
            function() {
              this.loadMerchant();
              this.loadPagenation();
            }
          );
        }
      }
    }
    if (this.props.pagedata !== prevProps.pagedata) {
      /*Page Response */
      this.setState({ pagecontentLoading: false });
      if (
        this.props.pagedata.status !== "" &&
        typeof this.props.pagedata.status !== "undefined" &&
        typeof this.props.pagedata.status !== undefined
      ) {
        if (this.props.pagedata.status === false) {
          const { history } = this.props;
          history.push("/logout");
        } else if (this.props.pagedata.status === "ok") {
          this.setState({ pagedetails: this.props.pagedata.result_set });
        }
      }
    }
  }

  /* Load Merchant List */
  loadMerchant() {
    let merchantlist;
    let noRecords;
    if (
      this.state.merchant_details !== "" &&
      typeof this.state.merchant_details !== "undefined"
    ) {
      merchantlist = this.state.merchant_details.map((item, index) => {
        return (
          <li key={index}>
            <Link to={"/merchant-deals/" + Encrypt(item.merchant_id, "e")}>
              <div className="mlogo_bx">
                <img
                  src={getImage(item.merchant_photo, "merchant")}
                  alt={item.merchant_name}
                />
              </div>
              <p>{item.merchant_name}</p>
            </Link>
          </li>
        );
      });
    } else {
      this.setState({ total_records: 0 }, function() {
        this.loadPagenation();
      });
      noRecords = (
        <div className="norecord_sec text-center">
          <i className="norecord_ico"></i>
          <h4>{this.state.lang.merchant.no_merchant}</h4>
        </div>
      );
    }
    this.setState({ merchantlist: merchantlist, noRecords:noRecords });
  }

  /* Load Pagenation Desaign */
  loadPagenation() {
    let total_records = this.state.total_records;
    let totalPage = total_records / page_limit;
    let pagenation = "";
    let prev = this.state.page - 1;
    let next = parseInt(this.state.page) + 1;
    if (Math.ceil(totalPage) > 1) {
      pagenation = (
        <Pagination aria-label="Page navigation example">
          {this.state.page > 1 && (
            <PaginationItem onClick={this.loadMore.bind(this, prev)}>
              <span
                className="page-link"
                aria-label={this.state.lang.common.prev}
              >
                <span aria-hidden="true">‹</span>
                <span className="sr-only">{this.state.lang.common.prev}</span>
              </span>
            </PaginationItem>
          )}
          {this.loadpageList()}
          {parseInt(this.state.page) + 1 <= Math.ceil(totalPage) && (
            <PaginationItem onClick={this.loadMore.bind(this, next)}>
              <span
                href="#"
                className="page-link"
                aria-label={this.state.lang.common.next}
              >
                <span aria-hidden="true">›</span>
                <span className="sr-only">{this.state.lang.common.next}</span>
              </span>
            </PaginationItem>
          )}
        </Pagination>
      );
    }
    this.setState({ pagenation: pagenation });
  }

  /* Pagenation Number List */
  loadpageList() {
    let currentPage = this.state.page > 1 ? parseInt(this.state.page) - 1 : 1;
    let total_records = this.state.total_records;
    let totalPage = total_records / page_limit;
    let nextloop =
      parseInt(currentPage) + 3 <= totalPage
        ? parseInt(currentPage) + 3
        : totalPage;
    if (currentPage - nextloop <= 3) {
      currentPage = nextloop - 3;
      if (currentPage <= 0) {
        currentPage = 1;
      }
    }
    let ToReturn = [];
    for (var i = Math.ceil(currentPage); i <= Math.ceil(nextloop); i++) {
      ToReturn.push(
        <PaginationItem
          key={i}
          className={this.state.page === i ? "active" : ""}
          onClick={this.loadMore.bind(this, i)}
        >
          <span className="page-link">{i}</span>
        </PaginationItem>
      );
    }
    return ToReturn;
  }
  /* Apply Pagenation */
  loadMore(page) {
    if (page !== this.state.page) {
      this.setState({ page: page, frmloading: true }, function() {
        ScrollDiv("merchant_sec");
        let params = "page=" + this.state.page;
        this.props.getMerchants(params);
      });
    }
  }

  render() {
    if (
      this.state.userdetails !== "" &&
      typeof this.state.userdetails !== "undefined"
    ) {
      return (
        <div className="center">
          {this.state.pagedetails !== "" && CreateMeta(this.state.pagedetails)}
          {this.state.pagecontentLoading === true && IntialLoading()}
          {this.state.pagecontentLoading === false && (
            <div>
              {headerDetails()}
              <div className="ban_sec">
                <div className="container">
                  {this.state.pagedetails !== "" && (
                    <div className="ban_info text-center">
                      <i>
                        <img
                          src={getImage(this.state.pagedetails.image, "pages")}
                          alt=""
                        />
                      </i>
                      <ScrollAnimation
                        animateIn="fadeInDown"
                        animateOnce={true}
                        offset={0}
                        delay={200}
                      >
                        <h1>{this.state.pagedetails.title}</h1>
                      </ScrollAnimation>
                      <ScrollAnimation
                        animateIn="fadeInDown"
                        animateOnce={true}
                        offset={0}
                        delay={400}
                      >
                        {this.state.pagedetails.description !== ""
                          ? DisplayContent(this.state.pagedetails.description)
                          : ""}
                      </ScrollAnimation>
                    </div>
                  )}
                </div>
                <a
                  href="#javascript;"
                  className="scroll_down"
                  onClick={e => {
                    e.preventDefault();
                    ScrollDiv("merchant_sec");
                  }}
                >
                  <span></span>
                </a>
              </div>
            </div>
          )}
          <div className="merchant_sec" id="merchant_sec">
            <div className="container">
              <ScrollAnimation
                animateIn="fadeIn"
                animateOnce={true}
                offset={0}
                delay={200}
              >
              {this.state.frmloading === true && LoadingSec()}
              {this.state.noRecords!='' && this.state.noRecords}
              {this.state.merchantlist!=="" &&
                <ul
                  className={
                    this.state.frmloading === true
                      ? "merchant_logos load_wrapper load-wrapper-mheight"
                      : "merchant_logos"
                  }
                >                  
                  {this.state.merchantlist}
                </ul>
              }
              </ScrollAnimation>
              {this.state.pagenation !== "" && (
                <div className="pagination_sec">{this.state.pagenation}</div>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return IntialLoading();
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPage: param => {
      dispatch({ type: GET_PAGE, param });
    },
    getMerchants: param => {
      dispatch({ type: GET_MERCHANTS, param });
    }
  };
};

const mapStateToProps = state => {
  return {
    pagedata: state.pagedata,
    merchantdata: state.merchantdata
  };
};

Index.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  })
};

Index = connect(mapStateToProps, mapDispatchToProps)(Index);

export default withRouter(Index);
