/* eslint-disable */
import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Scrollbars } from "react-custom-scrollbars";
import Swiper from "react-id-swiper";
import ico_tick from "../../common/images/ico_tick.png";
import announce1 from '../../common/images/announce1.jpg';
import announce2 from '../../common/images/announce2.jpg';
import ico_failed from '../../common/images/ico_failed.png';

const params = {     
 effect: "fade",
  loop: true,
  noSwiping: true,
  allowTouchMove: false,
  autoHeight: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true
  }
};

const ModalExample = (props) => {  
  const {
    buttonLabel,
    className
  } = props;

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [modal5, setModal5] = useState(false);

  const toggle = () => setModal(!modal);
  const toggle1 = () => setModal1(!modal1);
  const toggle2 = () => setModal2(!modal2);
  const toggle3 = () => setModal3(!modal3);
  const toggle4 = () => setModal4(!modal4);
  const toggle5 = () => setModal5(!modal5);

  return (
    <div>
      <Button className="btn_yellow" onClick={toggle}>Terms of use</Button>      
        <Modal isOpen={modal} toggle={toggle} className={className} centered>
          <div className="popup_sec popup_terms">
            <ModalHeader toggle={toggle}></ModalHeader>
            <ModalBody>
              <h2 className="title1 text-center">TERMS OF USE</h2>     
              <div className="smcustom_scroll">         
                <Scrollbars className="smcustom_scroll_in">
                  <div className="terms_content">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vel commodo velit. Aenean lacinia quis diam feugiat dictum. Suspendisse sit amet rhoncus ipsum. Donec pellentesque ligula a augue lobortis scelerisque. </p>
                    <h3>1.1 Vivamus sit amet velit</h3>
                    <p>Quis purus dapibus vulputate non nec sapien. Nunc ultricies, arcu non viverra dapibus, felis eros congue mi, eu congue erat metus sit amet leo. Curabitur ornare, neque in rhoncus lacinia, sem ligula maximus elit, sed vehicula est turpis ut leo. Mauris hendrerit arcu ut ante consequat fermentum. </p>
                    <h3>1.2 Vivamus sit amet velit</h3>
                    <p>Quis purus dapibus vulputate non nec sapien. Nunc ultricies, arcu non viverra dapibus, felis eros congue mi, eu congue erat metus sit amet leo. Curabitur ornare, neque in rhoncus lacinia, sem ligula maximus elit, sed vehicula est turpis ut leo. Mauris hendrerit arcu ut ante consequat fermentum. </p>
                    <h3>1.1 Vivamus sit amet velit</h3>
                    <p>Quis purus dapibus vulputate non nec sapien. Nunc ultricies, arcu non viverra dapibus, felis eros congue mi, eu congue erat metus sit amet leo. Curabitur ornare, neque in rhoncus lacinia, sem ligula maximus elit, sed vehicula est turpis ut leo. Mauris hendrerit arcu ut ante consequat fermentum. </p>
                    <h3>1.2 Vivamus sit amet velit</h3>
                    <p>Quis purus dapibus vulputate non nec sapien. Nunc ultricies, arcu non viverra dapibus, felis eros congue mi, eu congue erat metus sit amet leo. Curabitur ornare, neque in rhoncus lacinia, sem ligula maximus elit, sed vehicula est turpis ut leo. Mauris hendrerit arcu ut ante consequat fermentum. </p>
                    <h3>1.1 Vivamus sit amet velit</h3>
                    <p>Quis purus dapibus vulputate non nec sapien. Nunc ultricies, arcu non viverra dapibus, felis eros congue mi, eu congue erat metus sit amet leo. Curabitur ornare, neque in rhoncus lacinia, sem ligula maximus elit, sed vehicula est turpis ut leo. Mauris hendrerit arcu ut ante consequat fermentum. </p>
                    <h3>1.2 Vivamus sit amet velit</h3>
                    <p>Quis purus dapibus vulputate non nec sapien. Nunc ultricies, arcu non viverra dapibus, felis eros congue mi, eu congue erat metus sit amet leo. Curabitur ornare, neque in rhoncus lacinia, sem ligula maximus elit, sed vehicula est turpis ut leo. Mauris hendrerit arcu ut ante consequat fermentum. </p>
                    <h3>1.1 Vivamus sit amet velit</h3>
                    <p>Quis purus dapibus vulputate non nec sapien. Nunc ultricies, arcu non viverra dapibus, felis eros congue mi, eu congue erat metus sit amet leo. Curabitur ornare, neque in rhoncus lacinia, sem ligula maximus elit, sed vehicula est turpis ut leo. Mauris hendrerit arcu ut ante consequat fermentum. </p>
                    <h3>1.2 Vivamus sit amet velit</h3>
                    <p>Quis purus dapibus vulputate non nec sapien. Nunc ultricies, arcu non viverra dapibus, felis eros congue mi, eu congue erat metus sit amet leo. Curabitur ornare, neque in rhoncus lacinia, sem ligula maximus elit, sed vehicula est turpis ut leo. Mauris hendrerit arcu ut ante consequat fermentum. </p>
                    </div>
                  </Scrollbars>   
                </div>             
            </ModalBody>            
          </div>
        </Modal>
        <Button className="btn_yellow" onClick={toggle1}>Deals download</Button>      
        <Modal isOpen={modal1} toggle={toggle1} className={className} centered>
          <div className="popup_sec popup_alert">
            <ModalHeader toggle={toggle1}></ModalHeader>
            <ModalBody>
              <div className="popalert_top">
                <div className="popaler_info">
                  <i>
                    <img src={ico_tick} />
                  </i>
                  <h3 className="title2">Deal Downloaded</h3>
                  <p>Your deal will be displayed soon<br/> under the ‘Downloaded’ tab.</p>                  
                </div>
              </div> 
              <button className="btn btn_yellow btn-lg btn-block arch_btn text-uppercase">Check out more deals</button>            
            </ModalBody>            
          </div>
        </Modal>   
        <Button className="btn_yellow" onClick={toggle2}>Announcement</Button>    
        <Modal isOpen={modal2} toggle={toggle2} className={className} centered>
          <div className="popup_sec popup_announce">
            <ModalHeader toggle={toggle2}></ModalHeader>
            <ModalBody>
              <div className="popalert_top">
                <div className="announce_slider">
                  <Swiper {...params}>
                    <div className="announce_item">
                      <div className="announce_itemimg">
                        <img src={announce1} alt="" />
                      </div>
                      <div className="announce_iteminfo">
                        <h3>Lorem Ipsum dolor sit amet</h3>
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>
                      </div>
                    </div> 
                    <div className="announce_item">
                      <div className="announce_itemimg">
                        <img src={announce1} alt="" />
                      </div>
                      <div className="announce_iteminfo">
                        <h3>Lorem Ipsum dolor sit amet</h3>
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>
                      </div>
                    </div>   
                    <div className="announce_item">
                      <div className="announce_itemimg">
                        <img src={announce1} alt="" />
                      </div>
                      <div className="announce_iteminfo">
                        <h3>Lorem Ipsum dolor sit amet</h3>
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>
                      </div>
                    </div>  
                  </Swiper>
                </div>                    
              </div>    
              <button className="btn btn_yellow btn-block btn-lg arch_btn text-uppercase">Lorem ipsum</button>
            </ModalBody>            
          </div>
        </Modal>  
        <Button className="btn_yellow" onClick={toggle3}>Redeem</Button>      
        <Modal isOpen={modal3} toggle={toggle3} className={className} centered>
          <div className="popup_sec popup_alert">
            <ModalHeader toggle={toggle3}></ModalHeader>
            <ModalBody>
              <div className="popalert_top">
                <div className="popaler_info">
                  <i>
                    <img src={ico_failed} />
                  </i>
                  <h3 className="title2">REDEMPTION FAILED</h3>
                  <p>You do not have sufficient <br/>R.Coins to redeem this deal</p>                  
                </div>
              </div> 
              <button className="btn btn_yellow btn-lg btn-block arch_btn text-uppercase">Check out more deals</button>            
            </ModalBody>            
          </div>
        </Modal> 
        <Button className="btn_yellow" onClick={toggle4}>Thank you for voting</Button>      
        <Modal isOpen={modal4} toggle={toggle4} className={className} centered>
          <div className="popup_sec popup_alert">
            <ModalHeader toggle={toggle4}></ModalHeader>
            <ModalBody>
              <div className="popalert_top">
                <div className="popaler_info">
                  <i>
                    <img src={ico_tick} />
                  </i>
                  <h3 className="title2">Thank you for voting<br/>
for our showroom!</h3>
                  <p>Here's 100 R.Coins for you<br/> to spend!</p>                  
                </div>
              </div> 
              <button className="btn btn_yellow btn-lg btn-block arch_btn text-uppercase"><i class="ico_r ico_rgrey text-lowercase"></i> 100 i-Coins</button>            
            </ModalBody>            
          </div>
        </Modal> 
        <Button className="btn_yellow" onClick={toggle5}>REFERRAL</Button>      
        <Modal isOpen={modal5} toggle={toggle5} className={className} centered>
          <div className="popup_sec popup_alert">
            <ModalHeader toggle={toggle5}></ModalHeader>
            <ModalBody>
              <div className="popalert_top">
                <div className="popaler_info">
                  <i>
                    <img src={ico_tick} />
                  </i>
                  <h3 className="title2">REFERRAL SUCCESS</h3>
                  <p>Thank you for referring. Here's<br/> 100 i-Coins for you to spend!</p>                  
                </div>
              </div> 
              <button className="btn btn_yellow btn-lg btn-block arch_btn text-uppercase"><i class="ico_r ico_rgrey text-lowercase"></i> 100 i-Coins</button>            
            </ModalBody>            
          </div>
        </Modal>    
    </div>
  );
}

export default ModalExample;