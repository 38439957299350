import { takeLatest, call, put } from "redux-saga/effects";
import { apiUrl } from "../components/Config/config";
import { GET_UPDATE_PROFILE } from "../actions";
import cookie from "react-cookies";
import Axios from "axios";

export const watchGetUpdateProfile = function*() {
  yield takeLatest(GET_UPDATE_PROFILE, workerGetUpdateProfile);
};

function* workerGetUpdateProfile(Param) {
  const result = yield call(getUpdateProfile, Param);
  yield put({
    type: "SET_UPDATE_PROFILE",
    json: result.data || [{ error: result.message }]
  });
}

function getUpdateProfile(Param) {
  const options = {
    headers: { Auth: cookie.load("user_details").token }
  };
  return Axios.post(apiUrl + "customer/update", Param.Param, options).then(
    response => {
      return response;
    },
    error => {
      return { data: { status: error.response.data.status } };
    }
  );
}
