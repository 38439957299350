import { takeLatest, call, put } from 'redux-saga/effects';
import { apiUrl } from "../components/Config/config";
import { GET_DEALDETAIL } from '../actions';
import cookie from 'react-cookies';
import Axios from 'axios';


export const watchGetDealDetail = function* (Param) {
 	yield takeLatest(GET_DEALDETAIL, workerGetDealDetail);
}

function* workerGetDealDetail(Param) {
    const result = yield call(getDealDetail, Param);
    yield put({ type: "SET_DEALDETAIL", json: result.data });   
}

function getDealDetail(Param) {
    const options = {
        headers: {'Auth': cookie.load('user_details').token}
    };
    return Axios.get(apiUrl+'deals/deal_info/'+Param.params, options).then( 
            (response) => { return response; },
            (error) => { return {data:{status:error.response.data.status}}; }
        );
} 
