/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import PropTypes from "prop-types";
import { withRouter, Redirect } from "react-router-dom";

class Logout extends Component {
  componentWillMount() {
    cookie.remove("user_details");
    cookie.remove("user_coin");
  }

  render() {
    if (this.props.history) {
      return <Redirect to={"/login"} />;
    }

    return <h1 className="loading-text">Logging out...</h1>;
  }
}

Logout.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  })
};

export default withRouter(Logout);
