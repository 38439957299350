/* eslint-disable */
import { takeLatest, call, put } from "redux-saga/effects";
import { apiUrl } from "../components/Config/config";
import { GET_FBLOGIN } from "../actions";
import Axios from "axios";

export const watchGetFBLogin = function*() {
  yield takeLatest(GET_FBLOGIN, workerGetFBLogin);
};

function* workerGetFBLogin(Param) {
  const result = yield call(getFBLogin, Param);
  yield put({
    type: "SET_FBLOGIN",
    json: result.data || [{ error: result.message }]
  });
}

function getFBLogin(Param) {
  var qs = require("qs");
  return Axios.post(apiUrl + "customer/fbLogin", qs.stringify(Param.Param));
}
