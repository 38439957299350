/* eslint-disable */
import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import cookie from "react-cookies";
import { GET_DEALDETAIL, GET_DEAL_REDEEM } from "../../actions";
import {
  language,
  site_title,
  checkauthentication,
  headerDetails,
  getImage,
  LoadingSec,
  DateDiff,
  IntialLoading,
  DisplayContent,
  DisplayImage
} from "../Config/Helpers";
import { Helmet } from "react-helmet";
import ModalPopup from "../Layout/ModalPopup";

class Detail extends React.Component {
  constructor(props) {
    super(props);
    if (checkauthentication() === false) {
      const { history } = this.props;
      history.push("/login");
    }
    if (
      this.props.match.params.dealID === "" ||
      typeof this.props.match.params.dealID === "undefined"
    ) {
      const { history } = this.props;
      history.push("/hot-deals");
    }

    this.state = {
      userdetails: cookie.load("user_details"),
      lang: language(),
      dealID: this.props.match.params.dealID,
      loading: true,
      modalDeals: false,
      redeemLoading: false,
      redeemsuccess: false,
      redeemsuccessmsg:'',
      redeemerror: "",
      closebtn: false,
      redeemed: "",
      pagetype:
        this.props.location.state !== "" &&
        typeof this.props.location.state != "undefined" &&
        this.props.location.state.pagetype !== "" &&
        typeof this.props.location.state.pagetype !== "undefined"
          ? this.props.location.state.pagetype
          : ""
    };
    this.toggleDeals = this.toggleDeals.bind(this);
    let params = this.state.dealID;
    this.props.getDealDetail(params);
  }

  componentDidUpdate(prevProps) {
    if (this.props.dealdetaildata !== prevProps.dealdetaildata) {
      /* Deal Details Response */
      this.setState({ loading: false });
      if (
        this.props.dealdetaildata.status !== "" &&
        typeof this.props.dealdetaildata.status !== "undefined"
      ) {
        if (this.props.dealdetaildata.status === false) {
          const { history } = this.props;
          history.push("/logout");
        } else if (this.props.dealdetaildata.status === "ok") {
          if (
            this.props.dealdetaildata.result_set !== "" &&
            typeof this.props.dealdetaildata.result_set !== "undefined"
          ) {
            this.setState({
              deal_details: this.props.dealdetaildata.result_set,
              redeemed: this.props.dealdetaildata.result_set.redeemed
            });
          } else {
            const { history } = this.props;
            history.push("/hot-deals");
          }
        } else {
          const { history } = this.props;
          history.push("/hot-deals");
        }
      }
    }
    if (this.props.dealredeemdata !== prevProps.dealredeemdata) {
      this.setState({ redeemLoading: false });
      if (
        this.props.dealredeemdata.status !== "" &&
        typeof this.props.dealredeemdata.status !== "undefined"
      ) {
        if (this.props.dealredeemdata.status === false) {
          const { history } = this.props;
          history.push("/logout");
        } else if (this.props.dealredeemdata.status === "ok") {
          cookie.save("user_coin", this.props.dealredeemdata.coin);
          this.setState({ redeemsuccess: true, redeemsuccessmsg:this.props.dealredeemdata.message, closebtn: true, redeemed: 1 });
        } else {
          this.setState({
            redeemerror: this.props.dealredeemdata.message,
            closebtn: true
          });
        }
      }
    }
  }
  toggleDeals() {
    this.setState(prevState => ({
      modalDeals: !prevState.modalDeals
    }));
  }
  createDealRedeem() {
    let postdata = {
      deal_id: this.state.dealID
    };
    this.setState({ redeemLoading: true });
    this.props.getDealRedeem(postdata);
    this.toggleDeals();
  }

  render() {
    if (
      this.state.userdetails !== "" &&
      typeof this.state.userdetails !== "undefined"
    ) {
      if (
        this.state.deal_details !== "" &&
        typeof this.state.deal_details !== "undefined"
      ) {
        return (
          <div className="center grow_flex">
            {this.state.deal_details !== "" && (
              <Helmet>
                <title>{site_title(this.state.deal_details.deal_name)}</title>
                <meta
                  name="keywords"
                  content={this.state.deal_details.deal_name}
                />
                <meta
                  name="description"
                  content={this.state.deal_details.description}
                />
              </Helmet>
            )}
            {headerDetails()}
            <div className="details_topbar">
              <Link
                to={
                  this.state.pagetype !== "" ? "/download-deals" : "/hot-deals"
                }
                className="back_arrow title2"
                title=""
              >
                {this.state.deal_details.merchant_name}
              </Link>
            </div>
            <div className="detail_img">
              <img
                src={getImage(
                  this.state.deal_details.deal_image,
                  "deals_details"
                )}
                alt=""
              />
            </div>
            <div className="deals_daysleft">
              <p>
                <span>
                  {DateDiff(this.state.deal_details.end_date) !== "Expired"
                    ? DateDiff(this.state.deal_details.end_date)
                    : ""}
                </span>{" "}
                {this.state.pagetype !== "" ? (
                  DateDiff(this.state.deal_details.end_date) === "Expired" ? (
                    <span className="deal_status deal_status_expired">
                      {this.state.lang.deals.expired}
                    </span>
                  ) : (
                    DateDiff(this.state.deal_details.end_date) !==
                      "Expired" && (
                      <span className="deal_status deal_status_active">
                        {this.state.lang.deals.active}
                      </span>
                    )
                  )
                ) : (
                  this.state.deal_details.hot_deals === "Y" && (
                    <span className="deal_status deal_status_hot">
                      {this.state.lang.home.hot_deals}
                    </span>
                  )
                )}
              </p>
            </div>
            <div className="detail_desc">
              {this.state.deal_details.description !== "" &&
              this.state.deal_details.description !== null
                ? DisplayContent(this.state.deal_details.description)
                : ""}
            </div>
            <div className="detail_infolist">
              <div className="detail_inforw detail_loc">
                <i>
                  <img src={DisplayImage("ico_map")} alt="" />
                </i>
                <h5>{this.state.lang.deals.where}</h5>
                <span>
                  {this.state.deal_details.location}{" "}
                  {this.state.deal_details.location > 1
                    ? this.state.lang.deals.locations
                    : this.state.lang.deals.location}
                </span>
              </div>
              <div className="detail_inforw detail_terms">
                <i>
                  <img src={DisplayImage("ico_terms")} alt="" />
                </i>
                <h5>{this.state.lang.deals.terms}</h5>
                {this.state.deal_details.terms !== "" &&
                this.state.deal_details.terms !== null
                  ? DisplayContent(this.state.deal_details.terms)
                  : ""}
              </div>
            </div>
            {this.state.pagetype === "" && (
              <span>
                {this.state.redeemed !== "" && this.state.redeemed === 1 ? (
                  <button
                    className="btn btn_yellow btn-lg btn-block text-transform redeem_btn"
                    title={this.state.lang.deals.redeemed} disabled={true}
                  >
                    <span>{this.state.lang.deals.redeemed}</span>
                  </button>
                ) : (
                  <a
                    href="#javascript;"
                    onClick={e => {
                      e.preventDefault();
                      this.createDealRedeem();
                    }}
                    className="btn btn_yellow btn-lg btn-block text-transform redeem_btn"
                    title={
                      this.state.lang.deals.redeem_with +
                      " " +
                      this.state.deal_details.coin +
                      " " +
                      this.state.lang.deals.icoin
                    }
                  >
                    <span>
                      {this.state.lang.deals.redeem_with}{" "}
                      <i className="ico_r ico_rgrey text-lowercase">                        
                      </i>
                      {this.state.deal_details.coin}{" "}
                      <b className="text-lowercase">
                        {this.state.lang.deals.coin}-
                      </b>
                      {this.state.lang.deals.icoin}
                    </span>
                  </a>
                )}
              </span>
            )}

            <ModalPopup
              modal={this.state.modalDeals}
              toggle={this.toggleDeals}
              closebtn={this.state.closebtn}
              innerclass="popup_alert"
            >
              {this.state.redeemLoading === true && (
                <div className="popalert_top">{LoadingSec()}</div>
              )}
              {this.state.redeemerror !== "" && (
                <div>
                  <div className="popalert_top">
                    <div className="popaler_info">
                      <i>
                        <img src={DisplayImage("ico_failed")} alt="" />
                      </i>
                      <h3 className="title2 text-uppercase">
                        {this.state.lang.deals.redemption_failed}
                      </h3>
                      <p>{DisplayContent(this.state.redeemerror)}</p>
                    </div>
                  </div>
                  <Link to={"/hot-deals"}>
                    <button className="btn btn_yellow btn-lg btn-block arch_btn text-uppercase">
                      {this.state.lang.deals.check_out}
                    </button>
                  </Link>
                </div>
              )}
              {this.state.redeemsuccess === true && (
                <div>
                  <div className="popalert_top">
                    <div className="popaler_info">
                      <i>
                        <img src={DisplayImage("ico_tick")} alt="" />
                      </i>
                      <h3 className="title2 text-uppercase">
                        {this.state.lang.deals.deal_downloaded}
                      </h3>
                      <p>{DisplayContent(this.state.redeemsuccessmsg)}</p>
                    </div>
                  </div>
                  <Link to={"/download-deals"}>
                    <button className="btn btn_yellow btn-lg btn-block arch_btn">
                      {this.state.lang.deals.check_download}
                    </button>
                  </Link>
                </div>
              )}
            </ModalPopup>
          </div>
        );
      } else {
        return IntialLoading();
      }
    } else {
      return IntialLoading();
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getDealDetail: params => {
      dispatch({ type: GET_DEALDETAIL, params });
    },
    getDealRedeem: params => {
      dispatch({ type: GET_DEAL_REDEEM, params });
    }
  };
};

const mapStateToProps = state => {
  return {
    dealdetaildata: state.dealdetaildata,
    dealredeemdata: state.dealredeemdata
  };
};

Detail.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  })
};

Detail = connect(mapStateToProps, mapDispatchToProps)(Detail);

export default withRouter(Detail);
