/* eslint-disable */
import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { GET_FORGOT } from "../../actions";
import {
  language,
  site_title,
  checkauthentication,
  emailValidate,
  poweredByFootre,
  ScrollTop,
  DisplayContent,
  DisplayImage
} from "../Config/Helpers";
import { Helmet } from "react-helmet";
import ModalPopup from "../Layout/ModalPopup";

class Index extends React.Component {
  constructor(props) {
    super(props);
    if (checkauthentication() === true) {
      const { history } = this.props;
      history.push("/");
    }
    this.state = {
      lang: language(),
      frmloading: false,
      email: "",
      error_email: "",
      logindata: "",
      error: "",
      successmsg: ""
    };
    this.togglemsg = this.togglemsg.bind(this);
  }
  componentDidMount() {
    ScrollTop();
  }

  componentDidUpdate(prevProps) {
    if (this.props.forgotdata !== prevProps.forgotdata) {
      /* Forgot Response */
      if (
        this.props.forgotdata.status !== "" &&
        typeof this.props.forgotdata.status !== "undefined"
      ) {
        this.setState({ frmloading: false });
        if (this.props.forgotdata.status === "ok") {
          this.setState(
            {
              error: "",
              successmsg: this.props.forgotdata.message,
              email: ""
            },
            function() {
              this.togglemsg();
            }
          );
        } else {
          this.setState(
            {
              error: this.props.forgotdata.message,
              successmsg: ""
            },
            function() {
              this.togglemsg();
            }
          );
        }
      }
    }
  }

  togglemsg() {
    this.setState(prevState => ({
      modalmsg: !prevState.modalmsg
    }));
  }

  /* Set State for input value */
  handleChangeTxt = event => {
    let { name, value } = event.target;
    if (name === "email") {
      if (emailValidate(value) === false && value !== "") {
        this.setState({
          ["error_" + name]: this.state.lang.common.invalid_email
        });
      } else {
        this.setState({ ["error_" + name]: "" });
      }
      this.setState({ [name]: value });
    } else {
      this.setState({ [name]: value, ["error_" + name]: "" });
    }
  };

  /* Forgot Function */
  forgotpwd() {
    let error = 0;
    if (this.state.email === "") {
      this.setState({ error_email: this.state.lang.common.field_required });
      error++;
    } else {
      if (emailValidate(this.state.email) === false) {
        this.setState({
          error_email: this.state.lang.common.invalid_email
        });
        error++;
      } else {
        this.setState({ error_email: "" });
      }
    }

    if (error === 0) {
      this.setState({ frmloading: true });
      let postdata = {
        email: this.state.email
      };
      this.props.getForgot(postdata);
    }
  }

  render() {
    return (
      <div className="center">
        <Helmet>
          <title>{site_title(this.state.lang.forgot.title)}</title>
          <meta name="keywords" content={this.state.lang.forgot.title} />
          <meta name="description" content={this.state.lang.forgot.title} />
        </Helmet>
        <div className="land_wrapper">
          <div className="land_content forget_content">
            <div className="container">
              <div className="lading_logo">
                <img
                  src={DisplayImage("landingLogo")}
                  alt={this.state.lang.common.title}
                />
              </div>
              <div className="landing_form forget_form">
                <h1 className="title1 text-uppercase">
                  {this.state.lang.forgot.title}
                </h1>
                <form className="form_sec">
                  <div className="form-group">
                    <div className="input_field">
                      <input
                        type="text"
                        name="email"
                        placeholder={this.state.lang.sign_up.emailid}
                        className={
                          this.state.error_email !== ""
                            ? "form-control error"
                            : "form-control"
                        }
                        onChange={this.handleChangeTxt.bind(this)}
                        value={this.state.email}
                      />
                      <div className="input_glowanimation"></div>
                      {this.state.error_email !== "" && (
                        <label className="error">
                          {this.state.error_email}
                        </label>
                      )}
                    </div>
                  </div>
                  <div className="form-group text-center">
                    <button
                      className={
                        this.state.frmloading === true
                          ? "btn btn_yellow text-uppercase btn-block text-uppercase load_btn"
                          : "btn btn_yellow text-uppercase btn-block text-uppercase"
                      }
                      disabled={this.state.frmloading}
                      onClick={e => {
                        e.preventDefault();
                        this.forgotpwd();
                      }}
                    >
                      <span>{this.state.lang.forgot.forgotbtn}</span>
                    </button>
                  </div>
                </form>
              </div>
              <div className="landing_botsec">
                <div className="landing_sublinks">
                  <Link
                    to={"/login"}
                    title={this.state.lang.login.sign_in}
                    data-text={this.state.lang.login.sign_in}
                  >
                    <span>{this.state.lang.login.sign_in}</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {poweredByFootre()}
        </div>
        <ModalPopup
          modal={this.state.modalmsg}
          toggle={this.togglemsg}
          closebtn={true}
          innerclass="popup_alert"
        >
          {this.state.error !== "" && (
            <div>
              <div className="popalert_top">
                <div className="popaler_info">
                  <i>
                    <img src={DisplayImage("ico_failed")} alt="" />
                  </i>
                  <h3 className="title2 text-uppercase">
                    {this.state.lang.forgot.error}
                  </h3>
                  <p>{DisplayContent(this.state.error)}</p>
                </div>
              </div>
              <button
                className="btn btn_yellow btn-lg btn-block arch_btn text-uppercase"
                onClick={this.togglemsg}
              >
                {this.state.lang.common.close}
              </button>
            </div>
          )}
          {this.state.successmsg !== "" && (
            <div>
              <div className="popalert_top">
                <div className="popaler_info">
                  <i>
                    <img src={DisplayImage("ico_tick")} alt="" />
                  </i>
                  <h3 className="title2 text-uppercase">
                    {this.state.lang.forgot.success}
                  </h3>
                  <p>{DisplayContent(this.state.successmsg)}</p>
                </div>
              </div>
              <button
                className="btn btn_yellow btn-lg btn-block arch_btn text-uppercase"
                onClick={this.togglemsg}
              >
                {this.state.lang.common.close}
              </button>
            </div>
          )}
        </ModalPopup>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getForgot: Param => {
      dispatch({ type: GET_FORGOT, Param: Param });
    }
  };
};

const mapStateToProps = state => {
  return {
    forgotdata: state.forgotdata
  };
};

Index.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  })
};

Index = connect(mapStateToProps, mapDispatchToProps)(Index);

export default withRouter(Index);
