import { takeLatest, call, put } from "redux-saga/effects";
import { apiUrl } from "../components/Config/config";
import { GET_GENERATE_PASSWORD } from "../actions";
import Axios from "axios";

export const watchGetGeneratePassword = function*(Param) {
  yield takeLatest(GET_GENERATE_PASSWORD, workerGetGeneratePassword);
};

function* workerGetGeneratePassword(Param) {
  const result = yield call(getGeneratePassword, Param);
  yield put({
    type: "SET_GENERATE_PASSWORD",
    json: result.data || [{ error: result.message }]
  });
}

function getGeneratePassword(Param) {
  var qs = require("qs");
  return Axios.post(
    apiUrl + "customer/generate_password",
    qs.stringify(Param.Param)
  );
}
