/* eslint-disable */
import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const toggle1 = () => setModal1(!modal1);
class ModalPopup extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.modal}
          toggle={this.props.toggle}
          className={this.props.className}
          centered
          keyboard={false}
          backdrop="static"
        >
          <div
            className={
              this.props.innerclass !== ""
                ? "popup_sec " + this.props.innerclass
                : "popup_sec popup_terms"
            }
          >
            {this.props.closebtn === true && (
              <ModalHeader toggle={this.props.toggle}></ModalHeader>
            )}
            <ModalBody>{this.props.children}</ModalBody>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ModalPopup;
