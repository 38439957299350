/* Local */
/*export const siteUrl = "http://localhost:3000";
export const apiMainUrl = "http://192.168.2.101/more_than_friends/";
export const mediaUrl = "http://192.168.2.101/more_than_friends/media/";
export const apiUrl = "http://192.168.2.101/more_than_friends/v1/frontend/";*/

/* Live */

/*
export const siteUrl = "https://more-than-friends.ezzysales.com/";
export const siteUrlMyaccount = "https://more-than-friends.ezzysales.com/my-account";
export const apiMainUrl = "https://demo.ezzysales.com/more_than_friends/";
export const mediaUrl = "https://demo.ezzysales.com/more_than_friends/media/";
export const apiUrl =
  "https://demo.ezzysales.com/more_than_friends/v1/frontend/"; 

export const page_limit = 10;

export const siteTitle = "More Than Friends";*/


/*13-4-20*/
export const siteUrl =   "http://more-than-friends.microwix.com"; 
export const siteUrlMyaccount = "http://more-than-friends.microwix.com/my-account";
export const mediaUrl = "http://more-than-friends-api.microwix.com/media/";
export const apiMainUrl = "http://more-than-friends-api.microwix.com/";
export const apiUrl = "http://more-than-friends-api.microwix.com/v1/frontend/";
export const page_limit = 10;
export const siteTitle = "More Than Friends";




