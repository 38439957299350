import { takeLatest, call, put } from "redux-saga/effects";
import { apiUrl } from "../components/Config/config";
import { GET_PROJECTS } from "../actions";
import cookie from "react-cookies";
import Axios from "axios";

export const watchGetProjects = function*() {
  yield takeLatest(GET_PROJECTS, workerGetProjects);
};

function* workerGetProjects(Param) {
  const result = yield call(getProjects, Param);
  yield put({ type: "SET_PROJECTS", json: result.data });
}

function getProjects(Param) {
  const options = {
    headers: { Auth: cookie.load("user_details").token }
  };
  let parameter = "";
  if (Param.param !== "" && typeof Param.param !== "undefined") {
    parameter = "?" + Param.param;
  }
  return Axios.get(apiUrl + "projects/listProjects" + parameter, options).then(
    response => {
      return response;
    },
    error => {
      return { data: { status: error.response.data.status } };
    }
  );
}
