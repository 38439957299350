import { takeLatest, call, put } from "redux-saga/effects";
import { apiUrl } from "../components/Config/config";
import { GET_EMPLOYEE_LIST } from "../actions";
import Axios from "axios";

export const watchGetEmployeelist = function*(Param) {
  yield takeLatest(GET_EMPLOYEE_LIST, workerGetEmployeelist);
};

function* workerGetEmployeelist(Param) {
  const result = yield call(getEmployeelist, Param);
  const employee = [];
  let result_values = result.data.result_set;
      result_values.map(function(item){
    	 employee.push({
            value: item.id,
            label: item.employee_name
        });
    });
  yield put({ type: "SET_EMPLOYEE_LIST", json: employee || [{ error: result.message }] });
}

function getEmployeelist(Param) {
  return Axios.get(apiUrl + "employees/listEmployees?");
}
