/* eslint-disable */
import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { GET_RESET_PASSWORD } from "../../actions";
import {
  language,
  site_title,
  checkauthentication,
  poweredByFootre,
  ScrollTop,
  DisplayImage,
  DisplayContent
} from "../Config/Helpers";
import ModalPopup from "../Layout/ModalPopup";
import { Helmet } from "react-helmet";

class Index extends React.Component {
  constructor(props) {
    super(props);
    if (checkauthentication() === true) {
      const { history } = this.props;
      history.push("/");
    }
    if (
      this.props.match.params.forgotKey === "" ||
      typeof this.props.match.params.forgotKey === "undefined"
    ) {
      const { history } = this.props;
      history.push("/login");
    }

    this.state = {
      language: language(),
      frmloading: false,
      password: "",
      error_password: "",
      cpassword: "",
      error_cpassword: "",
      logindata: "",
      pass_key: this.props.match.params.forgotKey,
      error: "",
      success: ""
    };
    this.togglemsg = this.togglemsg.bind(this);
  }
  componentDidMount() {
    ScrollTop();
  }

  componentDidUpdate(prevProps) {
    if (this.props.resetpwddata !== prevProps.resetpwddata) {
      /* Reset Password Response */
      this.setState({ frmloading: false });
      if (
        this.props.resetpwddata.status !== "" &&
        typeof this.props.resetpwddata.status !== "undefined"
      ) {
        if (this.props.resetpwddata.status === "ok") {
          this.setState(
            {
              error: "",
              success: this.props.resetpwddata.message,
              password: "",
              cpassword: ""
            },
            function() {
              this.togglemsg();
            }
          );
          const { history } = this.props;
          setTimeout(function() {
            history.push("/login");
          }, 4000);
        } else {
          this.setState(
            {
              error: this.props.resetpwddata.message,
              success: ""
            },
            function() {
              this.togglemsg();
            }
          );
        }
      }
    }
  }

  togglemsg() {
    this.setState(prevState => ({
      modalmsg: !prevState.modalmsg
    }));
  }

  /* Set State for input value */
  handleChangeTxt = event => {
    let { name, value } = event.target;
    this.setState({ [name]: value, ["error_" + name]: "" });
  };

  /* Reset Function */
  resetpwd() {
    let error = 0;
    if (this.state.password === "") {
      this.setState({
        error_password: this.state.language.common.field_required
      });
      error++;
    } else {
      if (this.state.password.length < 6) {
        this.setState({
          error_password: this.state.language.common.passwordmin
        });
        error++;
      } else {
        this.setState({ error_password: "" });
      }
    }

    if (this.state.cpassword === "") {
      this.setState({
        error_cpassword: this.state.language.common.field_required
      });
      error++;
    } else {
      if (this.state.password !== this.state.cpassword) {
        this.setState({
          error_cpassword: this.state.language.resetpwd.no_match_pwd
        });
        error++;
      } else {
        this.setState({ error_cpassword: "" });
      }
    }

    if (error === 0) {
      this.setState({ frmloading: true });
      let postdata = {
        password: this.state.password,
        cpassword: this.state.cpassword,
        pass_key: this.state.pass_key
      };
      this.props.getRestPassword(postdata);
    }
  }

  render() {
    return (
      <div className="center">
        <Helmet>
          <title>{site_title(this.state.language.resetpwd.title)}</title>
          <meta name="keywords" content={this.state.language.resetpwd.title} />
          <meta
            name="description"
            content={this.state.language.resetpwd.title}
          />
        </Helmet>
        <div className="land_wrapper">
          <div className="land_content forget_content">
            <div className="container">
              <div className="lading_logo">
                <img
                  src={DisplayImage("landingLogo")}
                  alt={this.state.language.common.title}
                />
              </div>
              <div className="landing_form forget_form">
                <h1 className="title1 text-uppercase">
                  {this.state.language.resetpwd.title}
                </h1>
                <form className="form_sec">
                  <div className="form-group">
                    <div className="input_field">
                      <input
                        type="password"
                        name="password"
                        placeholder={this.state.language.login.password}
                        className={
                          this.state.error_password !== ""
                            ? "form-control error"
                            : "form-control"
                        }
                        onChange={this.handleChangeTxt.bind(this)}
                        value={this.state.password}
                      />
                      <div className="input_glowanimation"></div>
                      {this.state.error_password !== "" && (
                        <label className="error">
                          {this.state.error_password}
                        </label>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input_field">
                      <input
                        type="password"
                        name="cpassword"
                        placeholder={this.state.language.login.cpassword}
                        className={
                          this.state.error_cpassword !== ""
                            ? "form-control error"
                            : "form-control"
                        }
                        onChange={this.handleChangeTxt.bind(this)}
                        value={this.state.cpassword}
                      />
                      <div className="input_glowanimation"></div>
                      {this.state.error_cpassword !== "" && (
                        <label className="error">
                          {this.state.error_cpassword}
                        </label>
                      )}
                    </div>
                  </div>
                  <div className="form-group text-center">
                    <button
                      className={
                        this.state.frmloading === true
                          ? "btn btn_yellow text-uppercase btn-block text-uppercase load_btn"
                          : "btn btn_yellow text-uppercase btn-block text-uppercase"
                      }
                      disabled={this.state.frmloading}
                      onClick={e => {
                        e.preventDefault();
                        this.resetpwd();
                      }}
                    >
                      <span>{this.state.language.resetpwd.reset}</span>
                    </button>
                  </div>
                </form>
              </div>
              <div className="landing_botsec">
                <div className="landing_sublinks">
                  <Link
                    to={"/login"}
                    title={this.state.language.login.login}
                    data-text={this.state.language.login.login}
                  >
                    <span>{this.state.language.login.login}</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {poweredByFootre()}
        </div>
        <ModalPopup
          modal={this.state.modalmsg}
          toggle={this.togglemsg}
          closebtn={true}
          innerclass="popup_alert"
        >
          {this.state.error !== "" && (
            <div>
              <div className="popalert_top">
                <div className="popaler_info">
                  <i>
                    <img src={DisplayImage("ico_failed")} alt="" />
                  </i>
                  <h3 className="title2 text-uppercase">
                    {this.state.language.resetpwd.faild}
                  </h3>
                  <p>{DisplayContent(this.state.error)}</p>
                </div>
              </div>
              <button
                className="btn btn_yellow btn-lg btn-block arch_btn text-uppercase"
                onClick={this.togglemsg}
              >
                {this.state.language.common.close}
              </button>
            </div>
          )}
          {this.state.success !== "" && (
            <div>
              <div className="popalert_top">
                <div className="popaler_info">
                  <i>
                    <img src={DisplayImage("ico_tick")} alt="" />
                  </i>
                  <h3 className="title2 text-uppercase">
                    {this.state.language.resetpwd.success}
                  </h3>
                  <p>{DisplayContent(this.state.success)}</p>
                </div>
              </div>
              <button
                className="btn btn_yellow btn-lg btn-block arch_btn text-uppercase"
                onClick={this.togglemsg}
              >
                {this.state.language.common.close}
              </button>
            </div>
          )}
        </ModalPopup>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getRestPassword: Param => {
      dispatch({ type: GET_RESET_PASSWORD, Param: Param });
    }
  };
};

const mapStateToProps = state => {
  return {
    resetpwddata: state.resetpwddata
  };
};

Index.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  })
};

Index = connect(mapStateToProps, mapDispatchToProps)(Index);

export default withRouter(Index);
