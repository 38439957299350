/* Login */
export const GET_LOGIN = "getLogin";
export const SET_LOGIN = "setLogin";

/* Profile Details */
export const GET_PROFILE = "getProfile";
export const SET_PROFILE = "setProfile";

/*Get MyAccount Profile Details*/
export const GET_MYACCOUNT_PROFILE = "getMyAccountProfile";
export const SET_MYACCOUNT_PROFILE = "setMyAccountProfile";

/* FB Login */
export const GET_FBLOGIN = "getFBLogin";
export const SET_FBLOGIN = "setFBLogin";

/* Activate Account */
export const GET_ACTIVATE_ACCOUNT = "getActivateAccount";
export const SET_ACTIVATE_ACCOUNT = "setActivateAccount";

/* Generate Password */
export const GET_GENERATE_PASSWORD = "getGeneratePassword";
export const SET_GENERATE_PASSWORD = "setGeneratePassword";

/* Registration */
export const GET_REGISTER = "getRegister";
export const SET_REGISTER = "setRegister";

/* Categories List */
export const GET_CATEGORIES = "getCategories";
export const SET_CATEGORIES = "setCategories";

/* Deals List */
export const GET_DEALS = "getDeals";
export const SET_DEALS = "setDeals";

/* Download Deals List */
export const GET_DOWNLOAD_DEALS = "getDownloadDeals";
export const SET_DOWNLOAD_DEALS = "setDownloadDeals";

/* Deals Detail */
export const GET_DEALDETAIL = "getDealDetail";
export const SET_DEALDETAIL = "setDealDetail";

/* Create Deal Redeem */
export const GET_DEAL_REDEEM = "getDealRedeem";
export const SET_DEAL_REDEEM = "setDealRedeem";

/* Forgot Passowrd */
export const GET_FORGOT = "getForgot";
export const SET_FORGOT = "setForgot";

/* Reset Password */
export const GET_RESET_PASSWORD = "getResetPassword";
export const SET_RESET_PASSWORD = "setResetPassword";

/* Get Merchant List */
export const GET_MERCHANTS = "getMerchants";
export const SET_MERCHANTS = "setMerchants";

/* Get Project List */
export const GET_PROJECTS = "getProjects";
export const SET_PROJECTS = "setProjects";

/* Get Static Block List */
export const GET_STATIC_BLOCK = "getStaticBlock";
export const SET_STATIC_BLOCK = "setStaticBlock";

/* Get Employee List */
export const GET_EMPLOYEE_LIST  = "getEmployeeList";
export const SET_EMPLOYEE_LIST  = "setEmployeeList";

/* Get Page Content */
export const GET_PAGE = "getPage";
export const SET_PAGE = "setPage";

/* Get Referral */
export const GET_REFERRAL = "getReferral";
export const SET_REFERRAL = "setReferral";

/* Get Announcement */
export const GET_ANNOUNCEMENT = "getAnnouncement";
export const SET_ANNOUNCEMENT = "setAnnouncement";

/* Get Unread Announcement */
export const GET_UNREADANNOUNCEMENT = "getUnreadAnnouncement";
export const SET_UNREADANNOUNCEMENT = "setUnreadAnnouncement";

/* Get Read Announcement */
export const GET_READANNOUNCEMENT = "getReadAnnouncement";
export const SET_READANNOUNCEMENT = "setReadAnnouncement";

/* Get Update Profile */
export const GET_UPDATE_PROFILE = "getUpdateProfile";
export const SET_UPDATE_PROFILE = "setUpdateProfile";

/* Create Best Showroom */
export const GET_BESTSHOWROOM = "getBestshowroom";
export const SET_BESTSHOWROOM = "setBestshowroom";

/* Create Best Showroom */
export const GET_SETTING = "getSetting";
export const SET_SETTING = "setSetting";

/* Get Showroom */
export const GET_SHOWROOM = "getShowroom";
export const SET_SHOWROOM = "setShowroom";


