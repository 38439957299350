import { takeLatest, call, put } from "redux-saga/effects";
import { apiUrl } from "../components/Config/config";
import { GET_READANNOUNCEMENT } from "../actions";
import cookie from "react-cookies";
import Axios from "axios";

export const watchGetReadAnnouncement = function*(Param) {
  yield takeLatest(GET_READANNOUNCEMENT, workerGetReadAnnouncement);
};

function* workerGetReadAnnouncement(Param) {
  const result = yield call(getReadAnnouncement, Param);
  yield put({
    type: "SET_READANNOUNCEMENT",
    json: result.data || [{ error: result.message }]
  });
}

function getReadAnnouncement(Param) {
  var qs = require("qs");
  const options = {
    headers: { Auth: cookie.load("user_details").token }
  };
  return Axios.post(
    apiUrl + "announcement/updatereadannouncement",
    qs.stringify(Param.Param),
    options
  ).then(
    response => {
      return response;
    },
    error => {
      return { data: { status: error.response.data.status } };
    }
  );
}
