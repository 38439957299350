import { takeLatest, call, put } from "redux-saga/effects";
import { apiUrl } from "../components/Config/config";
import { GET_PROFILE, GET_MYACCOUNT_PROFILE} from "../actions";
import cookie from "react-cookies";
import Axios from "axios";

export const watchGetProfile = function*() {
  yield takeLatest(GET_PROFILE, workerGetProfile);
};

function* workerGetProfile() {
  const result = yield call(getProfile);
  yield put({ type: "SET_PROFILE", json: result.data });
}

function getProfile() {
  const options = {
    headers: { Auth: cookie.load("user_details").token }
  };

  return Axios.get(apiUrl + "customer/profile", options).then(
    response => {
      return response;
    },
    error => {
      return { data: { status: error.response.data.status } };
    }
  );
}

/*MyAccount Profile */

export const watchGetMyAccountProfile = function*() {
  yield takeLatest(GET_MYACCOUNT_PROFILE, workerGetMyAccountProfile);
};

function* workerGetMyAccountProfile() {
  const result = yield call(getMyAccountProfile);
  yield put({ type: "SET_MYACCOUNT_PROFILE", json: result.data });
}

function getMyAccountProfile() {
  const options = {
    headers: { Auth: cookie.load("user_details").token }
  };

  return Axios.get(apiUrl + "customer/profile", options).then(
    response => {
      return response;
    },
    error => {
      return { data: { status: error.response.data.status } };
    }
  );
} 


