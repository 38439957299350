import { takeLatest, call, put } from "redux-saga/effects";
import { apiUrl } from "../components/Config/config";
import { GET_LOGIN } from "../actions";
import Axios from "axios";

export const watchGetLogin = function*(Param) {
  yield takeLatest(GET_LOGIN, workerGetLogin);
};

function* workerGetLogin(Param) {
  const result = yield call(getLogin, Param);
  yield put({
    type: "SET_LOGIN",
    json: result.data || [{ error: result.message }]
  });
}

function getLogin(Param) {
  var qs = require("qs");
  return Axios.post(apiUrl + "customer/login", qs.stringify(Param.Param));
}
