import { takeLatest, call, put } from "redux-saga/effects";
import { apiUrl } from "../components/Config/config";
import { GET_ACTIVATE_ACCOUNT } from "../actions";
import Axios from "axios";

export const watchGetActivateAccount = function*(Param) {
  yield takeLatest(GET_ACTIVATE_ACCOUNT, workerGetActivateAccount);
};

function* workerGetActivateAccount(Param) {
  const result = yield call(getActivateAccount, Param);
  yield put({
    type: "SET_ACTIVATE_ACCOUNT",
    json: result.data || [{ error: result.message }]
  });
}

function getActivateAccount(Param) {
  var qs = require("qs");
  return Axios.post(
    apiUrl + "customer/activation_account",
    qs.stringify(Param.Param)
  );
}
