import { takeLatest, call, put } from "redux-saga/effects";
import { apiUrl } from "../components/Config/config";
import { GET_BESTSHOWROOM, GET_SHOWROOM} from "../actions";
import cookie from "react-cookies";
import Axios from "axios";

export const watchGetBestShowroom = function*() {
  yield takeLatest(GET_BESTSHOWROOM, workerGetBestShowroom);
};

function* workerGetBestShowroom(Param) {
  const result = yield call(getBestShowroom, Param);
  yield put({
    type: "SET_BESTSHOWROOM",
    json: result.data || [{ error: result.message }]
  });
}

function getBestShowroom(Param) {
  const options = {
    headers: { Auth: cookie.load("user_details").token }
  };
  return Axios.post(apiUrl + "best_showroom/add", Param.param, options).then(
    response => {
      return response;
    },
    error => {
      return { data: { status: error.response.data.status } };
    }
  );
}


export const watchGetListShowroom = function*() {
  yield takeLatest(GET_SHOWROOM, workerGetListShowroom);
};

function* workerGetListShowroom(Param) {
  const result = yield call(getListShowroom, Param);

  const showroom = [];
  let result_values = result.data.result_set;
      result_values.map(function(item){
       showroom.push({
            value: item.id,
            label: item.showroom_name
        });
    });


  yield put({ type: "SET_SHOWROOM", json: showroom });
}

function getListShowroom(Param) {
  const options = {
    headers: { Auth: cookie.load("user_details").token }
  };


  let parameter = "";
  if (Param.param !== "" && typeof Param.param !== "undefined") {
    parameter = "?" + Param.param;
  }
  return Axios.get(apiUrl + "best_showroom/listshowroom" + parameter, options).then(
    response => {
      return response;
    },
    error => {
      return { data: { status: error.response.data.status } };
    }
  );
}




