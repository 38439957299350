/* eslint-disable */
import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import cookie from "react-cookies";
import { Pagination, PaginationItem } from "reactstrap";
import { GET_DOWNLOAD_DEALS } from "../../actions";
import { page_limit } from "../Config/config";
import { Helmet } from "react-helmet";
import {
  language,
  site_title,
  checkauthentication,
  getImage,
  LoadingSec,
  headerDetails,
  Encrypt,
  IntialLoading,
  DateDiff,
  DisplayContent
} from "../Config/Helpers";

class Index extends React.Component {
  constructor(props) {
    super(props);
    if (checkauthentication() === false) {
      const { history } = this.props;
      history.push("/login");
    }
    this.state = {
      frmloading: true,
      userdetails: cookie.load("user_details"),
      lang: language(),
      deallist: "",
      pagenation: "",
      page: 1
    };
  }

  componentDidMount() {
    let params = "page=" + this.state.page;
    this.props.getDownloadDeals(params);
  }

  componentDidUpdate(prevProps) {
    if (this.props.downloaddealdata !== prevProps.downloaddealdata) {
      /* Deals Response */
      this.setState({ frmloading: false });
      if (
        this.props.downloaddealdata.status !== "" &&
        typeof this.props.downloaddealdata.status !== "undefined" &&
        typeof this.props.downloaddealdata.status !== undefined
      ) {
        if (this.props.downloaddealdata.status === false) {
          const { history } = this.props;
          history.push("/logout");
        } else if (this.props.downloaddealdata.status === "ok") {
          if (
            this.props.downloaddealdata.total_records !== "" &&
            typeof this.props.downloaddealdata.total_records !== "undefined" &&
            typeof this.props.downloaddealdata.total_records !== undefined
          ) {
            this.setState(
              { total_records: this.props.downloaddealdata.total_records },
              function() {
                this.loadPagenation();
              }
            );
          }
          this.setState(
            { deal_details: this.props.downloaddealdata.result_set },
            function() {
              this.loadDeails();
              this.loadPagenation();
            }
          );
        }
      }
    }
  }

  /* Load Deals List */
  loadDeails() {
    let deallist;
    if (
      this.state.deal_details !== "" &&
      typeof this.state.deal_details !== "undefined"
    ) {
      deallist = this.state.deal_details.map((item, index) => {
        return (
          <div className="item_row" key={index}>
            <Link
              to={{
                pathname: "/deal-detail/" + Encrypt(item.deal_id, "e"),
                state: { pagetype: "download" }
              }}
            >
              <div className="item_img">
                {DateDiff(item.end_date) === "Expired" ? (
                  <span className="offer_label deal_status  deal_status_expired">
                    {this.state.lang.deals.expired}
                  </span>
                ) : (
                  DateDiff(item.end_date) !== "Expired" && (
                    <span className="offer_label deal_status  deal_status_active">
                      {this.state.lang.deals.active}
                    </span>
                  )
                )}

                <img src={getImage(item.deal_image, "deals")} alt="" />
              </div>
              <div className="item_info">
                <h3>{item.deal_name}</h3>
                <p className="deal_buyoff">
                  {" "}
                  {item.description !== "" && item.description !== null
                    ? DisplayContent(item.description)
                    : ""}
                </p>
                <p className="deal_loc">
                  {item.location}{" "}
                  {item.location > 1
                    ? this.state.lang.deals.locations
                    : this.state.lang.deals.location}
                </p>
                <span className="deals_coins">
                  <i className="ico_r text-lowercase"></i>
                  {item.coin}
                </span>
              </div>
            </Link>
          </div>
        );
      });
    } else {
      this.setState({ total_records: 0 }, function() {
        this.loadPagenation();
      });
      deallist = (
        <div className="norecord_sec text-center">
          <i className="norecord_ico"></i>
          <h4>{this.state.lang.deals.no_deals}</h4>
        </div>
      );
    }
    this.setState({ deallist: deallist });
  }

  /* Load Pagenation Desaign */
  loadPagenation() {
    let total_records = this.state.total_records;
    let totalPage = total_records / page_limit;
    let pagenation = "";
    let prev = this.state.page - 1;
    let next = parseInt(this.state.page) + 1;
    if (Math.ceil(totalPage) > 1) {
      pagenation = (
        <Pagination aria-label="Page navigation example">
          {this.state.page > 1 && (
            <PaginationItem onClick={this.loadMore.bind(this, prev)}>
              <span
                className="page-link"
                aria-label={this.state.lang.common.prev}
              >
                <span aria-hidden="true">‹</span>
                <span className="sr-only">{this.state.lang.common.prev}</span>
              </span>
            </PaginationItem>
          )}
          {this.loadpageList()}
          {parseInt(this.state.page) + 1 <= Math.ceil(totalPage) && (
            <PaginationItem onClick={this.loadMore.bind(this, next)}>
              <span
                href="#"
                className="page-link"
                aria-label={this.state.lang.common.next}
              >
                <span aria-hidden="true">›</span>
                <span className="sr-only">{this.state.lang.common.next}</span>
              </span>
            </PaginationItem>
          )}
        </Pagination>
      );
    }
    this.setState({ pagenation: pagenation });
  }

  /* Pagenation Number List */
  loadpageList() {
    let currentPage = this.state.page > 1 ? parseInt(this.state.page) - 1 : 1;
    let total_records = this.state.total_records;
    let totalPage = total_records / page_limit;
    let nextloop =
      parseInt(currentPage) + 3 <= totalPage
        ? parseInt(currentPage) + 3
        : totalPage;
    if (currentPage - nextloop <= 3) {
      currentPage = nextloop - 3;
      if (currentPage <= 0) {
        currentPage = 1;
      }
    }
    let ToReturn = [];
    for (var i = Math.ceil(currentPage); i <= Math.ceil(nextloop); i++) {
      ToReturn.push(
        <PaginationItem
          key={i}
          className={this.state.page === i ? "active" : ""}
          onClick={this.loadMore.bind(this, i)}
        >
          <span className="page-link">{i}</span>
        </PaginationItem>
      );
    }
    return ToReturn;
  }

  /* Apply Pagenation */
  loadMore(page) {
    if (page !== this.state.page) {
      this.setState({ page: page, frmloading: true }, function() {
        let params = "page=" + this.state.page;
        this.props.getDownloadDeals(params);
      });
    }
  }

  render() {
    if (
      this.state.userdetails !== "" &&
      typeof this.state.userdetails !== "undefined"
    ) {
      return (
        <div className="center grow_flex">
          <Helmet>
            <title>{site_title(this.state.lang.download.title)}</title>
            <meta name="keywords" content={this.state.lang.download.title} />
            <meta name="description" content={this.state.lang.download.title} />
          </Helmet>
          {headerDetails()}
          <div className="deals_topbar">
            <div className="deals_ttrow">
              <h3 className="title2">{this.state.lang.deals.title}</h3>
            </div>
            <div className="tablinks">
              <Link
                to={"/hot-deals"}
                className=" text-uppercase"
                title={this.state.lang.deals.all_deals}
              >
                {this.state.lang.deals.all_deals}
              </Link>
              <Link
                to={"/download-deals"}
                className="active text-uppercase"
                title={this.state.lang.deals.downloaded}
              >
                {this.state.lang.deals.downloaded}
              </Link>
            </div>
          </div>
          <div
            className={
              this.state.frmloading === true
                ? "item_list load_wrapper load-wrapper-mheight"
                : "item_list"
            }
          >
            {this.state.frmloading === true && LoadingSec()}
            {this.state.deallist}
          </div>
          {this.state.pagenation !== "" && (
            <div className="pagination_sec">{this.state.pagenation}</div>
          )}
        </div>
      );
    } else {
      return IntialLoading();
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getDownloadDeals: params => {
      dispatch({ type: GET_DOWNLOAD_DEALS, params });
    }
  };
};

const mapStateToProps = state => {
  return {
    downloaddealdata: state.downloaddealdata
  };
};

Index.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  })
};

Index = connect(mapStateToProps, mapDispatchToProps)(Index);

export default withRouter(Index);
