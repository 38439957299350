import { takeLatest, call, put } from "redux-saga/effects";
import { apiUrl } from "../components/Config/config";
import { GET_STATIC_BLOCK } from "../actions";
import Axios from "axios";

export const watchGetStaticBlock = function*(Param) {
  yield takeLatest(GET_STATIC_BLOCK, workerGetStaticBlock);
};

function* workerGetStaticBlock(Param) {
  const result = yield call(getStaticBlock, Param);
  yield put({ type: "SET_STATIC_BLOCK", json: result.data });
}

function getStaticBlock(Param) {
  return Axios.get(apiUrl + "pages/staticblocks?" + Param.Param);
}
