/* eslint-disable */
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import cookie from "react-cookies";
import { GET_ANNOUNCEMENT } from "../../actions";
import {
  language,
  headerDetails,
  checkauthentication,
  IntialLoading,
  getImage,
  DisplayContent,
  CreateMeta
} from "../Config/Helpers";

class Index extends React.Component {
  constructor(props) {
    super(props);
    if (checkauthentication() === false) {
      const { history } = this.props;
      history.push("/login");
    }
    this.state = {
      userdetails: cookie.load("user_details"),
      lang: language(),
      announcement_details: "",
      announcement: ""
    };
    this.props.getAnnouncement();
  }

  componentDidUpdate(prevProps) {
    if (this.props.announcementdata !== prevProps.announcementdata) {
      /* Announcement Response */
      this.setState({ frmloading: false });
      if (
        this.props.announcementdata.status !== "" &&
        typeof this.props.announcementdata.status !== "undefined" &&
        typeof this.props.announcementdata.status !== undefined
      ) {
        if (this.props.announcementdata.status === false) {
          const { history } = this.props;
          history.push("/logout");
        } else if (this.props.announcementdata.status === "ok") {
          this.setState(
            { announcement_details: this.props.announcementdata.result_set },
            function() {
              this.loadAnnouncement();
            }
          );
        }
      }
    }
  }
  loadAnnouncement() {
    let announcement = "";
    if (
      this.state.announcement_details !== "" &&
      typeof this.state.announcement_details !== "undefined"
    ) {
      announcement = this.state.announcement_details.map((item, index) => {
        return (
          <div className="announce_item" key={index}>
            <div className="announce_itemimg">
              <img src={getImage(item.image, "announcement")} alt="" />
            </div>
            <div className="announce_iteminfo">
              <h3>{item.title}</h3>
              <p>{DisplayContent(item.description)}</p>
            </div>
          </div>
        );
      });
    } else {
      announcement = (
        <div className="no-record">
          {this.state.lang.announcement.no_announcement}
        </div>
      );
    }
    this.setState({ announcement: announcement });
  }

  render() {
    if (
      this.state.userdetails !== "" &&
      typeof this.state.userdetails !== "undefined"
    ) {
      return (
        <div className="center">
          {CreateMeta(this.state.lang.announcement)}
          {headerDetails()}
          <div className="announce_sec">
            <div className="container">
              <div className="announce_list">{this.state.announcement}</div>
            </div>
          </div>
        </div>
      );
    } else {
      return IntialLoading();
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getAnnouncement: param => {
      dispatch({ type: GET_ANNOUNCEMENT });
    }
  };
};

const mapStateToProps = state => {
  return {
    announcementdata: state.announcementdata
  };
};

Index.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  })
};

Index = connect(mapStateToProps, mapDispatchToProps)(Index);

export default withRouter(Index);
