/* eslint-disable */
import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Select from 'react-select';
import {
  language,
  site_title,
  checkauthentication,
  emailValidate,
  numariconly,
  poweredByFootre,
  ScrollTop,
  DisplayImage,
  DisplayContent
} from "../Config/Helpers";
import { GET_REGISTER, GET_STATIC_BLOCK, GET_EMPLOYEE_LIST } from "../../actions";
import ModalPopup from "../Layout/ModalPopup";
import { Scrollbars } from "react-custom-scrollbars";
import { Helmet } from "react-helmet";
import ScrollAnimation from "react-animate-on-scroll";

class Index extends React.Component {
  constructor(props) {
    super(props);
    if (checkauthentication() === true) {
      const { history } = this.props;
      history.push("/");
    }
    this.state = {
      frmloading: false,
      lang: language(),
      referral_code: this.props.location.search,
      error: "",
      success: "",
      name: "",
      email: "",
      password: "",
      contact_number: "",
      reference_id: "", 
      reference_value:"",
      error_name: "",
      error_email: "",
      error_password: "",
      error_contact_number: "",
      modalStaticBlock: false,
      removePhoto: false,
      image_error: "",
      employeeData:[], 
      profileImage: DisplayImage("profile1")
    };
    this.toggleStaticBlock = this.toggleStaticBlock.bind(this);
    this.togglemsg = this.togglemsg.bind(this);
  }
  componentDidMount() {
    ScrollTop();
    let params = "slug=terms-of-use,privacy-policy";
    this.props.getStaticBlock(params);
    this.props.getEmployeeList();
  }

  componentDidUpdate(prevProps) {
    if (this.props.registerdata !== prevProps.registerdata) {
      /* Register Response */
      if (
        this.props.registerdata.status !== "" &&
        typeof this.props.registerdata.status !== "undefined" &&
        typeof this.props.registerdata.status !== undefined
      ) {
        this.setState({ frmloading: false });
        if (this.props.registerdata.status === "ok") {
          var image = document.getElementById("profileImage");
          image.value = null;
          image.src = DisplayImage("profile1");
          this.setState({
            name: "",
            email: "",
            password: "",
            contact_number: ""
          });
          this.setState(
            { success: this.props.registerdata.message },
            function() {
              this.togglemsg();
            }
          );
          this.setState({ error: "" });
        } else {
          this.setState({ success: "" });
          this.setState({ error: this.props.registerdata.message }, function() {
            this.togglemsg();
          });
        }
        //this.hideMessage();
      }
    }
    if (this.props.staticblockdata !== prevProps.staticblockdata) {
      if (this.props.staticblockdata.status === "ok") {
        this.setState({ staticblock: this.props.staticblockdata.result_set });
      }
    }

    if (this.props.employeeList !== prevProps.employeeList) {
         this.setState({
          employeeData:this.props.employeeList
         });
    }
  }

  toggleStaticBlock() {
    this.setState(prevState => ({
      modalStaticBlock: !prevState.modalStaticBlock
    }));
  }
  togglemsg() {
    this.setState(prevState => ({
      modalmsg: !prevState.modalmsg
    }));
  }
  /* Hide Message */
  hideMessage() {
    const current = this;
    setTimeout(function() {
      current.setState({ error: "", success: "" });
    }, 4000);
  }

  /* Set State for input value */
  handleChangeTxt = event => {
    let { name, value } = event.target;
    if (name === "email") {
      if (emailValidate(value) === false && value !== "") {
        this.setState({
          ["error_" + name]: this.state.lang.common.invalid_email
        });
      } else {
        this.setState({ ["error_" + name]: "" });
      }
      this.setState({ [name]: value });
    } else {
      this.setState({ [name]: value, ["error_" + name]: "" });
    }
  };

  handleSelectChange = (item, event) => { 
    this.setState({ [item]:event});
   //console.log(`Option selected:`, event);
   this.setState({ reference_value:event.value});
  }

  /* File bowse display image */
  onChangeFile(event) {
    event.stopPropagation();
    event.preventDefault();

    var selectedFile = event.target.files[0];
    var idxDot = selectedFile.name.lastIndexOf(".") + 1;
    var extFile = selectedFile.name
      .substr(idxDot, selectedFile.name.length)
      .toLowerCase();
    if (
      extFile === "jpg" ||
      extFile === "jpeg" ||
      extFile === "png" ||
      extFile === "svg" ||
      extFile === "gif"
    ) {
      var image = document.getElementById("profileImage");
      image.src = URL.createObjectURL(event.target.files[0]);
      this.setState({ image_error: "", removePhoto: true });
    } else {
      this.setState({ image_error: "Image file only allowed" });
    }
  }
  /* Registration function */
  register() {
    let error = 0;
    if (this.state.name === "") {
      this.setState({ error_name: this.state.lang.common.field_required });
      error++;
    } else {
      this.setState({ error_name: "" });
    }
    if (this.state.email === "") {
      this.setState({ error_email: this.state.lang.common.field_required });
      error++;
    } else {
      if (emailValidate(this.state.email) === false) {
        this.setState({
          error_email: this.state.lang.common.invalid_email
        });
      } else {
        this.setState({ error_email: "" });
      }
    }
    if (this.state.password === "") {
      this.setState({
        error_password: this.state.lang.common.field_required
      });
      error++;
    } else {
      if (this.state.password.length < 6) {
        this.setState({
          error_password: this.state.lang.common.passwordmin
        });
        error++;
      } else {
        this.setState({ error_password: "" });
      }
    }

    if (this.state.contact_number === "") {
      this.setState({
        error_contact_number: this.state.lang.common.field_required
      });
      error++;
    } else {
      this.setState({ error_contact_number: "" });
    }

    if (error === 0) {
      this.setState({ frmloading: true });
      let data = new FormData();
      let imagefile = document.querySelector("#profile_image");
      data.append("photo", imagefile.files[0]);
      data.append("name", this.state.name);
      data.append("email", this.state.email);
      data.append("password", this.state.password);
      data.append("mobile_number", this.state.contact_number);
      data.append("reference_id", this.state.reference_value);

      if (
        this.state.referral_code !== "" &&
        typeof this.state.referral_code !== "undefined"
      ) {
        let referral_code = this.state.referral_code.split("=");
        data.append("referral_code", referral_code[1]);
      }
      this.props.getRegister(data);
    }
  }
  removeProfileImage() {
    this.setState({ removePhoto: false });
    var image = document.getElementById("profileImage");
    image.src = DisplayImage("profile1");
  }

  loadBlock(slug) {
    if (
      this.state.staticblock !== "" &&
      this.state.staticblock[slug] !== "" &&
      typeof this.state.staticblock[slug] !== "undefined"
    ) {
      this.setState({ blockDetails: this.state.staticblock[slug] }, function() {
        this.toggleStaticBlock();
      });
    }
  }

  render() {
    return (
      <div className="center">
        <Helmet>
          <title>{site_title(this.state.lang.sign_up.title)}</title>
          <meta name="keywords" content={this.state.lang.sign_up.title} />
          <meta name="description" content={this.state.lang.sign_up.title} />
        </Helmet>
        <div className="land_wrapper">
          <div className="land_content signup_content">
            <div className="container">
              <div className="lading_logo">
                <ScrollAnimation
                  animateIn="fadeIn"
                  animateOnce={true}
                  offset={0}
                  delay={100}
                >
                  <img
                    src={DisplayImage("landingLogo")}
                    alt="More than Friends"
                  />
                </ScrollAnimation>
              </div>
              <div className="signup_form">
                <ScrollAnimation
                  animateIn="fadeIn"
                  animateOnce={true}
                  offset={0}
                  delay={300}
                >
                  <form className="form_sec">
                    <div className="form-group upload_imggroup">
                      <div className="input_field">
                        <div
                          className={
                            this.state.removePhoto === true
                              ? "choose_file text-center choose_img_available"
                              : "choose_file text-center"
                          }
                        >
                          {this.state.removePhoto === true && (
                            <lable
                              className="remove-image"
                              onClick={e => {
                                e.preventDefault();
                                this.removeProfileImage();
                              }}
                            >
                              remove
                            </lable>
                          )}
                          <input
                            type="file"
                            name="profile_image"
                            id="profile_image"
                            accept="image/*"
                            onChange={this.onChangeFile.bind(this)}
                          />
                          <div className="profile_img">
                            <img
                              src={this.state.profileImage}
                              id="profileImage"
                              alt={this.state.lang.sign_up.profile}
                            />
                            {this.state.removePhoto === true && (
                              <span
                                className="remove_profileimg"
                                id="aaaa"
                              ></span>
                            )}
                          </div>

                          <p data-text="Upload Profile Picture">
                            <span>{this.state.lang.sign_up.upload_image} </span>
                          </p>
                          {this.state.image_error !== "" && (
                            <label className="error">
                              {this.state.image_error}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-sm-4">
                          <label>{this.state.lang.sign_up.name}:</label>
                        </div>
                        <div className="col-sm-8">
                          <div className="input_field">
                            <input
                              type="text"
                              name="name"
                              className={
                                this.state.error_name !== ""
                                  ? "form-control error"
                                  : "form-control"
                              }
                              onChange={this.handleChangeTxt.bind(this)}
                              value={this.state.name}
                            />
                            <div className="input_glowanimation"></div>
                            {this.state.error_name !== "" && (
                              <label className="error">
                                {this.state.error_name}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-sm-4">
                          <label>{this.state.lang.sign_up.email}:</label>
                        </div>
                        <div className="col-sm-8">
                          <div className="input_field">
                            <input
                              type="text"
                              name="email"
                              className={
                                this.state.error_email !== ""
                                  ? "form-control error"
                                  : "form-control"
                              }
                              onChange={this.handleChangeTxt.bind(this)}
                              value={this.state.email}
                            />
                            <div className="input_glowanimation"></div>
                            {this.state.error_email !== "" && (
                              <label className="error">
                                {this.state.error_email}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-sm-4">
                          <label>{this.state.lang.sign_up.password}:</label>
                        </div>
                        <div className="col-sm-8">
                          <div className="input_field">
                            <input
                              type="password"
                              name="password"
                              className={
                                this.state.error_password !== ""
                                  ? "form-control error"
                                  : "form-control"
                              }
                              onChange={this.handleChangeTxt.bind(this)}
                              value={this.state.password}
                            />
                            <div className="input_glowanimation"></div>
                            {this.state.error_password !== "" && (
                              <label className="error">
                                {this.state.error_password}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>{this.state.lang.sign_up.contact_number}</label>
                      <div className="input_field contact-field">
                        <input
                          type="number"
                          name="contact_number"
                          className={
                            this.state.error_contact_number !== ""
                              ? "form-control error"
                              : "form-control"
                          }
                          onChange={this.handleChangeTxt.bind(this)}
                          value={this.state.contact_number}
                          onKeyPress={e => numariconly(e)}
                          onInput={e => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 8);
                          }}
                        />
                        <div className="input_glowanimation"></div>
                      </div>
                      {this.state.error_contact_number !== "" && (
                        <label className="error">
                          {this.state.error_contact_number}
                        </label>
                      )}
                    </div>

                    <div className="form-group">
                      <label>{this.state.lang.sign_up.referralId}</label>
                      <div className="input_field custom_select">
                      <Select
                      searchable='true'
                      onChange={this.handleSelectChange.bind(this, 'reference_id')}
                      placeholder={this.state.lang.sign_up.referralId}
                      name="reference_id"
                      options = {this.state.employeeData}
                      value={this.state.reference_id}
                        />
                      </div>
                    </div>

                      <div className="form-group">
                          <label>{this.state.lang.sign_up.referralId}</label>
                          <div className="input_field">
                            <div className="custom_select">
                              <Select
                                placeholder={this.state.lang.sign_up.referralId}
                                value={this.state.reference_id}
                                onChange={this.handleSelectChange.bind(this, 'reference_id')}
                                options = {this.state.employeeData}
                              />
                          
                            </div>
                          </div>
                        </div>


                    <ScrollAnimation
                      animateIn="fadeIn"
                      animateOnce={true}
                      offset={0}
                      delay={200}
                    >
                      <div className="form-group text-center">
                        <p className="ldform_terms text-center">
                          {this.state.lang.login.by_proceeding}{" "}
                          <a
                            href="#terms-of-use"
                            onClick={e => {
                              e.preventDefault();
                              this.loadBlock("terms-of-use");
                            }}
                          >
                            {DisplayContent(this.state.lang.login.terms)}
                          </a>{" "}
                          &{" "}
                          <a
                            href="#terms-of-use"
                            onClick={e => {
                              e.preventDefault();
                              this.loadBlock("privacy-policy");
                            }}
                          >
                            {DisplayContent(this.state.lang.login.privacy)}
                          </a>
                        </p>
                        <button
                          className={
                            this.state.frmloading === true
                              ? "btn btn_yellow btn-block text-uppercase load_btn"
                              : "btn btn_yellow btn-block text-uppercase"
                          }
                          disabled={this.state.frmloading}
                          onClick={e => {
                            e.preventDefault();
                            this.register();
                          }}
                        >
                          <span>{this.state.lang.sign_up.title}</span>
                        </button>
                      </div>
                    </ScrollAnimation>
                  </form>
                </ScrollAnimation>
              </div>
              <div className="landing_sublinks">
                <Link
                  to={"login"}
                  title={this.state.lang.login.sign_in}
                  data-text={this.state.lang.login.sign_in}
                >
                  <span>{this.state.lang.login.sign_in}</span>
                </Link>
              </div>
            </div>
          </div>
          {poweredByFootre()}
        </div>
        <ModalPopup
          modal={this.state.modalStaticBlock}
          toggle={this.toggleStaticBlock}
          closebtn={true}
          innerclass="popup_terms"
        >
          <div>
            {this.state.blockDetails !== "" &&
              typeof this.state.blockDetails !== "undefined" && (
                <div>
                  <h2 className="title1 text-center">
                    {this.state.blockDetails.title}
                  </h2>
                  <div className="smcustom_scroll">
                    <Scrollbars className="smcustom_scroll_in">
                      <div className="terms_content">
                        {DisplayContent(this.state.blockDetails.description)}
                      </div>
                    </Scrollbars>
                  </div>
                </div>
              )}
          </div>
        </ModalPopup>
        <ModalPopup
          modal={this.state.modalmsg}
          toggle={this.togglemsg}
          closebtn={true}
          innerclass="popup_alert"
        >
          {this.state.error !== "" && (
            <div>
              <div className="popalert_top">
                <div className="popaler_info">
                  <i>
                    <img src={DisplayImage("ico_failed")} alt="" />
                  </i>
                  <h3 className="title2">{this.state.lang.sign_up.faild}</h3>
                  <p>{DisplayContent(this.state.error)}</p>
                </div>
              </div>
              <button
                className="btn btn_yellow btn-lg btn-block arch_btn text-uppercase"
                onClick={this.togglemsg}
              >
                {this.state.lang.common.close}
              </button>
            </div>
          )}
          {this.state.success !== "" && (
            <div>
              <div className="popalert_top">
                <div className="popaler_info">
                  <i>
                    <img src={DisplayImage("ico_tick")} alt="" />
                  </i>
                  <h3 className="title2">{this.state.lang.sign_up.success}</h3>
                  <p>{DisplayContent(this.state.success)}</p>
                </div>
              </div>
              <Link to={"/login"}>
                <button
                  className="btn btn_yellow btn-lg btn-block arch_btn text-uppercase"
                  onClick={this.togglemsg}
                >
                  {this.state.lang.common.close}
                </button>
              </Link>
            </div>
          )}
        </ModalPopup>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getRegister: Param => {
      dispatch({ type: GET_REGISTER, Param: Param });
    },
    getStaticBlock: Param => {
      dispatch({ type: GET_STATIC_BLOCK, Param });
    },
    getEmployeeList: (Param) => {
      dispatch({ type: GET_EMPLOYEE_LIST });
    }
  };
};

const mapStateToProps = state => {
  return {
    registerdata: state.registerdata,
    staticblockdata: state.staticblockdata,
    employeeList   : state.employeeList,
  };
};

Index.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  })
};

Index = connect(mapStateToProps, mapDispatchToProps)(Index);

export default withRouter(Index);
