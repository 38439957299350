import { takeLatest, call, put } from "redux-saga/effects";
import { apiUrl } from "../components/Config/config";
import { GET_FORGOT } from "../actions";
import Axios from "axios";

export const watchGetForgot = function*(Param) {
  yield takeLatest(GET_FORGOT, workerGetForgot);
};

function* workerGetForgot(Param) {
  const result = yield call(getForgot, Param);
  yield put({
    type: "SET_FORGOT",
    json: result.data || [{ error: result.message }]
  });
}

function getForgot(Param) {
  var qs = require("qs");
  return Axios.post(
    apiUrl + "customer/forgot_password",
    qs.stringify(Param.Param)
  );
}
