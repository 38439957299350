import { takeLatest, call, put } from "redux-saga/effects";
import { apiUrl } from "../components/Config/config";
import { GET_REFERRAL } from "../actions";
import cookie from "react-cookies";
import Axios from "axios";

export const watchGetReferral = function*(Param) {
  yield takeLatest(GET_REFERRAL, workerGetReferral);
};

function* workerGetReferral(Param) {
  const result = yield call(getReferral, Param);
  yield put({
    type: "SET_REFERRAL",
    json: result.data || [{ error: result.message }]
  });
}

function getReferral(Param) {
  var qs = require("qs");
  const options = {
    headers: { Auth: cookie.load("user_details").token }
  };
  return Axios.post(
    apiUrl + "referral/refer_friend",
    qs.stringify(Param.Param),
    options
  ).then(
    response => {
      return response;
    },
    error => {
      return { data: { status: error.response.data.status } };
    }
  );
}
