/* eslint-disable */
import { takeLatest, call, put } from 'redux-saga/effects';
import { apiUrl } from "../components/Config/config";
import { GET_CATEGORIES } from '../actions';
import cookie from 'react-cookies';
import Axios from 'axios';


export const watchGetCategories = function* () {
 	yield takeLatest(GET_CATEGORIES, workerGetCategories);
}

function* workerGetCategories() {
    const result = yield call(getCategories);
    yield put({ type: "SET_CATEGORIES", json: result.data || [{ error: result.message }] });   
}

function getCategories() {
    const options = {
        headers: {'Auth': cookie.load('user_details').token}
    };
    return Axios.get(apiUrl+'deals/listcategory?', options).then( 
        (response) => { return response; },
        (error) => { return {data:{status:error.response.data.status}}; }
    );
} 
