/* eslint-disable */
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import cookie from "react-cookies";
import { Pagination, PaginationItem } from "reactstrap";
import { page_limit } from "../Config/config";
import { GET_PAGE, GET_PROJECTS } from "../../actions";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
  language,
  checkauthentication,
  getImage,
  LoadingSec,
  ProjectDate,
  ScrollDiv,
  IntialLoading,
  headerDetails,
  DisplayImage,
  DisplayContent,
  CreateMeta
} from "../Config/Helpers";
import Swiper from "react-id-swiper";
import ScrollAnimation from "react-animate-on-scroll";

const params = {
  effect: "fade",
  loop: true,
  noSwiping: true,
  allowTouchMove: false,
  autoHeight: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  }
};
class Index extends React.Component {
  constructor(props) {
    super(props);
    if (checkauthentication() === false) {
      const { history } = this.props;
      history.push("/login");
    }
    this.state = {
      userdetails: cookie.load("user_details"),
      lang: language(),
      page: 1,
      projectlist: "",
      pagenation: "",
      project_details: "",
      pagedetails: "",
      frmloading: true,
      pagecontentLoading: true
    };
  }

  componentDidMount() {
    let pageparams = "slug=projects-gallery";
    this.props.getPage(pageparams);

    let params = "page=" + this.state.page;
    this.props.getProjects(params);
  }
  componentDidUpdate(prevProps) {
    if (this.props.projectdata !== prevProps.projectdata) {
      /* Proejcts Response */
      this.setState({ frmloading: false });
      if (
        this.props.projectdata.status !== "" &&
        typeof this.props.projectdata.status !== "undefined" &&
        typeof this.props.projectdata.status !== undefined
      ) {
        if (this.props.projectdata.status === false) {
          const { history } = this.props;
          history.push("/logout");
        } else if (this.props.projectdata.status === "ok") {
          if (
            this.props.projectdata.total_records !== "" &&
            typeof this.props.projectdata.total_records !== "undefined" &&
            typeof this.props.projectdata.total_records !== undefined
          ) {
            this.setState(
              { total_records: this.props.projectdata.total_records },
              function() {
                this.loadPagenation();
              }
            );
          }
          this.setState(
            { project_details: this.props.projectdata.result_set },
            function() {
              this.loadProject();

              this.loadPagenation();
            }
          );
        }
      }
    }
    if (this.props.pagedata !== prevProps.pagedata) {
      /*Page Response */
      this.setState({ pagecontentLoading: false });
      if (
        this.props.pagedata.status !== "" &&
        typeof this.props.pagedata.status !== "undefined" &&
        typeof this.props.pagedata.status !== undefined
      ) {
        if (this.props.pagedata.status === false) {
          const { history } = this.props;
          history.push("/logout");
        } else if (this.props.pagedata.status === "ok") {
          this.setState({ pagedetails: this.props.pagedata.result_set });
        }
      }
    }
  }

  /* Load Project List */
  loadProject() {
    let projectlist;
    if (
      this.state.project_details !== "" &&
      typeof this.state.project_details !== "undefined"
    ) {
      projectlist = Object.entries(this.state.project_details).map(
        (item, index) => {
          let project = item[1];

          return (
            <div className="gallery_slider" key={index}>
              {project.image !== null &&
              typeof project.image !== "undefined" &&
              project.image.length > 0 ? (
                project.image.length === 1 ? (
                  this.loadGallery(project.image)
                ) : (
                  <Swiper {...params}>{this.loadGallery(project.image)}</Swiper>
                )
              ) : (
                <div className="no-project-image">
                  <img src={DisplayImage("noDetailimg")} alt="" />
                </div>
              )}
              <div className="gallery_sliderinfo">
                <div className="galinfo_left">
                  <h3>{project.project_location}</h3>
                  <p>
                    {project.project_type} {ProjectDate(project.updated_on)}
                  </p>
                </div>                 
              </div>
            </div>
          );
        }
      );
    } else {
      this.setState({ total_records: 0 }, function() {
        this.loadPagenation();
      });
      projectlist = (
        <div className="norecord_sec text-center">
          <i className="norecord_ico"></i>
          <h4>{this.state.lang.project.no_project}</h4>
        </div>
      );
    }
    this.setState({ projectlist: projectlist });
  }
  /* Load Slider Image */
  loadGallery(image) {
    if (image !== null) {
      return image.map((item, index) => {
        let projectGallery = getImage(item, "project");
        if (projectGallery !== "") {
          return (
            <div key={index}>
                <img src={getImage(item, "project")} alt="" />
            </div>
          );
        } else {
          return "";
        }
      });
    } else {
      return "";
    }
  }

  /* Load Pagenation Desaign */
  loadPagenation() {
    let total_records = this.state.total_records;
    let totalPage = total_records / page_limit;
    let pagenation = "";
    let prev = this.state.page - 1;
    let next = parseInt(this.state.page) + 1;
    if (Math.ceil(totalPage) > 1) {
      pagenation = (
        <Pagination aria-label="Page navigation example">
          {this.state.page > 1 && (
            <PaginationItem onClick={this.loadMore.bind(this, prev)}>
              <span
                className="page-link"
                aria-label={this.state.lang.common.prev}
              >
                <span aria-hidden="true">‹</span>
                <span className="sr-only">{this.state.lang.common.prev}</span>
              </span>
            </PaginationItem>
          )}
          {this.loadpageList()}
          {parseInt(this.state.page) + 1 <= Math.ceil(totalPage) && (
            <PaginationItem onClick={this.loadMore.bind(this, next)}>
              <span
                href="#"
                className="page-link"
                aria-label={this.state.lang.common.next}
              >
                <span aria-hidden="true">›</span>
                <span className="sr-only">{this.state.lang.common.next}</span>
              </span>
            </PaginationItem>
          )}
        </Pagination>
      );
    }
    this.setState({ pagenation: pagenation });
  }

  /* Pagenation Number List */
  loadpageList() {
    let currentPage = this.state.page > 1 ? parseInt(this.state.page) - 1 : 1;
    let total_records = this.state.total_records;
    let totalPage = total_records / page_limit;
    let nextloop =
      parseInt(currentPage) + 3 <= totalPage
        ? parseInt(currentPage) + 3
        : totalPage;
    if (currentPage - nextloop <= 3) {
      currentPage = nextloop - 3;
      if (currentPage <= 0) {
        currentPage = 1;
      }
    }
    let ToReturn = [];
    for (var i = Math.ceil(currentPage); i <= Math.ceil(nextloop); i++) {
      ToReturn.push(
        <PaginationItem
          key={i}
          className={this.state.page === i ? "active" : ""}
          onClick={this.loadMore.bind(this, i)}
        >
          <span className="page-link">{i}</span>
        </PaginationItem>
      );
    }
    return ToReturn;
  }
  /* Apply Pagenation */
  loadMore(page) {
    if (page !== this.state.page) {
      ScrollDiv("projects_gallery");
      this.setState({ page: page, frmloading: true }, function() {
        let params = "page=" + this.state.page;
        this.props.getProjects(params);
      });
    }
  }

  render() {
    if (
      this.state.userdetails !== "" &&
      typeof this.state.userdetails !== "undefined"
    ) {
      return (
        <div className="center">
          {this.state.pagedetails !== "" && CreateMeta(this.state.pagedetails)}
          {this.state.pagecontentLoading === true && IntialLoading()}
          {this.state.pagecontentLoading === false && (
            <div>
              {headerDetails()}

              <div className="ban_sec">
                <div className="container">
                  {this.state.pagedetails !== "" && (
                    <div className="ban_info text-center">
                      <i>
                        <img
                          src={getImage(this.state.pagedetails.image, "pages")}
                          alt=""
                        />
                      </i>
                      <ScrollAnimation
                        animateIn="fadeInDown"
                        animateOnce={true}
                        offset={0}
                        delay={200}
                      >
                        <h1>{this.state.pagedetails.title}</h1>
                      </ScrollAnimation>
                      <ScrollAnimation
                        animateIn="fadeInDown"
                        animateOnce={true}
                        offset={0}
                        delay={400}
                      >
                        {this.state.pagedetails.description !== ""
                          ? DisplayContent(this.state.pagedetails.description)
                          : ""}
                      </ScrollAnimation>
                    </div>
                  )}
                </div>
                <a
                  href="#javascript;"
                  className="scroll_down"
                  onClick={e => {
                    e.preventDefault();
                    ScrollDiv("projects_gallery");
                  }}
                >
                  <span></span>
                </a>
              </div>
            </div>
          )}
          <div id="projects_gallery">
            <div
              className={
                this.state.frmloading === true
                  ? "load_wrapper load-wrapper-mheight"
                  : ""
              }
            >
              {this.state.frmloading === true && LoadingSec()}
              {this.state.projectlist}
            </div>
            {this.state.pagenation !== "" && (
              <div className="pagination_sec">{this.state.pagenation}</div>
            )}
          </div>
        </div>
      );
    } else {
      return IntialLoading();
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPage: param => {
      dispatch({ type: GET_PAGE, param });
    },
    getProjects: param => {
      dispatch({ type: GET_PROJECTS, param });
    }
  };
};

const mapStateToProps = state => {
  return {
    pagedata: state.pagedata,
    projectdata: state.projectdata
  };
};

Index.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  })
};

Index = connect(mapStateToProps, mapDispatchToProps)(Index);

export default withRouter(Index);
