import { takeLatest, call, put } from "redux-saga/effects";
import { apiUrl } from "../components/Config/config";
import { GET_RESET_PASSWORD } from "../actions";
import Axios from "axios";

export const watchGetResetPassword = function*(Param) {
  yield takeLatest(GET_RESET_PASSWORD, workerGetResetPassword);
};

function* workerGetResetPassword(Param) {
  const result = yield call(getResetPassword, Param);
  yield put({
    type: "SET_RESET_PASSWORD",
    json: result.data || [{ error: result.message }]
  });
}

function getResetPassword(Param) {
  var qs = require("qs");
  return Axios.post(
    apiUrl + "customer/reset_password",
    qs.stringify(Param.Param)
  );
}
