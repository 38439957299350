/* eslint-disable */
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import cookie from "react-cookies";
import {
  GET_PAGE,
  GET_STATIC_BLOCK,
  GET_REFERRAL,
  GET_SETTING
} from "../../actions";
import {
  language,
  checkauthentication,
  IntialLoading,
  emailValidate,
  numariconly,
  CreateMeta,
  ScrollDiv,
  headerDetails,
  getImage,
  DisplayImage,
  DisplayContent
} from "../Config/Helpers";
import ModalPopup from "../Layout/ModalPopup";
import { Scrollbars } from "react-custom-scrollbars";
import ScrollAnimation from "react-animate-on-scroll";

class Index extends React.Component {
  constructor(props) {
    super(props);
    if (checkauthentication() === false) {
      const { history } = this.props;
      history.push("/login");
    }
    this.state = {
      userdetails: cookie.load("user_details"),
      lang: language(),
      pagecontentLoading: true,
      modalStaticBlock: false,
      name: "",
      error: "",
      success: "",
      error_name: "",
      email: "",
      error_email: "",
      contact_number: "",
      error_contact_number: "",
      creaditcoin: "",
      settingdata: ""
    };
    this.toggleStaticBlock = this.toggleStaticBlock.bind(this);
    this.togglemsg = this.togglemsg.bind(this);
  }

  componentDidMount() {
    let pageparams = "slug=refer-a-friend";
    this.props.getPage(pageparams);
    this.props.getSetting();
    let params = "slug=terms-of-use,privacy-policy";
    this.props.getStaticBlock(params);
  }
  componentDidUpdate(prevProps) {
    if (this.props.pagedata !== prevProps.pagedata) {
      /*Page Response */
      this.setState({ pagecontentLoading: false });
      if (
        this.props.pagedata.status !== "" &&
        typeof this.props.pagedata.status !== "undefined" &&
        typeof this.props.pagedata.status !== undefined
      ) {
        if (this.props.pagedata.status === false) {
          const { history } = this.props;
          history.push("/logout");
        } else if (this.props.pagedata.status === "ok") {
          this.setState({ pagedetails: this.props.pagedata.result_set });
        }
      }
    }

    if (this.props.settingdata !== prevProps.settingdata) {
      /*Page Response */
      if (
        this.props.settingdata.status !== "" &&
        typeof this.props.settingdata.status !== "undefined" &&
        typeof this.props.settingdata.status !== undefined
      ) {
        if (this.props.settingdata.status === false) {
          const { history } = this.props;
          history.push("/logout");
        } else if (this.props.settingdata.status === "ok") {
          this.setState({ settingdata: this.props.settingdata.result_set });
        }
      }
    }
    if (this.props.referraldata !== prevProps.referraldata) {
      /* Refer Response */
      if (
        this.props.referraldata.status !== "" &&
        typeof this.props.referraldata.status !== "undefined" &&
        typeof this.props.referraldata.status !== undefined
      ) {
        this.setState({ frmloading: false });
        if (this.props.referraldata.status === false) {
          const { history } = this.props;
          history.push("/logout");
        } else if (this.props.referraldata.status === "ok") {
          this.setState({
            name: "",
            email: "",
            contact_number: ""
          });
          cookie.save("user_coin", this.props.referraldata.coin);
          this.setState(
            {
              success: this.props.referraldata.message,
              creaditcoin: this.props.referraldata.credited_referral_coins
            },
            function() {
              this.togglemsg();
            }
          );
          this.setState({ error: "" });
        } else {
          this.setState({ success: "" });
          this.setState({ error: this.props.referraldata.message }, function() {
            this.togglemsg();
          });
        }
        //this.hideMessage();
      }
    }
    if (this.props.staticblockdata !== prevProps.staticblockdata) {
      if (this.props.staticblockdata.status === "ok") {
        this.setState({ staticblock: this.props.staticblockdata.result_set });
      }
    }
  }

  /* Hide Message */
  hideMessage() {
    const current = this;
    setTimeout(function() {
      current.setState({ error: "", success: "" });
    }, 4000);
  }

  /* Set State for input value */
  handleChangeTxt = event => {
    let { name, value } = event.target;
    if (name === "email") {
      if (emailValidate(value) === false && value !== "") {
        this.setState({
          ["error_" + name]: this.state.lang.common.invalid_email
        });
      } else {
        this.setState({ ["error_" + name]: "" });
      }
      this.setState({ [name]: value });
    } else {
      this.setState({ [name]: value, ["error_" + name]: "" });
    }
  };

  toggleStaticBlock() {
    this.setState(prevState => ({
      modalStaticBlock: !prevState.modalStaticBlock
    }));
  }
  togglemsg() {
    this.setState(prevState => ({
      modalmsg: !prevState.modalmsg
    }));
  }

  loadBlock(slug) {
    if (
      this.state.staticblock !== "" &&
      this.state.staticblock[slug] !== "" &&
      typeof this.state.staticblock[slug] !== "undefined"
    ) {
      this.setState({ blockDetails: this.state.staticblock[slug] }, function() {
        this.toggleStaticBlock();
      });
    }
  }
  /* Refer Friend Function */
  referFriend() {
    let error = 0;
    if (this.state.email === "") {
      this.setState({ error_email: this.state.lang.common.field_required });
      error++;
    } else {
      if (emailValidate(this.state.email) === false) {
        this.setState({
          error_email: this.state.lang.common.invalid_email
        });
      } else {
        this.setState({ error_email: "" });
      }
    }
    if (this.state.name === "") {
      this.setState({
        error_name: this.state.lang.common.field_required
      });
      error++;
    } else {
      this.setState({ error_name: "" });
    }
    if (this.state.contact_number === "") {
      this.setState({
        error_contact_number: this.state.lang.common.field_required
      });
      error++;
    } else {
      this.setState({ error_contact_number: "" });
    }

    if (error === 0) {
      this.setState({ frmloading: true });
      let postdata = {
        email: this.state.email,
        name: this.state.name,
        mobile_number: this.state.contact_number
      };
      this.props.getReferral(postdata);
    }
  }

  render() {
    if (
      this.state.userdetails !== "" &&
      typeof this.state.userdetails !== "undefined"
    ) {
      return (
        <div className="center">
          {this.state.pagecontentLoading === true && IntialLoading()}
          {this.state.pagecontentLoading === false && (
            <div>
              {this.state.pagedetails !== "" &&
                CreateMeta(this.state.pagedetails)}
              {headerDetails()}
              <div className="ban_sec">
                <div className="container">
                  <div className="ban_info text-center">
                    <i className="banrefer_ico">
                      <img
                        src={getImage(this.state.pagedetails.image, "pages")}
                        alt=""
                      />
                    </i>
                    <ScrollAnimation
                      animateIn="fadeInDown"
                      animateOnce={true}
                      offset={0}
                      delay={200}
                    >
                      <h1>{this.state.pagedetails.title}</h1>
                    </ScrollAnimation>
                    <ScrollAnimation
                      animateIn="fadeInDown"
                      animateOnce={true}
                      offset={0}
                      delay={400}
                    >
                      {this.state.pagedetails.description !== ""
                        ? DisplayContent(this.state.pagedetails.description)
                        : ""}
                      <div className="refer_earncoins">
                        <i className="ico_r">i</i>
                        {this.state.settingdata !== ""
                          ? this.state.settingdata.referral_point
                          : 0}{" "}
                        <b className="text-lowercase">i</b>-Coins
                      </div>
                    </ScrollAnimation>
                  </div>
                </div>
                <a
                  href="#javascript;"
                  className="scroll_down"
                  onClick={e => {
                    e.preventDefault();
                    ScrollDiv("refer");
                  }}
                >
                  <span></span>
                </a>
              </div>
            </div>
          )}
          <div id="refer">
            <div className="refer_sec">
              <div className="container">
                <form className="form_sec refer_form">
                  <ScrollAnimation
                    animateIn="fadeInDown"
                    animateOnce={true}
                    offset={0}
                    delay={200}
                  >
                    <p className="ldform_terms refer_helptext text-center">
                      {this.state.lang.refer.fill}{" "}
                      <b>{this.state.lang.refer.friend}</b>
                    </p>
                  </ScrollAnimation>
                  <span className="etag"></span>
                  <ScrollAnimation
                    animateIn="fadeIn"
                    animateOnce={true}
                    offset={0}
                    delay={500}
                  >
                    <div className="form-group">
                      <div className="row">
                        <div className="col-sm-4">
                          <label>{this.state.lang.sign_up.name}:</label>
                        </div>
                        <div className="col-sm-8">
                          <div className="input_field">
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              onChange={this.handleChangeTxt.bind(this)}
                              value={this.state.name}
                            />
                            <div className="input_glowanimation"></div>
                            {this.state.error_name !== "" && (
                              <label className="error">
                                {this.state.error_name}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-sm-4">
                          <label>{this.state.lang.sign_up.email}:</label>
                        </div>
                        <div className="col-sm-8">
                          <div className="input_field">
                            <input
                              type="text"
                              name="email"
                              className="form-control"
                              onChange={this.handleChangeTxt.bind(this)}
                              value={this.state.email}
                            />
                            <div className="input_glowanimation"></div>
                            {this.state.error_email !== "" && (
                              <label className="error">
                                {this.state.error_email}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>{this.state.lang.sign_up.contact_number}:</label>
                      <div className="input_field contact-field">
                        <input
                          type="number"
                          name="contact_number"
                          className="form-control"
                          onChange={this.handleChangeTxt.bind(this)}
                          value={this.state.contact_number}
                          onKeyPress={e => numariconly(e)}
                          onInput={e => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 8);
                          }}
                        />
                        <div className="input_glowanimation"></div>
                      </div>
                      {this.state.error_contact_number !== "" && (
                        <label className="error">
                          {this.state.error_contact_number}
                        </label>
                      )}
                    </div>
                  </ScrollAnimation>
                  <span className="etag"></span>
                  <ScrollAnimation
                    animateIn="fadeInDown"
                    animateOnce={true}
                    offset={0}
                    delay={200}
                  >
                    <div className="form-group text-center">
                      <p className="ldform_terms text-center">
                        {this.state.lang.login.by_proceeding}{" "}
                        <a
                          href="#terms-of-use"
                          onClick={e => {
                            e.preventDefault();
                            this.loadBlock("terms-of-use");
                          }}
                        >
                          {DisplayContent(this.state.lang.login.terms)}
                        </a>{" "}
                        &{" "}
                        <a
                          href="#terms-of-use"
                          onClick={e => {
                            e.preventDefault();
                            this.loadBlock("privacy-policy");
                          }}
                        >
                          {DisplayContent(this.state.lang.login.privacy)}
                        </a>
                      </p>

                      <button
                        className={
                          this.state.frmloading === true
                            ? "btn btn_yellow btn-block text-uppercase load_btn"
                            : "btn btn_yellow btn-block text-uppercase"
                        }
                        disabled={this.state.frmloading}
                        onClick={e => {
                          e.preventDefault();
                          this.referFriend();
                        }}
                      >
                        <span>{this.state.lang.refer.submit}</span>
                      </button>
                    </div>
                  </ScrollAnimation>
                </form>
              </div>
            </div>
          </div>
          <ModalPopup
            modal={this.state.modalStaticBlock}
            toggle={this.toggleStaticBlock}
            closebtn={true}
            innerclass="popup_terms"
          >
            <div>
              {this.state.blockDetails !== "" &&
                typeof this.state.blockDetails !== "undefined" && (
                  <div>
                    <h2 className="title1 text-center">
                      {this.state.blockDetails.title}
                    </h2>
                    <div className="smcustom_scroll">
                      <Scrollbars className="smcustom_scroll_in">
                        <div className="terms_content">
                          {DisplayContent(this.state.blockDetails.description)}
                        </div>
                      </Scrollbars>
                    </div>
                  </div>
                )}
            </div>
          </ModalPopup>

          <ModalPopup
            modal={this.state.modalmsg}
            toggle={this.togglemsg}
            closebtn={true}
            innerclass="popup_alert"
          >
            {this.state.error !== "" && (
              <div>
                <div className="popalert_top">
                  <div className="popaler_info">
                    <i>
                      <img src={DisplayImage("ico_failed")} alt="" />
                    </i>
                    <h3 className="title2 text-uppercase">
                      {this.state.lang.refer.faild}
                    </h3>
                    <p>{DisplayContent(this.state.error)}</p>
                  </div>
                </div>
                <button
                  className="btn btn_yellow btn-lg btn-block arch_btn text-uppercase"
                  onClick={this.togglemsg}
                >
                  {this.state.lang.common.close}
                </button>
              </div>
            )}
            {this.state.success !== "" && (
              <div>
                <div className="popalert_top">
                  <div className="popaler_info">
                    <i>
                      <img src={DisplayImage("ico_tick")} alt="" />
                    </i>
                    <h3 className="title2 text-uppercase">
                      {this.state.lang.refer.success}
                    </h3>
                    <p>{DisplayContent(this.state.success)}</p>
                  </div>
                </div>
                <button
                  className="btn btn_yellow btn-lg btn-block arch_btn text-uppercase"
                  onClick={this.togglemsg}
                >
                  <i className="ico_r ico_rgrey text-lowercase"></i>
                  {this.state.creaditcoin}
                  <span className="text-lowercase"> i</span>-Coins
                </button>
              </div>
            )}
          </ModalPopup>
        </div>
      );
    } else {
      return IntialLoading();
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPage: param => {
      dispatch({ type: GET_PAGE, param });
    },
    getSetting: param => {
      dispatch({ type: GET_SETTING, param });
    },

    getStaticBlock: Param => {
      dispatch({ type: GET_STATIC_BLOCK, Param });
    },
    getReferral: Param => {
      dispatch({ type: GET_REFERRAL, Param });
    }
  };
};

const mapStateToProps = state => {
  return {
    pagedata: state.pagedata,
    staticblockdata: state.staticblockdata,
    referraldata: state.referraldata,
    settingdata: state.settingdata
  };
};

Index.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  })
};

Index = connect(mapStateToProps, mapDispatchToProps)(Index);

export default withRouter(Index);
