import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./components/Home/Index";
import Login from "./components/Login/Index";
import Activate from "./components/Login/Activate";
import Logout from "./components/Login/Logout";
import Signup from "./components/Signup/Index";
import Myaccount from "./components/Myaccount/Index";
import Deals from "./components/Deals/Index";
import Merchantdeals from "./components/Deals/Merchantdeals";
import Detail from "./components/Deals/Detail";
import Download from "./components/Deals/Download";
import Style from "./components/Style";
import ModalExample from "./components/Layout/Model";
import Merchants from "./components/Merchants/Index";
import Projects from "./components/Projects/Index";
import Referfriend from "./components/Referfriend/Index";
import Bestshowroom from "./components/Bestshowroom/Index";
import Announcement from "./components/Announcement/Index";
import Forgotpassword from "./components/Forgotpassword/Index";
import Resetpassword from "./components/Resetpassword/Index";

//import Page404 from './Page404';
const NoMatch = ({ location }) => (
  <div className="center">
    <div className="pgnot_found">
      <div className="pgnot_found_inner">
        <h1 className="title1">
          4<span>0</span>4
        </h1>
        <p>The page you requested</p>
        <a href="/" className="btn btn_yellow btn-sm btn_minwid" title="Home">
          Home
        </a>
      </div>
    </div>
  </div>
);

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/style" component={Style} />
          <Route path="/login" component={Login} />
          <Route path="/activate/:customerID" component={Activate} />
          <Route path="/my-account" component={Myaccount} />
          <Route path="/forgot-password" component={Forgotpassword} />
          <Route path="/reset-password/:forgotKey" component={Resetpassword} />
          <Route path="/logout" component={Logout} />
          <Route path="/sign-up" component={Signup} />
          <Route path="/hot-deals/:merchantID" component={Deals} />
          <Route path="/hot-deals/" component={Deals} />
          <Route path="/merchant-deals/:merchantID" component={Merchantdeals} />
          <Route path="/download-deals/" component={Download} />
          <Route path="/deal-detail/:dealID" component={Detail} />
          <Route path="/deal-detail" component={Detail} />
          <Route path="/model" component={ModalExample} />
          <Route path="/merchants" component={Merchants} />
          <Route path="/projects-gallery" component={Projects} />
          <Route path="/refer-friend" component={Referfriend} />
          <Route path="/best-showroom" component={Bestshowroom} />
          <Route path="/announcement" component={Announcement} />
          <Route component={NoMatch} />
        </Switch>
      </Router>
    );
  }
}
export default App;
