/* eslint-disable */
import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { GET_ACTIVATE_ACCOUNT, GET_GENERATE_PASSWORD } from "../../actions";
import {
  language,
  site_title,
  checkauthentication,
  poweredByFootre,
  ScrollTop,
  DisplayImage,
  DisplayContent
} from "../Config/Helpers";
import ModalPopup from "../Layout/ModalPopup";
import { Helmet } from "react-helmet";

class Activate extends React.Component {
  constructor(props) {
    super(props);
    if (checkauthentication() === true) {
      const { history } = this.props;
      history.push("/");
    }
    if (
      this.props.match.params.customerID === "" ||
      typeof this.props.match.params.customerID === "undefined"
    ) {
      const { history } = this.props;
      history.push("/login");
    }

    this.state = {
      language: language(),
      frmloading: false,
      modalactivemsg: false,
      modalmsg: false,
      password: "",
      error_password: "",
      cpassword: "",
      error_cpassword: "",
      logindata: "",
      customerID: this.props.match.params.customerID,
      error: "",
      success: "",
      aerror: "",
      asuccess: ""
    };
    this.togglemsg = this.togglemsg.bind(this);
    this.toggleactivemsg = this.toggleactivemsg.bind(this);
  }
  componentDidMount() {
    ScrollTop();
    let postdata = {
      customer_id: this.state.customerID
    };
    this.props.getActivateAccount(postdata);
  }

  componentDidUpdate(prevProps) {
    if (this.props.activatedata !== prevProps.activatedata) {
      if (
        this.props.activatedata.status !== "" &&
        typeof this.props.activatedata.status !== "undefined"
      ) {
        if (this.props.activatedata.status === "ok") {
          this.setState(
            {
              aerror: "",
              asuccess: this.props.activatedata.message
            },
            function() {
              this.toggleactivemsg();
            }
          );
        } else {
          this.setState(
            {
              aerror: this.props.activatedata.message,
              asuccess: ""
            },
            function() {
              this.toggleactivemsg();
            }
          );
        }
      }
    }
    if (this.props.generatepwddata !== prevProps.generatepwddata) {
      /* Reset Password Response */
      this.setState({ frmloading: false });
      if (
        this.props.generatepwddata.status !== "" &&
        typeof this.props.generatepwddata.status !== "undefined"
      ) {
        if (this.props.generatepwddata.status === "ok") {
          this.setState(
            {
              error: "",
              success: this.props.generatepwddata.message,
              password: "",
              cpassword: ""
            },
            function() {
              this.togglemsg();
            }
          );
        } else {
          this.setState(
            {
              error: this.props.generatepwddata.message,
              success: ""
            },
            function() {
              this.togglemsg();
            }
          );
        }
      }
    }
  }

  togglemsg() {
    this.setState(prevState => ({
      modalmsg: !prevState.modalmsg
    }));
  }

  toggleactivemsg() {
    this.setState(prevState => ({
      modalactivemsg: !prevState.modalactivemsg
    }));
  }

  /* Set State for input value */
  handleChangeTxt = event => {
    let { name, value } = event.target;
    this.setState({ [name]: value, ["error_" + name]: "" });
  };

  /* GEnerate Password Function */
  generatepwd() {
    let error = 0;
    if (this.state.password === "") {
      this.setState({
        error_password: this.state.language.common.field_required
      });
      error++;
    } else {
      if (this.state.password.length < 6) {
        this.setState({
          error_password: this.state.language.common.passwordmin
        });
        error++;
      } else {
        this.setState({ error_password: "" });
      }
    }

    if (this.state.cpassword === "") {
      this.setState({
        error_cpassword: this.state.language.common.field_required
      });
      error++;
    } else {
      if (this.state.password !== this.state.cpassword) {
        this.setState({
          error_cpassword: this.state.language.resetpwd.no_match_pwd
        });
        error++;
      } else {
        this.setState({ error_cpassword: "" });
      }
    }

    if (error === 0) {
      this.setState({ frmloading: true });
      let postdata = {
        password: this.state.password,
        cpassword: this.state.cpassword,
        customer_id: this.state.customerID
      };
      this.props.getGeneratePassword(postdata);
    }
  }

  render() {
    return (
      <div className="center">
        <Helmet>
          <title>{site_title(this.state.language.activate.title)}</title>
          <meta name="keywords" content={this.state.language.activate.title} />
          <meta
            name="description"
            content={this.state.language.activate.title}
          />
        </Helmet>
        <div className="land_wrapper">
          <div className="land_content forget_content">
            <div className="container">
              <div className="lading_logo">
                <img
                  src={DisplayImage("landingLogo")}
                  alt={this.state.language.common.title}
                />
              </div>
              <div className="landing_form forget_form">
                <h1 className="title1 text-uppercase">
                  {this.state.language.activate.title}
                </h1>
                <form className="form_sec">
                  <div className="form-group">
                    <div className="input_field">
                      <input
                        type="password"
                        name="password"
                        placeholder={this.state.language.login.password}
                        className={
                          this.state.error_password !== ""
                            ? "form-control error"
                            : "form-control"
                        }
                        onChange={this.handleChangeTxt.bind(this)}
                        value={this.state.password}
                      />
                      <div className="input_glowanimation"></div>
                      {this.state.error_password !== "" && (
                        <label className="error">
                          {this.state.error_password}
                        </label>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input_field">
                      <input
                        type="password"
                        name="cpassword"
                        placeholder={this.state.language.login.cpassword}
                        className={
                          this.state.error_cpassword !== ""
                            ? "form-control error"
                            : "form-control"
                        }
                        onChange={this.handleChangeTxt.bind(this)}
                        value={this.state.cpassword}
                      />
                      <div className="input_glowanimation"></div>
                      {this.state.error_cpassword !== "" && (
                        <label className="error">
                          {this.state.error_cpassword}
                        </label>
                      )}
                    </div>
                  </div>
                  <div className="form-group text-center">
                    <button
                      className={
                        this.state.frmloading === true
                          ? "btn btn_yellow text-uppercase btn-block text-uppercase load_btn"
                          : "btn btn_yellow text-uppercase btn-block text-uppercase"
                      }
                      disabled={this.state.frmloading}
                      onClick={e => {
                        e.preventDefault();
                        this.generatepwd();
                      }}
                    >
                      <span>{this.state.language.activate.generate}</span>
                    </button>
                  </div>
                </form>
              </div>
              <div className="landing_botsec">
                <div className="landing_sublinks">
                  <Link
                    to={"/login"}
                    title={this.state.language.login.login}
                    data-text={this.state.language.login.login}
                  >
                    <span>{this.state.language.login.login}</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {poweredByFootre()}
        </div>
        <ModalPopup
          modal={this.state.modalmsg}
          toggle={this.togglemsg}
          closebtn={true}
          innerclass="popup_alert"
        >
          {this.state.error !== "" && (
            <div>
              <div className="popalert_top">
                <div className="popaler_info">
                  <i>
                    <img src={DisplayImage("ico_failed")} alt="" />
                  </i>
                  <h3 className="title2 text-uppercase">Password Failed</h3>
                  <p>{DisplayContent(this.state.error)}</p>
                </div>
              </div>
              <button
                className="btn btn_yellow btn-lg btn-block arch_btn text-uppercase"
                onClick={this.togglemsg}
              >
                {this.state.language.common.close}
              </button>
            </div>
          )}
          {this.state.success !== "" && (
            <div>
              <div className="popalert_top">
                <div className="popaler_info">
                  <i>
                    <img src={DisplayImage("ico_tick")} alt="" />
                  </i>
                  <h3 className="title2 text-uppercase">Password Success</h3>
                  <p>{DisplayContent(this.state.success)}</p>
                </div>
              </div>
              <Link to={"/login"}>
                <button
                  className="btn btn_yellow btn-lg btn-block arch_btn text-uppercase"
                  onClick={this.togglemsg}
                >
                  {this.state.language.common.close}
                </button>
              </Link>
            </div>
          )}
        </ModalPopup>

        <ModalPopup
          modal={this.state.modalactivemsg}
          toggle={this.toggleactivemsg}
          closebtn={true}
          innerclass="popup_alert"
        >
          {this.state.aerror !== "" && (
            <div>
              <div className="popalert_top">
                <div className="popaler_info">
                  <i>
                    <img src={DisplayImage("ico_failed")} alt="" />
                  </i>
                  <h3 className="title2 text-uppercase">Activation Failed</h3>
                  <p>{DisplayContent(this.state.aerror)}</p>
                </div>
              </div>
              <Link to={"/login"}>
                <button
                  className="btn btn_yellow btn-lg btn-block arch_btn text-uppercase"
                >
                  {this.state.language.common.close}
                </button>
              </Link>
            </div>
          )}
          {this.state.asuccess !== "" && (
            <div>
              <div className="popalert_top">
                <div className="popaler_info">
                  <i>
                    <img src={DisplayImage("ico_tick")} alt="" />
                  </i>
                  <h3 className="title2 text-uppercase">Activation Success</h3>
                  <p>{DisplayContent(this.state.asuccess)}</p>
                </div>
              </div>
              <button
                className="btn btn_yellow btn-lg btn-block arch_btn text-uppercase"
                onClick={this.toggleactivemsg}
              >
                {this.state.language.common.close}
              </button>
            </div>
          )}
        </ModalPopup>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getActivateAccount: Param => {
      dispatch({ type: GET_ACTIVATE_ACCOUNT, Param: Param });
    },
    getGeneratePassword: Param => {
      dispatch({ type: GET_GENERATE_PASSWORD, Param: Param });
    }
  };
};

const mapStateToProps = state => {
  return {
    activatedata: state.activatedata,
    generatepwddata: state.generatepwddata
  };
};

Activate.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  })
};

Activate = connect(mapStateToProps, mapDispatchToProps)(Activate);

export default withRouter(Activate);
