/* eslint-disable */
import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { GET_LOGIN, GET_STATIC_BLOCK, GET_FBLOGIN } from "../../actions";
import {
  language,
  site_title,
  checkauthentication,
  emailValidate,
  poweredByFootre,
  ScrollTop,
  DisplayImage,
  DisplayContent
} from "../Config/Helpers";
import { siteUrl, siteUrlMyaccount} from "../Config/config";
import cookie from "react-cookies";
import { Helmet } from "react-helmet";
import FacebookLogin from "react-facebook-login";
import ModalPopup from "../Layout/ModalPopup";
import { Scrollbars } from "react-custom-scrollbars";
import ScrollAnimation from "react-animate-on-scroll";



class Index extends React.Component {
  constructor(props) {
    super(props);
    if (checkauthentication() === true) {
      const { history } = this.props;
      history.push("/");
    }
    this.state = {
      lang: language(),
      frmloading: false,
      email:
        localStorage.email !== "" &&
        typeof localStorage.email !== undefined &&
        typeof localStorage.email !== "undefined"
          ? localStorage.email
          : "",
      error_email: "",
      password:
        localStorage.password !== "" &&
        typeof localStorage.password !== undefined &&
        typeof localStorage.password !== "undefined"
          ? localStorage.password
          : "",
      error_password: "",
      logindata: "",
      error: "",
      remember_me:
        localStorage.email !== "" &&
        typeof localStorage.email !== undefined &&
        typeof localStorage.email !== "undefined"
          ? true
          : false,
      blockDetails: "",
      staticblock: "",
      modalStaticBlock: false
    };
    this.toggleStaticBlock = this.toggleStaticBlock.bind(this);
    this.togglemsg = this.togglemsg.bind(this);
  }

  componentDidMount() {
    ScrollTop();
    let params = "slug=terms-of-use,privacy-policy";
    this.props.getStaticBlock(params);
  }

  componentDidUpdate(prevProps) {
    if (this.props.logindata !== prevProps.logindata) {
      /* Login Response */
      if (
        this.props.logindata.status !== "" &&
        typeof this.props.logindata.status !== "undefined"
      ) {
        this.setState({ frmloading: false });
        if (this.props.logindata.status === "ok") {
          if (this.state.remember_me === true) {
            localStorage.email = this.state.email;
            localStorage.password = this.state.password;
          } else {
            localStorage.email = "";
            localStorage.password = "";
          }

          this.setState({ error: "" });
          cookie.save("user_details", this.props.logindata.result_set);
          cookie.save("user_coin", this.props.logindata.result_set.coin);
          const { history } = this.props;
          //history.push("/");
         window.location.href = siteUrl;
        } else {
          this.setState({ error: this.props.logindata.message }, function() {
            this.togglemsg();
          });
        }
      }
      //this.hideMessage();
    }
    if (this.props.fblogindata !== prevProps.fblogindata) {
      /* FB Login Response */
      if (
        this.props.fblogindata.status !== "" &&
        typeof this.props.fblogindata.status !== "undefined"
      ) {
        this.setState({ frmloading: false });
        if (this.props.fblogindata.status === "ok") {
          this.setState({ error: "" });
          cookie.save("user_details", this.props.fblogindata.result_set);
          cookie.save("user_coin", this.props.fblogindata.result_set.coin);
          //const { history } = this.props;
          let checkuser = this.props.fblogindata.result_set.new_user;
          if(checkuser==="yes"){
            window.location.href =  siteUrlMyaccount
          }else{
            window.location.href = siteUrl;
          }
            
        } else {
          this.setState({ error: this.props.fblogindata.message }, function() {
            this.togglemsg();
          });
        }
      }
      //this.hideMessage();
    }
    if (this.props.staticblockdata !== prevProps.staticblockdata) {
      if (this.props.staticblockdata.status === "ok") {
        this.setState({ staticblock: this.props.staticblockdata.result_set });
      }
    }
  }
  /* Hide Message */
  hideMessage() {
    const current = this;
    setTimeout(function() {
      current.setState({ error: "" });
    }, 4000);
  }

  /* Set State for input value */
  handleChangeTxt = event => {
    let { name, value } = event.target;
    if (name === "email") {
      if (emailValidate(value) === false && value !== "") {
        this.setState({
          ["error_" + name]: this.state.lang.common.invalid_email
        });
      } else {
        this.setState({ ["error_" + name]: "" });
      }
      this.setState({ [name]: value });
    } else {
      this.setState({ [name]: value, ["error_" + name]: "" });
    }
  };
  handleChange = event => {
    this.setState({
      remember_me: this.refs.remember_me.checked
    });
  };

  toggleStaticBlock() {
    this.setState(prevState => ({
      modalStaticBlock: !prevState.modalStaticBlock
    }));
  }
  togglemsg() {
    this.setState(prevState => ({
      modalmsg: !prevState.modalmsg
    }));
  }

  /* Login Function */
  login() {
    let error = 0;
    if (this.state.email === "") {
      this.setState({ error_email: this.state.lang.common.field_required });
      error++;
    } else {
      if (emailValidate(this.state.email) === false) {
        this.setState({
          error_email: this.state.lang.common.invalid_email
        });
      } else {
        this.setState({ error_email: "" });
      }
    }
    if (this.state.password === "") {
      this.setState({
        error_password: this.state.lang.common.field_required
      });
      error++;
    } else {
      this.setState({ error_password: "" });
    }

    if (error === 0) {
      this.setState({ frmloading: true });
      let postdata = {
        email: this.state.email,
        password: this.state.password
      };
      this.props.getLogin(postdata);
    }
  }
  responseFacebook = response => {
    if (response.id !== "" && typeof response.id !== "undefined") {
      this.setState({ error: "" });
      let postdata = {
        fb_id: response.id,
        name: response.name,
        email: response.email,
        photo: response.picture !== "" ? response.picture.data.url : ""
      };
      this.props.getFBLogin(postdata);
    } else {
      this.setState({ error: this.state.lang.login.fb_error });
    }
  };
  loadBlock(slug) {
    if (
      this.state.staticblock !== "" &&
      this.state.staticblock[slug] !== "" &&
      typeof this.state.staticblock[slug] !== "undefined"
    ) {
      this.setState({ blockDetails: this.state.staticblock[slug] }, function() {
        this.toggleStaticBlock();
      });
    }
  }

  render() {
    return (
      <div className="center">
        <Helmet>
          <title>{site_title(this.state.lang.login.title)}</title>
          <meta name="keywords" content={this.state.lang.login.title} />
          <meta name="description" content={this.state.lang.login.title} />
        </Helmet>
        <div className="land_wrapper">
          <div className="land_content">
            <div className="container">
              <div className="lading_logo">
                <ScrollAnimation
                  animateIn="fadeIn"
                  animateOnce={true}
                  offset={0}
                  delay={100}
                >
                  <img
                    src={DisplayImage("landingLogo")}
                    alt={this.state.lang.common.title}
                  />
                </ScrollAnimation>
              </div>
              <div className="landing_form">
                <ScrollAnimation
                  animateIn="fadeIn"
                  animateOnce={true}
                  offset={0}
                  delay={150}
                >
                  <h1 className="title1">{this.state.lang.login.title}</h1>
                </ScrollAnimation>

                <form className="form_sec">
                  <ScrollAnimation
                    animateIn="fadeInDown"
                    animateOnce={true}
                    offset={0}
                    delay={200}
                  >
                    <div className="form-group">
                      <div className="input_field">
                        <input
                          type="text"
                          name="email"
                          placeholder={this.state.lang.sign_up.emailid}
                          className={
                            this.state.error_email !== ""
                              ? "form-control error"
                              : "form-control"
                          }
                          onChange={this.handleChangeTxt.bind(this)}
                          value={this.state.email}
                        />
                        <div className="input_glowanimation"></div>
                        {this.state.error_email !== "" && (
                          <label className="error">
                            {this.state.error_email}
                          </label>
                        )}
                      </div>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="fadeInDown"
                    animateOnce={true}
                    offset={0}
                    delay={220}
                  >
                    <div className="form-group">
                      <div className="input_field">
                        <input
                          type="password"
                          name="password"
                          placeholder={this.state.lang.login.password}
                          className={
                            this.state.error_password !== ""
                              ? "form-control error"
                              : "form-control"
                          }
                          onChange={this.handleChangeTxt.bind(this)}
                          value={this.state.password}
                        />
                        <div className="input_glowanimation"></div>
                        {this.state.error_password !== "" && (
                          <label className="error">
                            {this.state.error_password}
                          </label>
                        )}
                      </div>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="fadeInDown"
                    animateOnce={true}
                    offset={0}
                    delay={240}
                  >
                    <div className="form-group text-center">
                      <button
                        className={
                          this.state.frmloading === true
                            ? "btn btn_yellow text-uppercase btn-block text-uppercase load_btn"
                            : "btn btn_yellow text-uppercase btn-block text-uppercase"
                        }
                        disabled={this.state.frmloading}
                        onClick={e => {
                          e.preventDefault();
                          this.login();
                        }}
                      >
                        <span>{this.state.lang.login.sign_in}</span>
                      </button>
                    </div>
                  </ScrollAnimation>

                  <ScrollAnimation
                    animateIn="fadeInDown"
                    animateOnce={true}
                    offset={0}
                    delay={260}
                  >
                    <div className="form-group lading_remember text-center">
                      <div className="custom_checkbox">
                        <input
                          type="checkbox"
                          ref="remember_me"
                          defaultChecked={this.state.remember_me}
                          onChange={this.handleChange}
                        />{" "}
                        <span>{this.state.lang.login.remember_me}</span>
                      </div>
                    </div>
                  </ScrollAnimation>
                </form>
              </div>
              <div className="landing_botsec">
                <ScrollAnimation
                  animateIn="fadeIn"
                  animateOnce={true}
                  offset={0}
                  delay={300}
                >
                  <div className="ldform_divider text-center">
                    {this.state.lang.common.or}
                  </div>
                  {
                    <FacebookLogin
                      appId="144568919875473"
                      autoLoad={false}
                      fields="name,email,picture"
                      callback={this.responseFacebook}
                      cssClass="btn btn-lg fb_btn btn-block"
                      icon="fa fa-facebook-official"
                    />
                  }
                  <p className="ldform_terms text-center">
                    {this.state.lang.login.by_proceeding}{" "}
                    <a
                      href="#terms-of-use"
                      onClick={e => {
                        e.preventDefault();
                        this.loadBlock("terms-of-use");
                      }}
                    >
                      {DisplayContent(this.state.lang.login.terms)}
                    </a>{" "}
                    &{" "}
                    <a
                      href="#terms-of-use"
                      onClick={e => {
                        e.preventDefault();
                        this.loadBlock("privacy-policy");
                      }}
                    >
                      {DisplayContent(this.state.lang.login.privacy)}
                    </a>
                  </p>

                  <div className="landing_sublinks">
                    <Link
                      to={"/forgot-password"}
                      title={this.state.lang.forgot.title}
                      data-text={this.state.lang.forgot.title}
                    >
                      <span>{this.state.lang.forgot.title}</span>
                    </Link>
                    <Link
                      to={"sign-up"}
                      title={this.state.lang.register.title}
                      data-text={this.state.lang.register.title}
                    >
                      <span>{this.state.lang.register.title}</span>
                    </Link>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
          {poweredByFootre()}
        </div>
        <ModalPopup
          modal={this.state.modalStaticBlock}
          toggle={this.toggleStaticBlock}
          closebtn={true}
          innerclass="popup_terms"
        >
          <div>
            {this.state.blockDetails !== "" &&
              typeof this.state.blockDetails !== "undefined" && (
                <div>
                  <h2 className="title1 text-center">
                    {this.state.blockDetails.title}
                  </h2>
                  <div className="smcustom_scroll">
                    <Scrollbars className="smcustom_scroll_in">
                      <div className="terms_content">
                        {DisplayContent(this.state.blockDetails.description)}
                      </div>
                    </Scrollbars>
                  </div>
                </div>
              )}
          </div>
        </ModalPopup>
        <ModalPopup
          modal={this.state.modalmsg}
          toggle={this.togglemsg}
          closebtn={true}
          innerclass="popup_alert"
        >
          {this.state.error !== "" && (
            <div>
              <div className="popalert_top">
                <div className="popaler_info">
                  <i>
                    <img src={DisplayImage("ico_failed")} alt="" />
                  </i>
                  <h3 className="title2">
                    {this.state.lang.login.login_faild}
                  </h3>
                  <p>{DisplayContent(this.state.error)}</p>
                </div>
              </div>
              <button
                className="btn btn_yellow btn-lg btn-block arch_btn text-uppercase"
                onClick={this.togglemsg}
              >
                {this.state.lang.common.close}
              </button>
            </div>
          )}
        </ModalPopup>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getLogin: Param => {
      dispatch({ type: GET_LOGIN, Param: Param });
    },
    getFBLogin: Param => {
      dispatch({ type: GET_FBLOGIN, Param: Param });
    },
    getStaticBlock: Param => {
      dispatch({ type: GET_STATIC_BLOCK, Param });
    }
  };
};

const mapStateToProps = state => {
  return {
    logindata: state.logindata,
    fblogindata: state.fblogindata,
    staticblockdata: state.staticblockdata
  };
};

Index.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  })
};

Index = connect(mapStateToProps, mapDispatchToProps)(Index);

export default withRouter(Index);
