/* eslint-disable */
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import cookie from "react-cookies";
import { GET_PAGE, GET_BESTSHOWROOM, GET_PROJECTS, GET_SHOWROOM} from "../../actions";
import {
  language,
  checkauthentication,
  IntialLoading,
  site_title,
  headerDetails,
  ScrollDiv,
  DisplayContent,
  DisplayImage,
  getImage
} from "../Config/Helpers";
import ModalPopup from "../Layout/ModalPopup";
import { Helmet } from "react-helmet";
import Select from "react-select";
import ScrollAnimation from "react-animate-on-scroll";

class Index extends React.Component {
  constructor(props) {
    super(props);
    if (checkauthentication() === false) {
      const { history } = this.props;
      history.push("/login");
    }
    this.state = {
      userdetails: cookie.load("user_details"),
      lang: language(),
      pagecontentLoading: true,
      selectedproject: null,
      selectedchapter: null,
      selectedrank: null,
      frmloading: false,
      error_rank: "",
      error_chapter: "",
      error_project: "",
      error: "",
      success: "",
      pagedetails: "",
      chapter: [
        { value: "1", label: "01" },
        { value: "2", label: "02" },
        { value: "3", label: "03" },
        { value: "4", label: "04" },
        { value: "5", label: "05" },
        { value: "6", label: "06" },
        { value: "7", label: "07" },
        { value: "8", label: "08" },
        { value: "9", label: "09" },
        { value: "10", label: "10" }
      ],
      project_details: "",
      projectList: "",
      rank: [
        { value: "1", label: "Excellent" },
        { value: "2", label: "Good" },
        { value: "3", label: "Average" },
        { value: "4", label: "Satisfactory" },
        { value: "5", label: "Needs Improvement" }
      ],
      creaditcoin: "",
      showroomlist: "", 
    };
    this.togglemsg = this.togglemsg.bind(this);
  }

  componentDidMount() {
    let pageparams = "slug=best-showroom";
    this.props.getPage(pageparams);
    let pageparam = "list=1";
    this.props.getProjects(pageparam);
    this.props.getShowroom();
  }
  componentDidUpdate(prevProps) {
    if (this.props.pagedata !== prevProps.pagedata) {
      /*Page Response */
      this.setState({ pagecontentLoading: false });
      if (
        this.props.pagedata.status !== "" &&
        typeof this.props.pagedata.status !== "undefined" &&
        typeof this.props.pagedata.status !== undefined
      ) {
        if (this.props.pagedata.status === false) {
          const { history } = this.props;
          history.push("/logout");
        } else if (this.props.pagedata.status === "ok") {
          this.setState({ pagedetails: this.props.pagedata.result_set });
        }
      }
    }
    if (this.props.projectdata !== prevProps.projectdata) {
      /* Proejcts Response */
      if (
        this.props.projectdata.status !== "" &&
        typeof this.props.projectdata.status !== "undefined" &&
        typeof this.props.projectdata.status !== undefined
      ) {
        if (this.props.projectdata.status === false) {
          const { history } = this.props;
          history.push("/logout");
        } else if (this.props.projectdata.status === "ok") {
          this.setState(
            { project_details: this.props.projectdata.result_set },
            function() {
              this.loadProject();
            }
          );
        }
      }
    }
   

     if(this.props.showroom != prevProps.showroom){
     if ( this.props.showroom!== "" &&   typeof this.props.showroom!== "undefined" && typeof this.props.showroomlist !== undefined) {
     	//console.log("this.props.showroom", this.props.showroom);
		 	this.setState({
		 		showroomlist:this.props.showroom
		 	})
    	}
    }

    if (this.props.bestshowroomdata !== prevProps.bestshowroomdata) {
      /* Create Best Showroom Response */
      if (
        this.props.bestshowroomdata.status !== "" &&
        typeof this.props.bestshowroomdata.status !== "undefined" &&
        typeof this.props.bestshowroomdata.status !== undefined
      ) {
        this.setState({ frmloading: false });
        if (this.props.bestshowroomdata.status === "ok") {
          cookie.save("user_coin", this.props.bestshowroomdata.coin);
          this.setState(
            {
              success: this.props.bestshowroomdata.message,
              selectedchapter: null,
              selectedproject: null,
              selectedrank: null,
              creaditcoin: this.props.bestshowroomdata.credited_showroom_coins
            },
            function() {
              this.togglemsg();
            }
          );
          this.setState({ error: "" });
        } else {
          this.setState({ success: "" });
          this.setState(
            { error: this.props.bestshowroomdata.message },
            function() {
              this.togglemsg();
            }
          );
        }
      }
    }
  }

  togglemsg() {
    this.setState(prevState => ({
      modalmsg: !prevState.modalmsg
    }));
  }
  loadProject() {
    let projectList = [];
    Object.entries(this.state.project_details).map(item => {
      let project = item[1];
      projectList.push({
        label: project.project_location,
        value: project.project_id
      });
    });
    this.setState({ projectList: projectList });
  }

  handleChangeChapter = selectedchapter => {
    this.setState({ selectedchapter });
  };
  handleChangeProject = selectedproject => {
    this.setState({ selectedproject });
  };
  handleChangeRank = selectedrank => {
    this.setState({ selectedrank });
  };

  createBestShowroom() {
    let error = 0;
    if (this.state.selectedproject === null) {
      this.setState({ error_project: this.state.lang.common.field_required });
      error++;
    } else {
      this.setState({ error_project: "" });
    }
    if (this.state.selectedchapter === null) {
      this.setState({ error_chapter: this.state.lang.common.field_required });
      error++;
    } else {
      this.setState({ error_chapter: "" });
    }
    if (this.state.selectedrank === null) {
      this.setState({
        error_rank: this.state.lang.common.field_required
      });
      error++;
    } else {
      this.setState({ error_rank: "" });
    }
    if (error === 0) {
      this.setState({ frmloading: true });
      let data = new FormData();
      data.append("visited_id", this.state.selectedchapter.value);
      data.append("project_id", this.state.selectedproject.value);
      data.append("rank", this.state.selectedrank.value);
      this.props.getBestshowroom(data);
    }
  }

  render() {
    if (
      this.state.userdetails !== "" &&
      typeof this.state.userdetails !== "undefined"
    ) {
      const { selectedchapter, selectedrank, selectedproject } = this.state;
      return (
        <div className="center">
          {this.state.pagecontentLoading === true && IntialLoading()}
          {this.state.pagecontentLoading === false && (
            <div>
              {this.state.pagedetails !== "" && (
                <Helmet>
                  <title>{site_title(this.state.pagedetails.title)}</title>
                  <meta
                    name="keywords"
                    content={this.state.pagedetails.meta_keyword}
                  />
                  <meta
                    name="description"
                    content={this.state.pagedetails.meta_description}
                  />
                </Helmet>
              )}
              {headerDetails()}
              <div className="ban_sec ban_bestshowroom">
                <div className="container">
                  <div className="ban_info text-center">
                    <i className="banshowroom_ico">
                      <img
                        src={getImage(this.state.pagedetails.image, "pages")}
                        alt={this.state.pagedetails.title}
                      />
                    </i>
                    <ScrollAnimation
                      animateIn="fadeInDown"
                      animateOnce={true}
                      offset={0}
                      delay={200}
                    >
                      <h1>
                        <span>{this.state.pagedetails.title}</span>
                      </h1>
                      {this.state.pagedetails.description !== ""
                        ? DisplayContent(this.state.pagedetails.description)
                        : ""}
                    </ScrollAnimation>
                  </div>
                </div>
                <a
                  href="#javascript;"
                  className="scroll_down"
                  onClick={e => {
                    e.preventDefault();
                    ScrollDiv("showroom");
                  }}
                >
                  <span></span>
                </a>
              </div>
              <div id="showroom">
                <div className="bestshow_roomsec">
                  <div className="container">
                    <form className="form_sec faqlist">
                      <ScrollAnimation
                        animateIn="fadeIn"
                        animateOnce={true}
                        offset={0}
                        delay={400}
                      >
                        <div className="form-group">
                          <label>
                            {DisplayContent(
                              this.state.lang.best_showroom.chapter
                            )}
                          </label>
                          <div className="input_field">
                            <div className="custom_select visited_select">
                              <Select
                                placeholder={this.state.lang.common.select}
                                name="chapter"
                                value={selectedchapter}
                                onChange={this.handleChangeChapter}
                                options={this.state.chapter}
                              />
                              {this.state.error_chapter !== "" && (
                                <label className="error">
                                  {this.state.error_chapter}
                                </label>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label>
                            {DisplayContent(
                              this.state.lang.best_showroom.project
                            )}
                          </label>
                          <div className="input_field">
                            <div className="custom_select">
                              <Select
                                placeholder={
                                  this.state.lang.best_showroom.select_project
                                }
                                value={selectedproject}
                                onChange={this.handleChangeProject}
                                options={this.state.showroomlist}
                              />
                              {this.state.error_project !== "" && (
                                <label className="error">
                                  {this.state.error_project}
                                </label>
                              )}
                            </div>
                          </div>
                        </div>


                        <div className="form-group">
                          <label>
                            {DisplayContent(this.state.lang.best_showroom.rank)}
                          </label>
                          <div className="input_field">
                            <div className="custom_select">
                              <Select
                                placeholder={
                                  this.state.lang.best_showroom.select_rank
                                }
                                value={selectedrank}
                                onChange={this.handleChangeRank}
                                options={this.state.rank}
                              />
                              {this.state.error_rank !== "" && (
                                <label className="error">
                                  {this.state.error_rank}
                                </label>
                              )}
                            </div>
                          </div>
                        </div>
                      </ScrollAnimation>
                      <span className="etag"></span>
                      <ScrollAnimation
                        animateIn="fadeInDown"
                        animateOnce={true}
                        offset={0}
                        delay={200}
                      >
                        <div className="form-group faqbtn_row">
                          <button
                            className={
                              this.state.frmloading === true
                                ? "btn btn_yellow btn-block text-uppercase load_btn"
                                : "btn btn_yellow btn-block text-uppercase"
                            }
                            disabled={this.state.frmloading}
                            onClick={e => {
                              e.preventDefault();
                              this.createBestShowroom();
                            }}
                          >
                            <span>{this.state.lang.common.submit}</span>
                          </button>
                        </div>
                      </ScrollAnimation>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
          <ModalPopup
            modal={this.state.modalmsg}
            toggle={this.togglemsg}
            closebtn={true}
            innerclass="popup_alert"
          >
            {this.state.error !== "" && (
              <div>
                <div className="popalert_top">
                  <div className="popaler_info">
                    <i>
                      <img src={DisplayImage("ico_failed")} alt="" />
                    </i>
                    <h3 className="title2 text-uppercase">
                      {this.state.lang.best_showroom.voting_failed}
                    </h3>
                    <p>{DisplayContent(this.state.error)}</p>
                  </div>
                </div>
                <button
                  className="btn btn_yellow btn-lg btn-block arch_btn text-uppercase"
                  onClick={this.togglemsg}
                >
                  {this.state.lang.common.close}
                </button>
              </div>
            )}
            {this.state.success !== "" && (
              <div>
                <div className="popalert_top">
                  <div className="popaler_info">
                    <i>
                      <img src={DisplayImage("ico_tick")} alt="" />
                    </i>
                    <h3 className="title2">
                      {DisplayContent(
                        this.state.lang.best_showroom.thank_voting
                      )}
                    </h3>
                    <p>{DisplayContent(this.state.success)}</p>
                  </div>
                </div>
                <button
                  className="btn btn_yellow btn-lg btn-block arch_btn text-uppercase"
                  onClick={this.togglemsg}
                >
                  <i className="ico_r ico_rgrey text-lowercase"></i>
                  {this.state.creaditcoin}
                  <span className="text-lowercase"> i</span>-Coins
                </button>
              </div>
            )}
          </ModalPopup>
        </div>
      );
    } else {
      return IntialLoading();
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPage: param => {
      dispatch({ type: GET_PAGE, param });
    },
    getBestshowroom: param => {
      dispatch({ type: GET_BESTSHOWROOM, param });
    },
    getProjects: param => {
      dispatch({ type: GET_PROJECTS, param });
    }, 
    getShowroom : param => {
    	dispatch({ type: GET_SHOWROOM})
    }
  };
};

const mapStateToProps = state => {
  return {
    pagedata: state.pagedata,
    bestshowroomdata: state.bestshowroomdata,
    projectdata: state.projectdata, 
    showroom: state.showroom, 
  };
};

Index.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  })
};

Index = connect(mapStateToProps, mapDispatchToProps)(Index);

export default withRouter(Index);
