/* eslint-disable */
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Select from 'react-select';
import {
  language,
  site_title,
  checkauthentication,
  emailValidate,
  numariconly,
  poweredByFootre,
  ScrollTop,
  getImage,
  headerDetails,
  DisplayContent,
  DisplayImage
} from "../Config/Helpers";
import cookie from "react-cookies";
import { GET_UPDATE_PROFILE, GET_EMPLOYEE_LIST, GET_MYACCOUNT_PROFILE } from "../../actions";
import ModalPopup from "../Layout/ModalPopup";
import { Scrollbars } from "react-custom-scrollbars";
import { Helmet } from "react-helmet";
import ScrollAnimation from "react-animate-on-scroll";

class Index extends React.Component {
  constructor(props) {
    super(props);
    if (checkauthentication() === false) {
      const { history } = this.props;
      history.push("/login");
    }

    this.state = {
      frmloading: false,
      lang: language(),
      user_details: cookie.load("user_details"),
      referral_code: this.props.location.search,
      error: "",
      success: "",
      checkUser:"", 
      employee_id: "", 
      name:
        cookie.load("user_details") !== "" &&
        typeof cookie.load("user_details") !== "undefined" &&
        cookie.load("user_details").name !== "" &&
        typeof cookie.load("user_details").name !== "undefined"
          ? cookie.load("user_details").name
          : "",
      email:
        cookie.load("user_details") !== "" &&
        typeof cookie.load("user_details") !== "undefined" &&
        cookie.load("user_details").email !== "" &&
        typeof cookie.load("user_details").email !== "undefined"
          ? cookie.load("user_details").email
          : "",
      password: "",
      contact_number:
        cookie.load("user_details") !== "" &&
        typeof cookie.load("user_details") !== "undefined" &&
        cookie.load("user_details").mobile_number !== "" &&
        typeof cookie.load("user_details").mobile_number !== "undefined"
          ? cookie.load("user_details").mobile_number
          : "",
      reference_id: "",    
      error_name: "",
      error_email: "",
      error_password: "",
      error_contact_number: "",
      modalStaticBlock: false,
      image_error: "",
      delete_image: 0,
      removePhoto:
        cookie.load("user_details") !== "" &&
        typeof cookie.load("user_details") !== "undefined" &&
        cookie.load("user_details").photo !== "" &&
        typeof cookie.load("user_details").photo !== "undefined"
          ? true
          : false,
      profilephoto:
        cookie.load("user_details") !== "" &&
        typeof cookie.load("user_details") !== "undefined" &&
        cookie.load("user_details").photo !== "" &&
        typeof cookie.load("user_details").photo !== "undefined"
          ? cookie.load("user_details").photo
          : ""
    };
    this.toggleStaticBlock = this.toggleStaticBlock.bind(this);
    this.togglemsg = this.togglemsg.bind(this);
  }
  componentDidMount() {
    ScrollTop();
    this.props.getEmployeeList();
    this.props.getMyAccountProfile();
  }

  componentDidUpdate(prevProps) {
    if (this.props.profiledata !== prevProps.profiledata) {
      /* Profile Update Response */
      if (
        this.props.profiledata.status !== "" &&
        typeof this.props.profiledata.status !== "undefined" &&
        typeof this.props.profiledata.status !== undefined
      ) {
        this.setState({ frmloading: false });
        if (this.props.profiledata.status === "ok") {
          //var image = document.getElementById('profileImage');
          //image.src = URL.createObjectURL(profile1);

          cookie.save("user_details", this.props.profiledata.result_set);

          this.setState(
            { success: this.props.profiledata.message },
            function() {
              this.togglemsg();
              //this.props.getMyAccountProfile();
            }
          );
          this.setState({ error: "" });
        } else {
          this.setState({ success: "" });
          this.setState({ error: this.props.profiledata.message }, function() {
            this.togglemsg();
          });
        }
      }
    }

    if (this.props.staticblockdata !== prevProps.staticblockdata) {
      if (this.props.staticblockdata.status === "ok") {
        this.setState({ staticblock: this.props.staticblockdata.result_set });
      }
    }

    if (this.props.myaccountprofile !== prevProps.myaccountprofile) {
      if (this.props.myaccountprofile.status !== "" && typeof this.props.myaccountprofile.status !== "undefined" &&
        typeof this.props.myaccountprofile.status !== undefined) {
        if (this.props.myaccountprofile.status === "ok") {
                let checkuser = this.props.myaccountprofile.result_set.new_user;
                let defaultValue = "";
                if(checkuser==="yes"){
                  defaultValue = 1;
                 }else{
                  defaultValue = 0; 
                }
                this.setState({
                   checkUser:defaultValue,
                   employee_id:this.props.myaccountprofile.result_set.employee_reference_id
                })
               const referenceEmpolyee = this.props.myaccountprofile.result_set;
              if(referenceEmpolyee.employee_reference!=='' && referenceEmpolyee.employee_reference!==null){
                const employees = referenceEmpolyee.employee_reference.split('||');
                //const employeesId = (employees[0]).split('~~');
                 const employeesVal = employees[1]; 
                 let emp = [];
                   emp.push({
                        value : this.props.myaccountprofile.result_set.employee_reference_id, 
                        label : employeesVal
                });
            this.setState({reference_id: emp});
        }
      }
    }

  }

}


  toggleStaticBlock() {
    this.setState(prevState => ({
      modalStaticBlock: !prevState.modalStaticBlock
    }));
  }
  togglemsg() {
    this.setState(prevState => ({
      modalmsg: !prevState.modalmsg
    }));
  }

  /* Set State for input value */
  handleChangeTxt = event => {
    let { name, value } = event.target;
    if (name === "email") {
      if (emailValidate(value) === false && value !== "") {
        this.setState({
          ["error_" + name]: this.state.lang.common.invalid_email
        });
      } else {
        this.setState({ ["error_" + name]: "" });
      }
      this.setState({ [name]: value });
    } else {
      this.setState({ [name]: value, ["error_" + name]: "" });
    }
  };


  handleSelectChange = (event) => {  
    this.setState({ reference_id:event});
   //console.log(`Option selected:`, event.value);
   this.setState({ reference_value:event.value});
  }


  /* File bowse display image */
  onChangeFile(event) {
    event.stopPropagation();
    event.preventDefault();

    var selectedFile = event.target.files[0];
    var idxDot = selectedFile.name.lastIndexOf(".") + 1;
    var extFile = selectedFile.name
      .substr(idxDot, selectedFile.name.length)
      .toLowerCase();
    if (
      extFile === "jpg" ||
      extFile === "jpeg" ||
      extFile === "png" ||
      extFile === "svg" ||
      extFile === "gif"
    ) {
      var image = document.getElementById("profileImage");
      image.src = URL.createObjectURL(event.target.files[0]);
      this.setState({ removePhoto: true, delete_image: 0 });
      this.setState({ image_error: "" });
    } else {
      this.setState({ image_error: "Image file only allowed" });
    }
  }


  /* Update Profile function */
  UpdateProfile() {
    let error = 0;
    if (this.state.name === "") {
      this.setState({ error_name: this.state.lang.common.field_required });
      error++;
    } else {
      this.setState({ error_name: "" });
    }
    if (this.state.email === "") {
      this.setState({ error_email: this.state.lang.common.field_required });
      error++;
    } else {
      if (emailValidate(this.state.email) === false) {
        this.setState({
          error_email: this.state.lang.common.invalid_email
        });
      } else {
        this.setState({ error_email: "" });
      }
    }
    if (this.state.password !== "") {
      if (this.state.password.length < 6) {
        this.setState({
          error_password: this.state.lang.common.passwordmin
        });
        error++;
      } else {
        this.setState({ error_password: "" });
      }
    }

    if (this.state.contact_number === "") {
      this.setState({
        error_contact_number: this.state.lang.common.field_required
      });
      error++;
    } else {
      this.setState({ error_contact_number: "" });
    }

    if (error === 0) {
      this.setState({ frmloading: true });
      let data = new FormData();
      let imagefile = document.querySelector("#profile_image");
      if (
        imagefile.files[0] !== "" &&
        typeof imagefile.files[0] !== "undefined"
      ) {
        data.append("photo", imagefile.files[0]);
      }
      data.append("name", this.state.name);
      data.append("email", this.state.email);
      data.append("password", this.state.password);
      data.append("mobile_number", this.state.contact_number);
      data.append("photo_delete", this.state.delete_image);
      {this.state.checkUser === 1 && 
      data.append("reference_id", this.state.reference_value);
      }

      if (
        this.state.referral_code !== "" &&
        typeof this.state.referral_code !== "undefined"
      ) {
        let referral_code = this.state.referral_code.split("=");
        data.append("referral_code", referral_code[1]);
      }
      //console.log(data);
     this.props.getUpdateProfile(data);
    }
  }

  removeProfileImage() {
    this.setState({ removePhoto: false, delete_image: 1 });
    var image = document.getElementById("profileImage");
    image.src = DisplayImage("profile1");
  }

  loadBlock(slug) {
    if (
      this.state.staticblock !== "" &&
      this.state.staticblock[slug] !== "" &&
      typeof this.state.staticblock[slug] !== "undefined"
    ) {
      this.setState({ blockDetails: this.state.staticblock[slug] }, function() {
        this.toggleStaticBlock();
      });
    }
  }

  render() {
    console.log(this.state.employee_id, "employees");
    return (
      <div className="center">
        <Helmet>
          <title>{site_title(this.state.lang.myaccount.title)}</title>
          <meta name="keywords" content={this.state.lang.myaccount.title} />
          <meta name="description" content={this.state.lang.myaccount.title} />
        </Helmet>
        {headerDetails()}
        <div className="land_wrapper">
          <div className="land_content signup_content">
            <div className="container">
              <div className="lading_logo">
                <ScrollAnimation
                  animateIn="fadeIn"
                  animateOnce={true}
                  offset={0}
                  delay={100}
                >
                  <img
                    src={DisplayImage("landingLogo")}
                    alt={this.state.lang.common.title}
                  />
                </ScrollAnimation>
              </div>
              <div className="signup_form">
                <ScrollAnimation
                  animateIn="fadeIn"
                  animateOnce={true}
                  offset={0}
                  delay={300}
                >
                  <form className="form_sec">
                    <div className="form-group upload_imggroup">
                      <div className="input_field">
                        <div
                          className={
                            this.state.removePhoto === true
                              ? "choose_file text-center choose_img_available"
                              : "choose_file text-center"
                          }
                        >
                          {this.state.removePhoto === true && (
                            <lable
                              className="remove-image"
                              onClick={e => {
                                e.preventDefault();
                                this.removeProfileImage();
                              }}
                            >
                              remove
                            </lable>
                          )}
                          <input
                            type="file"
                            name="profile_image"
                            id="profile_image"
                            accept="image/*"
                            onChange={this.onChangeFile.bind(this)}
                          />
                          <div className="profile_img">
                            <img
                              src={
                                this.state.profilephoto !== ""
                                  ? getImage(this.state.profilephoto, "profile")
                                  : DisplayImage("profile1")
                              }
                              id="profileImage"
                              alt={this.state.lang.sign_up.profile}
                            />
                            {this.state.removePhoto === true && (
                              <span
                                className="remove_profileimg"
                                id="aaaa"
                              ></span>
                            )}
                          </div>
                          <p data-text="Upload Profile Picture">
                            <span>{this.state.lang.sign_up.upload_image} </span>
                          </p>
                          {this.state.image_error !== "" && (
                            <label className="error">
                              {this.state.image_error}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-sm-4">
                          <label>{this.state.lang.sign_up.name}:</label>
                        </div>
                        <div className="col-sm-8">
                          <div className="input_field">
                            <input
                              type="text"
                              name="name"
                              className={
                                this.state.error_name !== ""
                                  ? "form-control error"
                                  : "form-control"
                              }
                              onChange={this.handleChangeTxt.bind(this)}
                              value={this.state.name}
                            />
                            <div className="input_glowanimation"></div>
                            {this.state.error_name !== "" && (
                              <label className="error">
                                {this.state.error_name}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-sm-4">
                          <label>{this.state.lang.sign_up.email}:</label>
                        </div>
                        <div className="col-sm-8">
                          <div className="input_field">
                            <input
                              type="text"
                              name="email"
                              className={
                                this.state.error_email !== ""
                                  ? "form-control error"
                                  : "form-control"
                              }
                              onChange={this.handleChangeTxt.bind(this)}
                              readOnly={true}
                              value={this.state.email}
                            />
                            <div className="input_glowanimation"></div>
                            {this.state.error_email !== "" && (
                              <label className="error">
                                {this.state.error_email}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-sm-4">
                          <label>{this.state.lang.sign_up.password}:</label>
                        </div>
                        <div className="col-sm-8">
                          <div className="input_field">
                            <input
                              type="password"
                              name="password"
                              className={
                                this.state.error_password !== ""
                                  ? "form-control error"
                                  : "form-control"
                              }
                              onChange={this.handleChangeTxt.bind(this)}
                              value={this.state.password}
                            />
                            <div className="input_glowanimation"></div>
                            {this.state.error_password !== "" && (
                              <label className="error">
                                {this.state.error_password}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>{this.state.lang.sign_up.contact_number}</label>
                      <div className="input_field contact-field">
                        <input
                          type="number"
                          name="contact_number"
                          className={
                            this.state.error_contact_number !== ""
                              ? "form-control error"
                              : "form-control"
                          }
                          onChange={this.handleChangeTxt.bind(this)}
                          value={this.state.contact_number}
                          onKeyPress={e => numariconly(e)}
                          onInput={e => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 8);
                          }}
                        />
                        <div className="input_glowanimation"></div>
                      </div>
                      {this.state.error_contact_number !== "" && (
                        <label className="error">
                          {this.state.error_contact_number}
                        </label>
                      )}
                    </div>


                    {this.state.checkUser === 1 &&

                    <div className="form-group">
                      <label>{this.state.lang.sign_up.referralId}</label>
                      <div className="input_field custom_select">
                      <Select
                      searchable='true'
                      onChange={this.handleSelectChange.bind(this)}
                      placeholder={this.state.lang.sign_up.referralId}
                      name="reference_id"
                      value={this.state.reference_id}
                      options={this.props.employeeList}
                        />
                      }
                      </div>
                    </div>
                  }


                    <div className="form-group text-center">
                      <button
                        className={
                          this.state.frmloading === true
                            ? "btn btn_yellow btn-block text-uppercase load_btn"
                            : "btn btn_yellow btn-block text-uppercase"
                        }
                        disabled={this.state.frmloading}
                        onClick={e => {
                          e.preventDefault();
                          this.UpdateProfile();
                        }}
                      >
                        <span>{this.state.lang.myaccount.update}</span>
                      </button>
                    </div>
                  </form>
                </ScrollAnimation>
              </div>
            </div>
          </div>
          {poweredByFootre()}
        </div>
        <ModalPopup
          modal={this.state.modalStaticBlock}
          toggle={this.toggleStaticBlock}
          closebtn={true}
          innerclass="popup_terms"
        >
          <div>
            {this.state.blockDetails !== "" &&
              typeof this.state.blockDetails !== "undefined" && (
                <div>
                  <h2 className="title1 text-center">
                    {this.state.blockDetails.title}
                  </h2>
                  <div className="smcustom_scroll">
                    <Scrollbars className="smcustom_scroll_in">
                      <div className="terms_content">
                        {DisplayContent(this.state.blockDetails.description)}
                      </div>
                    </Scrollbars>
                  </div>
                </div>
              )}
          </div>
        </ModalPopup>
        <ModalPopup
          modal={this.state.modalmsg}
          toggle={this.togglemsg}
          closebtn={true}
          innerclass="popup_alert"
        >
          {this.state.error !== "" && (
            <div>
              <div className="popalert_top">
                <div className="popaler_info">
                  <i>
                    <img src={DisplayImage("ico_failed")} alt="" />
                  </i>
                  <h3 className="title2">{this.state.lang.myaccount.faild}</h3>
                  <p>{DisplayContent(this.state.error)}</p>
                </div>
              </div>
              <button
                className="btn btn_yellow btn-lg btn-block arch_btn text-uppercase"
                onClick={this.togglemsg}
              >
                {this.state.lang.common.close}
              </button>
            </div>
          )}
          {this.state.success !== "" && (
            <div>
              <div className="popalert_top">
                <div className="popaler_info">
                  <i>
                    <img src={DisplayImage("ico_tick")} alt="" />
                  </i>
                  <h3 className="title2">
                    {this.state.lang.myaccount.success}
                  </h3>
                  <p>{DisplayContent(this.state.success)}</p>
                </div>
              </div>
              <button
                className="btn btn_yellow btn-lg btn-block arch_btn text-uppercase"
                onClick={this.togglemsg}
              >
                {this.state.lang.common.close}
              </button>
            </div>
          )}
        </ModalPopup>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUpdateProfile: Param => {
      dispatch({ type: GET_UPDATE_PROFILE, Param: Param });
    },
    getMyAccountProfile: Param => {
      dispatch({ type: GET_MYACCOUNT_PROFILE });
    }, 
    getEmployeeList: (Param) => {
      dispatch({ type: GET_EMPLOYEE_LIST });
    }
  };
};

const mapStateToProps = state => {
  return {
    profiledata: state.profiledata,
    employeeList   : state.employeeList,
    myaccountprofile : state.myaccountprofile
  };
};

Index.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  })
};

Index = connect(mapStateToProps, mapDispatchToProps)(Index);

export default withRouter(Index);
