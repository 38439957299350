/* eslint-disable */
import React from 'react';
import landingLogo from '../common/images/landing-logo.png';
import footerLogo from '../common/images/footer-logo.png';
import logo from '../common/images/logo.png';
import canvasLogo from '../common/images/canvas-logo.png';
import canvasFlogo from '../common/images/canvas-flogo.png';
import hico_deals from '../common/images/hico_deals.png';
import hico_best from '../common/images/hico_best.png';
import hico_gallery from '../common/images/hico_gallery.png';
import hico_merchants from '../common/images/hico_merchants.png';
import hico_refer from '../common/images/hico_refer.png';
import hico_account from '../common/images/hico_account.png';
import hico_deals1 from '../common/images/hico_deals1.png';
import hico_best1 from '../common/images/hico_best1.png';
import hico_gallery1 from '../common/images/hico_gallery1.png';
import hico_merchants1 from '../common/images/hico_merchants1.png';
import hico_refer1 from '../common/images/hico_refer1.png';
import hico_account1 from '../common/images/hico_account1.png';
import mlogolg_kfc from '../common/images/mlogolg-kfc.png';
import no_listimg from '../common/images/no_listimg.jpg';

class Style extends React.Component {
    constructor(props) {
        super(props);       
    }
   doMeow = e => {
 e.preventDefault();
 // do meow here
}
    render() {

        return (

            <div className="center">
              <header>
                <div className="header_in">
                  <div className="container">
                    <a href="#" className="menu_ico mk-css-icon-menu icon-size-big">
                      <div className="mk-css-icon-menu-line-1"></div>
                      <div className="mk-css-icon-menu-line-2"></div>
                      <div className="mk-css-icon-menu-line-3"></div>
                    </a>
                    <a href="#javascript;" className="logo" onClick={this.doMeow}>
                      <img src={logo} alt="" />
                    </a>
                  </div>
                  <div className="offcanvas_menu">
                    <div className="offcanvas_top">
                      <div className="canvas_logo text-center">
                        <img src={canvasLogo} alt="" />  
                      </div>
                      <div className="main_menusec">
                        <ul className="main_menulist">
                          <li className="active">
                            <a href="#" title="Home"><span>Home</span></a>
                          </li>
                          <li>
                            <a href="#" title="Hot Deals">
                              <i>
                                <img className="mmico" src={hico_deals} />
                                <img className="mmanim_ico" src={hico_deals1} />
                              </i>
                              <span>Hot Deals</span>
                            </a>
                          </li>
                          <li>
                            <a href="#" title="Participating Merchants">
                              <i><img className="mmico" src={hico_merchants} /><img className="mmanim_ico" src={hico_merchants1} /></i>
                              <span>Participating Merchants</span>
                            </a>
                          </li>
                          <li>
                            <a href="#" title="Projects Gallery">
                              <i><img className="mmico" src={hico_gallery} /><img className="mmanim_ico" src={hico_gallery1} /></i>
                              <span>Projects Gallery</span>
                            </a>
                          </li>
                          <li>
                            <a href="#" title="Refer A Friend">
                              <i><img className="mmico" src={hico_refer} /><img className="mmanim_ico" src={hico_refer1} /></i>
                              <span>Refer A Friend</span>
                            </a>
                          </li>
                          <li>
                            <a href="#" title="Best Showroom">
                              <i><img className="mmico" src={hico_best} /><img className="mmanim_ico" src={hico_best1} /></i>
                              <span>Best Showroom</span>
                            </a>
                          </li>
                          <li>
                            <a href="#" title="Announcement">
                              <i></i>
                              <span>Announcement <b className="announce_count">2</b></span>
                            </a>
                          </li>
                          <li className="menulink_account">
                            <a href="#" title="My Account">
                              <i><img className="mmico" src={hico_account} /><img className="mmanim_ico" src={hico_account1} /></i>
                              <span>My Account</span>
                            </a>
                          </li>
                          <li className="menulink_logout">
                            <a href="#" title="Log Out">Log Out <i className="fa fa-sign-out"></i></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="offcanvas_btm">
                      <h5>Powered By</h5>
                      <div className="canvas_flogo">
                        <img src={canvasFlogo} alt="" />
                      </div>
                    </div>
                  </div>
                </div>                
              </header>
               <div className="details_topbar">
                  <a href="#" className="back_arrow title2" title="">KFC </a>
              </div>
              <div className="merchant_banimg">
                <img src={mlogolg_kfc} alt="" />
              </div>
              <div className="item_list">
                <div className="item_row">
                  <a href="#">
                    <div className="item_img">
                      <span className="offer_label deal_status deal_status_active">Active</span>
                      <img src={no_listimg} alt=""/>
                    </div>
                    <div className="item_info">
                      <h3>KFC Lorem</h3>
                      <p className="deal_buyoff">Buy 1 Free 1</p>
                      <p className="deal_loc">10 locations</p>
                      <span className="deals_coins"><i className="ico_r text-lowercase"></i>20</span>
                    </div>
                  </a>
                </div>
                <div className="item_row">
                  <a href="#">
                    <div className="item_img">
                      <span className="offer_label deal_status deal_status_hot">Hot Deals</span>
                      <img src={no_listimg} alt=""/>
                    </div>
                    <div className="item_info">
                      <h3>KFC Lorem</h3>
                      <p className="deal_buyoff">Buy 1 Free 1</p>
                      <p className="deal_loc">10 locations</p>
                      <span className="deals_coins"><i className="ico_r text-lowercase"></i>20</span>
                    </div>
                  </a>
                </div>
                <div className="item_row">
                  <a href="#">
                    <div className="item_img">
                      <span className="offer_label deal_status deal_status_expired">Expired</span>
                      <img src={no_listimg} alt=""/>
                    </div>
                    <div className="item_info">
                      <h3>KFC Lorem</h3>
                      <p className="deal_buyoff">Buy 1 Free 1</p>
                      <p className="deal_loc">10 locations</p>
                      <span className="deals_coins"><i className="ico_r text-lowercase"></i>20</span>
                    </div>
                  </a>
                </div>
              </div>
              <button className="btn btn_yellow " disabled>test</button>
            </div>
        );
    }
}

export default Style;
