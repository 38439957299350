/* eslint-disable */
import React from "react";
import ReactDOM from "react-dom";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { GET_UNREADANNOUNCEMENT, GET_READANNOUNCEMENT } from "../../actions";
import {
  language,
  getImage,
  DisplayImage,
  DisplayContent,
  ScrollTop,
  EnableScrollTop
} from "../Config/Helpers";
import $ from "jquery";
import { Scrollbars } from "react-custom-scrollbars";
import Swiper from "react-id-swiper";
import ModalPopup from "../Layout/ModalPopup";

const params = {
  loop: true,
  noSwiping: true,
  allowTouchMove: false,
  autoHeight: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  }
};

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: language(),
      menuactive: false,
      current_menu: this.props.location,
      total_unreadrecords: 0,
      announcement_details: "",
      modalannouncement: false
    };
    this.toggleanno = this.toggleanno.bind(this);
    window.addEventListener("scroll", EnableScrollTop);
  }

  componentDidMount() {
    $(document).ready(function() {
      $("html, body").animate({ scrollTop: 0 }, 500);
    });
    this.props.getUnreadAnnouncement();
    document.addEventListener("click", this.handleClickOutside, true);
    EnableScrollTop();
  }
  toggleMenu() {
    this.setState(prevState => ({
      menuactive: !prevState.menuactive
    }));
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.unreadannouncementdata !== prevProps.unreadannouncementdata
    ) {
      /* Announcement Response */
      this.setState({ frmloading: false });
      if (
        this.props.unreadannouncementdata.status !== "" &&
        typeof this.props.unreadannouncementdata.status !== "undefined" &&
        typeof this.props.unreadannouncementdata.status !== undefined
      ) {
        if (this.props.unreadannouncementdata.status === false) {
          const { history } = this.props;
          history.push("/logout");
        } else if (this.props.unreadannouncementdata.status === "ok") {
          let announcement = "";
          if (
            this.props.unreadannouncementdata.result_set !== "" &&
            typeof this.props.unreadannouncementdata.result_set !== "undefined"
          ) {
            announcement = this.props.unreadannouncementdata.result_set;
            let postdata = {
              type: "all"
            };
            this.props.getReadAnnouncement(postdata);
          }
          if (announcement !== "") {
            this.setState(
              {
                announcement_details: announcement,
                total_unreadrecords: this.props.unreadannouncementdata
                  .total_unreadrecords
              },
              function() {
                this.toggleanno();
              }
            );
          }
        }
      }
    }
  }

  toggleanno() {
    this.setState(prevState => ({
      modalannouncement: !prevState.modalannouncement
    }));
  }

  loadAnnouncement() {
    return this.state.announcement_details.map((item, index) => {
      return (
        <div key={index}>
          <div className="announce_itemimg">
            <Link to={"/announcement"} className="announce_item">
              <img src={getImage(item.image, "announcement")} alt="" />
            </Link>
          </div>
          <div className="announce_iteminfo">
            <Link to={"/announcement"} className="announce_item">
              <h3> {item.title}</h3>
            </Link>
            {item.description !== "" ? DisplayContent(item.description) : ""}
          </div>
        </div>
      );
    });
  }

  /* Outside Click  close Menu */
  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        menuactive: false
      });
    }
  };
  render() {
    return (
      <header>
        <div className="header_in">
          <div className="container">
            <a
              href="#javascript;"
              onClick={e => {
                e.preventDefault();
                this.toggleMenu();
              }}
              className={
                this.state.menuactive === true
                  ? "menu_ico mk-css-icon-menu icon-size-big active"
                  : "menu_ico mk-css-icon-menu icon-size-big"
              }
            >
              <div className="mk-css-icon-menu-line-1"></div>
              <div className="mk-css-icon-menu-line-2"></div>
              <div className="mk-css-icon-menu-line-3"></div>
            </a>
            <Link to={"/"} className="logo">
              <img src={DisplayImage("logo")} alt="" />
            </Link>
          </div>
          <div
            className={
              this.state.menuactive === true
                ? "offcanvas_menu active"
                : "offcanvas_menu"
            }
          >
            <div className="offcanvas_top smcustom_scroll">
              <Scrollbars className="smcustom_scroll_in">
                <div className="offcanvas_topin">
                  <div className="canvas_logo text-center">
                    <Link to={"/"}>
                      <img src={DisplayImage("canvasLogo")} alt="" />
                    </Link>
                  </div>
                  <div className="main_menusec">
                    <ul className="main_menulist">
                      <li
                        className={
                          this.state.current_menu.pathname === "/"
                            ? "active"
                            : ""
                        }
                      >
                        <Link to={"/"} title={this.state.lang.home.title}>
                          <i>
                            <img
                              className="mmico"
                              src={DisplayImage("hico_home")}
                              alt=""
                            />
                            <img
                              className="mmanim_ico"
                              src={DisplayImage("hico_home1")}
                              alt=""
                            />
                          </i>
                          <span>{this.state.lang.home.title}</span>
                        </Link>
                      </li>
                      <li
                        className={
                          this.state.current_menu.pathname ===
                            "/download-deals" ||
                          this.state.current_menu.pathname.indexOf(
                            "/deal-detail"
                          ) >= 0 ||
                          this.state.current_menu.pathname.indexOf(
                            "/hot-deals"
                          ) >= 0
                            ? "active"
                            : ""
                        }
                      >
                        <Link
                          to={"/hot-deals"}
                          title={this.state.lang.home.hot_deals}
                        >
                          <i>
                            <img
                              className="mmico"
                              src={DisplayImage("hico_deals")}
                              alt=""
                            />
                            <img
                              className="mmanim_ico"
                              src={DisplayImage("hico_deals1")}
                              alt=""
                            />
                          </i>
                          <span>{this.state.lang.home.hot_deals}</span>
                        </Link>
                      </li>
                      <li
                        className={
                          this.state.current_menu.pathname === "/merchants"
                            ? "active"
                            : ""
                        }
                      >
                        <Link
                          to={"/merchants"}
                          title={this.state.lang.home.p_merchants}
                        >
                          <i>
                            <img
                              className="mmico"
                              src={DisplayImage("hico_merchants")}
                              alt=""
                            />
                            <img
                              className="mmanim_ico"
                              src={DisplayImage("hico_merchants1")}
                              alt=""
                            />
                          </i>
                          <span>{this.state.lang.home.p_merchants}</span>
                        </Link>
                      </li>
                      <li
                        className={
                          this.state.current_menu.pathname ===
                          "/projects-gallery"
                            ? "active"
                            : ""
                        }
                      >
                        <Link
                          to={"/projects-gallery"}
                          title={this.state.lang.home.p_gallery}
                        >
                          <i>
                            <img
                              className="mmico"
                              src={DisplayImage("hico_gallery")}
                              alt=""
                            />
                            <img
                              className="mmanim_ico"
                              src={DisplayImage("hico_gallery1")}
                              alt=""
                            />
                          </i>
                          <span>{this.state.lang.home.p_gallery}</span>
                        </Link>
                      </li>
                      <li
                        className={
                          this.state.current_menu.pathname === "/refer-friend"
                            ? "active"
                            : ""
                        }
                      >
                        <Link
                          to={"/refer-friend"}
                          title={this.state.lang.refer.title}
                        >
                          <i>
                            <img
                              className="mmico"
                              src={DisplayImage("hico_refer")}
                              alt=""
                            />
                            <img
                              className="mmanim_ico"
                              src={DisplayImage("hico_refer1")}
                              alt=""
                            />
                          </i>
                          <span>{this.state.lang.refer.title}</span>
                        </Link>
                      </li>
                      <li
                        className={
                          this.state.current_menu.pathname === "/best-showroom"
                            ? "active"
                            : ""
                        }
                      >
                        <Link
                          to={"/best-showroom"}
                          title={this.state.lang.best_showroom.title}
                        >
                          <i>
                            <img
                              className="mmico"
                              src={DisplayImage("hico_best")}
                              alt=""
                            />
                            <img
                              className="mmanim_ico"
                              src={DisplayImage("hico_best1")}
                              alt=""
                            />
                          </i>
                          <span>{this.state.lang.best_showroom.title}</span>
                        </Link>
                      </li>
                      <li
                        className={
                          this.state.current_menu.pathname === "/announcement"
                            ? "active"
                            : ""
                        }
                      >
                        <Link
                          to={"announcement"}
                          title={this.state.lang.announcement.title}
                        >
                          <i>
                            <img
                              className="mmico"
                              src={DisplayImage("hico_announcement")}
                              alt=""
                            />
                            <img
                              className="mmanim_ico"
                              src={DisplayImage("hico_announcement1")}
                              alt=""
                            />
                          </i>
                          <span>
                            {this.state.lang.announcement.title}
                            {this.state.total_unreadrecords > 0 && (
                              <b className="announce_count">
                                {this.state.total_unreadrecords}
                              </b>
                            )}
                          </span>
                        </Link>
                      </li>
                      <li className="menulink_account">
                        <Link
                          to={"/my-account"}
                          title={this.state.lang.myaccount.title}
                        >
                          <i>
                            <img
                              className="mmico"
                              src={DisplayImage("hico_account")}
                              alt=""
                            />
                            <img
                              className="mmanim_ico"
                              src={DisplayImage("hico_account1")}
                              alt=""
                            />
                          </i>
                          <span>{this.state.lang.myaccount.title}</span>
                        </Link>
                      </li>
                      <li className="menulink_logout">
                        <Link
                          to={"/logout"}
                          title={this.state.lang.common.log_out}
                        >
                          <span>{this.state.lang.common.log_out}</span>{" "}
                          <i className="fa fa-sign-out"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="offcanvas_btm">
                  <h5>{this.state.lang.common.powered_by}</h5>
                  <div className="canvas_flogo">
                    <img src={DisplayImage("canvasFlogo")} alt="" />
                  </div>
                </div>
              </Scrollbars>
            </div>
          </div>
        </div>
        <ModalPopup
          modal={this.state.modalannouncement}
          toggle={this.toggleanno}
          closebtn={true}
          innerclass="popup_announce"
        >
          {this.state.announcement_details !== "" &&
            this.state.announcement_details !== "undefined" && (
              <div>
                <div className="popalert_top">
                  <div className="announce_slider">
                    {this.state.announcement_details.length > 1}
                    {this.state.announcement_details.length > 1 ? (
                      <Swiper {...params}>{this.loadAnnouncement()}</Swiper>
                    ) : (
                      this.loadAnnouncement()
                    )}
                  </div>
                </div>
                <button
                  onClick={this.toggleanno}
                  className="btn btn_yellow btn-lg btn-block arch_btn text-uppercase"
                >
                  {this.state.lang.common.close}
                </button>
              </div>
            )}
        </ModalPopup>
        <span
          className="scrollToTop"
          onClick={e => {
            e.preventDefault();
            ScrollTop();
          }}
        >
          <i className="fa fa-angle-up"></i>
        </span>
      </header>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUnreadAnnouncement() {
      dispatch({ type: GET_UNREADANNOUNCEMENT });
    },
    getReadAnnouncement: Param => {
      dispatch({ type: GET_READANNOUNCEMENT, Param: Param });
    }
  };
};

const mapStateToProps = state => {
  return {
    unreadannouncementdata: state.unreadannouncementdata
  };
};

Header.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  })
};

Header = connect(mapStateToProps, mapDispatchToProps)(Header);

export default withRouter(Header);
