/* eslint-disable */
import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import cookie from "react-cookies";
import {
  language,
  headerDetails,
  checkauthentication,
  site_title,
  DisplayImage
} from "../Config/Helpers";
import { Helmet } from "react-helmet";
import { GET_PROFILE } from "../../actions";
import ScrollAnimation from "react-animate-on-scroll";

class Index extends React.Component {
  constructor(props) {
    super(props);
    if (checkauthentication() === false) {
      const { history } = this.props;
      history.push("/login");
    }
    this.state = {
      lang: language(),
      userdetails: cookie.load("user_details"),
      user_coin: cookie.load("user_coin"),
      profiledata: ""
    };
  }
  componentDidMount() {

  if(cookie.load("user_details") !== "" &&  typeof cookie.load("user_details") !== "undefined"){
  this.props.getProfile();
   }
    
  }
  componentDidUpdate(prevProps) {
    if (this.props.profiledata !== prevProps.profiledata) {
      /* Profile Response */
      if (
        this.props.profiledata.status !== "" &&
        typeof this.props.profiledata.status !== "undefined"
      ) {
        if (this.props.profiledata.status === "ok") {
          this.setState({ profiledata: this.props.profiledata.result_set });
        } else {
        }
      }
    }
  }

  render() {
    if (
      this.state.userdetails !== "" &&
      typeof this.state.userdetails !== "undefined"
    ) {
      return (
        <div className="center">
          <Helmet>
            <title>{site_title(this.state.lang.home.title)}</title>
            <meta name="keywords" content={this.state.lang.home.title} />
            <meta name="description" content={this.state.lang.home.title} />
          </Helmet>
          {headerDetails()}
          <div className="ban_dashboard">
            <div className="container">
              <div className="lading_logo">
                <ScrollAnimation
                  animateIn="fadeIn"
                  animateOnce={true}
                  offset={0}
                  delay={50}
                >
                  <img
                    src={DisplayImage("landingLogo")}
                    alt={this.state.lang.common.title}
                  />
                </ScrollAnimation>
              </div>
              <div className="bandash_coins text-center">
                <ScrollAnimation
                  animateIn="fadeIn"
                  animateOnce={true}
                  offset={0}
                  delay={150}
                >
                  <p>{this.state.lang.home.current_coin}</p>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeInDown"
                  animateOnce={true}
                  offset={0}
                  delay={250}
                >
                  <span>
                    {this.state.profiledata !== "" &&
                    typeof this.state.profiledata !== "undefined"
                      ? this.state.profiledata.coin
                      : 0}
                  </span>
                </ScrollAnimation>
              </div>
              <ScrollAnimation
                animateIn="fadeIn"
                animateOnce={true}
                offset={0}
                delay={350}
              >
                <Link to={"/hot-deals"}>
                  <button className="btn btn_yellow btn-block text-uppercase">
                    <span>{this.state.lang.home.redeem_today}</span>
                  </button>
                </Link>
              </ScrollAnimation>
            </div>
          </div>
          <div className="dashboard_sec">
            <div className="container">
              <ul className="dashboard_menu">
                <li>
                  <ScrollAnimation
                    animateIn="fadeInDown"
                    animateOnce={true}
                    offset={0}
                    delay={100}
                  >
                    <Link
                      to={"/hot-deals"}
                      title={this.state.lang.home.hot_deals}
                    >
                      <i>
                        <img
                          src={DisplayImage("ico_hotdeals")}
                          alt={this.state.lang.home.hot_deals}
                        />
                      </i>
                      <span>
                        {this.state.lang.home.hot} <br />{" "}
                        {this.state.lang.home.deals}
                      </span>
                    </Link>
                  </ScrollAnimation>
                </li>
                <li>
                  <ScrollAnimation
                    animateIn="fadeInDown"
                    animateOnce={true}
                    offset={0}
                    delay={250}
                  >
                    <Link
                      to={"/merchants"}
                      title={this.state.lang.home.p_merchants}
                    >
                      <i>
                        <img
                          src={DisplayImage("ico_merchants")}
                          alt={this.state.lang.home.p_merchants}
                        />
                      </i>
                      <span>
                        {this.state.lang.home.participating} <br />{" "}
                        {this.state.lang.home.merchants}
                      </span>
                    </Link>
                  </ScrollAnimation>
                </li>
                <li>
                  <ScrollAnimation
                    animateIn="fadeInDown"
                    animateOnce={true}
                    offset={0}
                    delay={400}
                  >
                    <Link
                      to={"/projects-gallery"}
                      title={this.state.lang.home.p_gallery}
                    >
                      <i>
                        <img
                          src={DisplayImage("ico_gallery")}
                          alt={this.state.lang.home.p_gallery}
                        />
                      </i>
                      <span>
                        {this.state.lang.home.projects} <br />{" "}
                        {this.state.lang.home.gallery}
                      </span>
                    </Link>
                  </ScrollAnimation>
                </li>
                <li>
                  <ScrollAnimation
                    animateIn="fadeInDown"
                    animateOnce={true}
                    offset={0}
                    delay={550}
                  >
                    <Link
                      to={"/my-account"}
                      title={this.state.lang.home.my_accout}
                    >
                      <i>
                        <img
                          src={DisplayImage("ico_account")}
                          alt={this.state.lang.home.my_accout}
                        />
                      </i>
                      <span>
                        {this.state.lang.home.my} <br />{" "}
                        {this.state.lang.home.account}
                      </span>
                    </Link>
                  </ScrollAnimation>
                </li>
              </ul>
              <ScrollAnimation
                animateIn="fadeIn"
                animateOnce={true}
                offset={0}
                delay={350}
              >
                <div className="dashboard_boxsec">
                  <Link
                    to={"/refer-friend"}
                    className="dashboard_bx dashbx_refer"
                  >
                    <i>
                      <img src={DisplayImage("ico_refer")} alt="" />
                    </i>
                    <p>{this.state.lang.home.refer}</p>
                  </Link>
                  <Link
                    to={"/best-showroom"}
                    className="dashboard_bx dashbx_best"
                  >
                    <i className="ico_bestshowroom">
                      <img src={DisplayImage("ico_bestshowroom")} alt="" />
                    </i>
                    <i className="ico_besthome">
                      <img src={DisplayImage("ico_besthome")} alt="" />
                    </i>
                    <p>{this.state.lang.home.best_showroom}</p>
                  </Link>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getProfile: Param => {
      dispatch({ type: GET_PROFILE });
    }
  };
};

const mapStateToProps = state => {
  return {
    profiledata: state.profiledata
  };
};

Index.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  })
};

Index = connect(mapStateToProps, mapDispatchToProps)(Index);

export default withRouter(Index);
