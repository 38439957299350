/* eslint-disable */
import React from "react";
import { lang } from "./lang";
import { mediaUrl, siteTitle } from "./config";
import $ from "jquery";
import Parser from "html-react-parser";
import cookie from "react-cookies";
import Header from "../Layout/Header";
import { Helmet } from "react-helmet";
import footerLogo from "../../common/images/footer-logo.png";
import noListimg from "../../common/images/no_listimg.jpg";
import noDetailimg from "../../common/images/no_detailimg.jpg";
import ichapter from "../../common/images/ichapter-logo.gif";
import ico_failed from "../../common/images/ico_failed.png";
import ico_tick from "../../common/images/ico_tick.png";
import ico_map from "../../common/images/ico_map.png";
import ico_terms from "../../common/images/ico_terms.png";
import landingLogo from "../../common/images/landing-logo.png";
import ico_hotdeals from "../../common/images/ico_hotdeals.png";
import ico_merchants from "../../common/images/ico_merchants.png";
import ico_gallery from "../../common/images/ico_gallery.png";
import ico_account from "../../common/images/ico_account.png";
import ico_refer from "../../common/images/ico_refer.png";
import ico_besthome from "../../common/images/ico_besthome.png";
import ico_bestshowroom from "../../common/images/ico_bestshowroom.png";
import logo from "../../common/images/logo.png";
import canvasLogo from "../../common/images/canvas-logo.png";
import canvasFlogo from "../../common/images/canvas-flogo.png";
import hico_deals from "../../common/images/hico_deals.png";
import hico_best from "../../common/images/hico_best.png";
import hico_gallery from "../../common/images/hico_gallery.png";
import hico_merchants from "../../common/images/hico_merchants.png";
import hico_refer from "../../common/images/hico_refer.png";
import hico_account from "../../common/images/hico_account.png";
import hico_home from "../../common/images/hico_home.png";
import hico_announcement from "../../common/images/hico_announcement.png";
import hico_deals1 from "../../common/images/hico_deals1.png";
import hico_best1 from "../../common/images/hico_best1.png";
import hico_gallery1 from "../../common/images/hico_gallery1.png";
import hico_merchants1 from "../../common/images/hico_merchants1.png";
import hico_refer1 from "../../common/images/hico_refer1.png";
import hico_account1 from "../../common/images/hico_account1.png";
import hico_home1 from "../../common/images/hico_home1.png";
import hico_announcement1 from "../../common/images/hico_announcement1.png";
import profile1 from "../../common/images/default_profile.png";

var base64 = require("base-64");

/* Load Header */
export const headerDetails = function() {
  return <Header />;
};

/* Load Language details */
export const language = function() {
  return lang();
};

/* Site Title */
export const site_title = function(title) {
  let result = "";
  if (title !== "") {
    result += title + " | ";
  }
  return result + siteTitle;
};

/* Email Validation */
export const emailValidate = function(emailID) {
  if (emailID !== "") {
    var re = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if (emailID !== "" && !re.test(emailID)) return false;
    else return true;
  }
};

/* Check Authentication */
export const checkauthentication = function() {
  if (
    cookie.load("user_details") !== "" &&
    typeof cookie.load("user_details") !== "undefined"
  ) {
    return true;
  } else {
    return false;
  }
};

/* Allow Number Only */
export const numariconly = function(e) {
  const re = /[0-9. ]+/g;
  if (!re.test(e.key)) {
    e.preventDefault();
  }
};

export const poweredByFootre = function(e) {
  return (
    <div className="landing_footer">
      <span>Powered by:</span>
      <img src={footerLogo} alt="More than Friends" />
    </div>
  );
};

export const getImage = function(url, pageName) {
  var imageUrl = "";
  if (pageName === "deals") {
    if (url !== "" && url !== null) {
      imageUrl = mediaUrl + "deals/" + url;
    } else {
      imageUrl = noListimg;
    }
  } else if (pageName === "deals_details") {
    if (url !== "" && url !== null) {
      imageUrl = mediaUrl + "deals/" + url;
    } else {
      imageUrl = noDetailimg;
    }
  } else if (pageName === "merchant") {
    if (url !== "" && url !== null) {
      imageUrl = mediaUrl + "merchants/" + url;
    } else {
      imageUrl = noListimg;
    }
  } else if (pageName === "project") {
    imageUrl = mediaUrl + "projects/" + url;
  } else if (pageName === "announcement") {
    if (url !== "") {
      imageUrl = mediaUrl + "announcement/" + url;
    } else {
      imageUrl = noDetailimg;
    }
  } else if (pageName === "profile") {
    if (url !== "" && url !== null) {
      imageUrl = mediaUrl + "profile_photo/" + url;
    } else {
      imageUrl = noListimg;
    }
  } else if (pageName === "pages") {
    if (url !== "" && url !== null) {
      imageUrl = mediaUrl + "pages/" + url;
    } else {
      imageUrl = landingLogo;
    }
  }

  return imageUrl;
};

export const LoadingSec = function() {
  return (
    <div className="load-msg">
      <div className="load-anim b">
        <div className="sk-cube sk-cube1"></div>
        <div className="sk-cube sk-cube2"></div>
        <div className="sk-cube sk-cube3"></div>
        <div className="sk-cube sk-cube4"></div>
        <div className="sk-cube sk-cube5"></div>
        <div className="sk-cube sk-cube6"></div>
        <div className="sk-cube sk-cube7"></div>
        <div className="sk-cube sk-cube8"></div>
        <div className="sk-cube sk-cube9"></div>
      </div>
      <div className="in b"></div>
    </div>
  );
};

export const Encrypt = function(Value, type) {
  if (Value !== "") {
    if (type === "e") {
      return base64.encode(Value);
    } else if (type === "d") {
      return base64.decode(Value);
    }
  }
};

/* Date Diffrent */
export const DateDiff = function(selectDate) {
  if (selectDate !== "" && selectDate !== "0000-00-00") {
    var date1 = new Date();
    var date2 = new Date(selectDate);
    // To calculate the time difference of two dates
    var Difference_In_Time = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    var Difference_In_Days = Math.round(
      Difference_In_Time / (1000 * 3600 * 24)
    );
    if (Difference_In_Days >= 0) {
      if (Difference_In_Days === 0) {
        return parseInt(Difference_In_Days) + 1 + " day left";
      } else {
        return parseInt(Difference_In_Days) + 1 + " days left";
      }
    } else {
      return "Expired";
    }
  }
};

/* Project Date */
export const ProjectDate = function(selectDate) {
  if (
    selectDate !== "" &&
    selectDate !== "0000-00-00" &&
    selectDate !== "0000-00-00 00:00:00" &&
    selectDate !== null &&
    typeof selectDate !== "undefined"
  ) {
    var monthNames = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      "10": "Oct",
      "11": "Nov",
      "12": "Dec"
    };
    var splitDate = selectDate.split("-");
    var monthIndex = splitDate[1];
    var dateSplit = splitDate[2].split(" ");

    return (
      "| " + dateSplit[0] + " " + monthNames[monthIndex] + " " + splitDate[0]
    );
  } else {
    return "";
  }
};

export const ScrollDiv = function(divID) {
  let minusValue = $("header").height() - 1;

  $("html, body").animate(
    {
      scrollTop: $("#" + divID).offset().top - minusValue
    },
    600
  );
};

export const ScrollTop = function() {
  $("html, body").animate({ scrollTop: 0 }, 500);
};

export const EnableScrollTop = function() {
  if ($("scrollToTop").length > 0) {
    let doc = document.documentElement,
      top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0),
      enableDiv = document.getElementsByClassName("scrollToTop")[0],
      getcontainerWid = document.getElementsByClassName("center")[0]
        .offsetWidth,
      getbodyleftPos = (window.innerWidth - getcontainerWid) / 2 + 14;
    enableDiv.style.left = getbodyleftPos + "px";
    if (top > 100) {
      enableDiv.classList.add("active");
    } else {
      enableDiv.classList.remove("active");
    }
  }
};

export const IntialLoading = function(divID) {
  return (
    <div className="center">
      <Header />
      <div className="loading_initial">
        <span>
          <img src={ichapter} alt="i-CHAPTER" />
        </span>
      </div>
    </div>
  );
};

export const IntialLoadingWithout = function(divID) {
  return (
    <div className="center">
      <div className="loading_initial">
        <span>
          <img src={ichapter} alt="i-CHAPTER" />
        </span>
      </div>
    </div>
  );
};

export const DisplayContent = function(content) {
  if (content !== "" && content !== null) {
    return Parser(content);
  }
};

export const DisplayImage = function(image) {
  if (image !== "" && image !== null) {
    if (image === "ico_failed") {
      return ico_failed;
    } else if (image === "ico_tick") {
      return ico_tick;
    } else if (image === "ico_terms") {
      return ico_terms;
    } else if (image === "ico_map") {
      return ico_map;
    } else if (image === "landingLogo") {
      return landingLogo;
    } else if (image === "ico_hotdeals") {
      return ico_hotdeals;
    } else if (image === "ico_merchants") {
      return ico_merchants;
    } else if (image === "ico_gallery") {
      return ico_gallery;
    } else if (image === "ico_account") {
      return ico_account;
    } else if (image === "ico_refer") {
      return ico_refer;
    } else if (image === "ico_besthome") {
      return ico_besthome;
    } else if (image === "ico_bestshowroom") {
      return ico_bestshowroom;
    } else if (image === "logo") {
      return logo;
    } else if (image === "canvasLogo") {
      return canvasLogo;
    } else if (image === "canvasFlogo") {
      return canvasFlogo;
    } else if (image === "hico_deals") {
      return hico_deals;
    } else if (image === "hico_best") {
      return hico_best;
    } else if (image === "hico_gallery") {
      return hico_gallery;
    } else if (image === "hico_merchants") {
      return hico_merchants;
    } else if (image === "hico_refer") {
      return hico_refer;
    } else if (image === "hico_account") {
      return hico_account;
    } else if (image === "hico_home") {
      return hico_home;
    } else if (image === "hico_announcement") {
      return hico_announcement;
    } else if (image === "hico_deals1") {
      return hico_deals1;
    } else if (image === "hico_best1") {
      return hico_best1;
    } else if (image === "hico_gallery1") {
      return hico_gallery1;
    } else if (image === "hico_merchants1") {
      return hico_merchants1;
    } else if (image === "hico_refer1") {
      return hico_refer1;
    } else if (image === "hico_account1") {
      return hico_account1;
    } else if (image === "hico_home1") {
      return hico_home1;
    } else if (image === "hico_announcement1") {
      return hico_announcement1;
    } else if (image === "profile1") {
      return profile1;
    } else if (image === "noDetailimg") {
      return noDetailimg;
    }
  }
};

export const CreateMeta = function(content) {
  if (content !== "" && content !== null) {
    return (
      <Helmet>
        <title>{site_title(content.title)}</title>
        <meta name="keywords" content={content.meta_keyword} />
        <meta name="description" content={content.meta_description} />
      </Helmet>
    );
  }
};
