/* eslint-disable */
import { takeLatest, call, put } from "redux-saga/effects";
import { apiUrl } from "../components/Config/config";
import { GET_REGISTER, SET_REGISTER } from "../actions";
import Axios from "axios";

export const watchGetRegister = function*(Param) {
  yield takeLatest(GET_REGISTER, workerGetRegister);
};

function* workerGetRegister(Param) {
  const result = yield call(getRegister, Param);
  yield put({
    type: "SET_REGISTER",
    json: result.data || [{ error: result.message }]
  });
}

function getRegister(Param) {
  return Axios.post(apiUrl + "customer/registration", Param.Param);
}
